import React, {FC} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {api} from 'services/api'
import {useTranslation} from 'react-i18next'
import {Avatar, Button} from 'components'
import {useQueryClient} from '@tanstack/react-query'
import {friendsKeys} from '@kaef/common/api/friends'
import {logAcceptFriendRequest, logDenyFriendRequest} from 'services/analytics/analytics'
import {IFriend} from '@kaef/common/types'

interface IProps {}

export const FriendsSidebar: FC<IProps> = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const [processingIds, setProcessingIds] = React.useState<string[]>([])
  const friendRequestsResponse = api.useFriendsRequestsQuery()
  const useAcceptFriendRequestMutation = api.useAcceptFriendRequestMutation()
  const useDenyFriendRequestMutation = api.useDenyFriendRequestMutation()

  const incomingRequests = friendRequestsResponse?.data || []

  const onAccept = (request: IFriend) => {
    logAcceptFriendRequest()
    setProcessingIds((prev) => [...prev, request.id])
    useAcceptFriendRequestMutation.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries(friendsKeys.all())
      },
      onSettled: () => {
        setProcessingIds((prev) => prev.filter((id) => id !== request.id))
      }
    })
  }

  const onDeny = (request: IFriend) => {
    logDenyFriendRequest()
    setProcessingIds((prev) => [...prev, request.id])
    useDenyFriendRequestMutation.mutate(request, {
      onSuccess: () => {
        queryClient.invalidateQueries(friendsKeys.requests())
      },
      onSettled: () => {
        setProcessingIds((prev) => prev.filter((id) => id !== request.id))
      }
    })
  }

  return (
    <Flex flexDirection={'column'} w={'100%'} gap={'8px'}>
      {incomingRequests.length > 0 && (
        <>
          <Text textStyle={'md'}>{t('friends.incomingRequests')}</Text>
          {incomingRequests.map((request) => (
            <Flex key={request.id} justifyContent={'space-between'} alignItems={'center'} pl={'6px'} pr={'6px'}>
              <Flex gap={'8px'} alignItems={'center'}>
                <Avatar name={request.name} />
                <Text textStyle={'md'}>{request.name}</Text>
              </Flex>
              <Flex gap={'8px'}>
                <Button
                  w={'70px'}
                  onClick={() => onDeny(request)}
                  variant={'secondary'}
                  isDisabled={useAcceptFriendRequestMutation.isLoading && processingIds.includes(request.id)}
                  isLoading={useDenyFriendRequestMutation.isLoading && processingIds.includes(request.id)}>
                  {t('friends.deny')}
                </Button>
                <Button
                  w={'70px'}
                  onClick={() => onAccept(request)}
                  variant={'accent'}
                  isDisabled={useDenyFriendRequestMutation.isLoading && processingIds.includes(request.id)}
                  isLoading={useAcceptFriendRequestMutation.isLoading && processingIds.includes(request.id)}>
                  {t('friends.accept')}
                </Button>
              </Flex>
            </Flex>
          ))}
        </>
      )}
    </Flex>
  )
}

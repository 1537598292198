import {getGoals, saveGoal} from './goals.api'
import { AxiosInstance } from "axios"
import {useMutation, useQuery} from '@tanstack/react-query'
import {goalsKeys} from './goals.keys'
import {IGoal, IOverviewGoal} from '../../types'

export const useGoalsQuery = (
  options: any,
  api: AxiosInstance,
  isDemo: boolean = false
) => {
  return useQuery(goalsKeys.goals(isDemo), () => getGoals(api, isDemo), options);
}

export const useSaveGoalMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<IOverviewGoal[], any, IGoal>((data: IGoal) => saveGoal(data, api), options)
}
import {FC, useCallback, useEffect, useRef} from 'react'
import {Divider, Flex, Text, useToken} from '@chakra-ui/react'
import {addHours, addSeconds, differenceInHours, differenceInSeconds, getHours, startOfDay} from 'date-fns'
import {secondsToTime, ANIMATION_CHART_DURATION} from '@kaef/common/utils/helpers'
import './DayLine.css'
import {Icon} from '../../UI'
import {IGoal} from '@kaef/common/types'

export interface TDayLineRange {
  start: number
  startPercent: number
  startDate: Date
  end: number
  endPercent: number
  endDate: Date
  color: string
}

interface IAnimatedLineProps {
  x1: number
  x2: number
  color: string
  index: number
}

const AnimatedSvgLine: FC<IAnimatedLineProps> = ({x1, x2, color}) => {
  const animationLine1 = useRef<SVGAnimationElement>(null)
  const animationLine2 = useRef<SVGAnimationElement>(null)
  const x1PrevRef = useRef(x1)
  const x2PrevRef = useRef(x1)
  const colorPrevRef = useRef(color)
  useEffect(() => {
    x1PrevRef.current = x1
    x2PrevRef.current = x2
    colorPrevRef.current = color
    animationLine1.current?.beginElement()
    animationLine2.current?.beginElement()
  }, [x1, x2, color])

  return (
    <line className={'day-line-path'} strokeWidth={3} strokeLinecap="butt" y1="0" x1={x1} x2={x2} y2="0" stroke={color}>
      <animate
        ref={animationLine1}
        repeatCount={1}
        restart="always"
        attributeName="x1"
        from={x1PrevRef.current}
        to={x1}
        begin="0"
        dur={ANIMATION_CHART_DURATION}
      />
      <animate
        ref={animationLine2}
        repeatCount={1}
        restart="always"
        attributeName="x2"
        from={x2PrevRef.current}
        to={x2}
        begin="0"
        dur={ANIMATION_CHART_DURATION}
      />
    </line>
  )
}

interface ILineProps {
  thinLine?: boolean
  withTrail?: boolean
  singleColor?: string
  alwaysActive?: boolean
  ranges: TDayLineRange[]
}

export const Line: FC<ILineProps> = ({thinLine, withTrail = true, singleColor, alwaysActive, ranges}) => {
  const [activeTrailColor] = useToken('colors', ['white.1-05', 'white.1-10'])
  return (
    <svg
      preserveAspectRatio="none"
      style={{width: '100%', height: thinLine ? '8px' : '12px'}}
      className={'day-line-svg-container'}
      width={'100%'}
      height={'100%'}
      viewBox="0 0 100 1">
      {withTrail && (
        <line
          className={`day-line-trail ${alwaysActive ? 'active' : ''}`}
          x1="0"
          y1="0"
          x2="100"
          y2="0"
          strokeWidth={3}
          fill={alwaysActive ? activeTrailColor : undefined}
        />
      )}
      {ranges.map((range, index) => (
        <AnimatedSvgLine
          key={index}
          index={index}
          x1={range.startPercent}
          x2={range.endPercent}
          color={singleColor || range.color}
        />
      ))}
    </svg>
  )
}

export interface ITick {
  value: string
  isShow: boolean
}

export const generateTicks = (rangeStart: Date, rangeEnd: Date): ITick[] => {
  const hours = differenceInHours(addSeconds(rangeEnd, 1), rangeStart)
  const ticksArr: ITick[] = []
  const isShowTick = hours < 6

  for (let i = 0; i < hours + 1; i++) {
    ticksArr.push({
      value: String(getHours(addHours(rangeStart, i))).padStart(2, '0'),
      isShow: i === 0 || i === hours || isShowTick || i % 2 === 0
    })
  }
  return ticksArr
}

interface IHoverLine {
  containerRef: any
  rangeDuration: number
  offset: number
  parentOffsetLeft?: number
  parentOffsetRight?: number
}
export const HoverLine: FC<IHoverLine> = ({
  containerRef,
  rangeDuration,
  offset,
  parentOffsetLeft = 0,
  parentOffsetRight = 0
}) => {
  const containerOffset = useRef(0)
  const containerWidth = useRef(1)

  const rangeDurationRef = useRef(rangeDuration)
  const offsetRef = useRef(offset)

  const lineRef = useRef<HTMLDivElement>(null)
  const labelRef = useRef<HTMLParagraphElement>(null)
  const onMouseMove = useCallback((event: any) => {
    if (!lineRef.current || !labelRef.current) {
      return
    }
    const position = event.screenX - containerOffset.current - parentOffsetLeft
    lineRef.current.style.left = `${position}px`
    if (position < 0 || position > containerWidth.current) {
      lineRef.current.style.opacity = '0'
    } else {
      lineRef.current.style.opacity = '1'
    }
    const time = (rangeDurationRef.current * position) / containerWidth.current
    labelRef.current.textContent = secondsToTime(Math.floor(time) + offsetRef.current)
  }, [])

  const onMouseEnter = useCallback(() => {
    if (!lineRef.current) {
      return
    }
    lineRef.current.style.opacity = '1'
  }, [])

  const onMouseLeave = useCallback(() => {
    if (!lineRef.current) {
      return
    }
    lineRef.current.style.opacity = '0'
  }, [])

  useEffect(() => {
    containerRef.current.addEventListener('mousemove', onMouseMove)
    containerRef.current.addEventListener('mouseenter', onMouseEnter)
    containerRef.current.addEventListener('mouseleave', onMouseLeave)

    const sizes = containerRef.current.getBoundingClientRect()
    containerOffset.current = sizes.x
    containerWidth.current = sizes.width - parentOffsetRight - parentOffsetLeft
  }, [containerRef])

  useEffect(() => {
    rangeDurationRef.current = rangeDuration
    offsetRef.current = offset
  }, [rangeDuration, offset])

  return (
    <Flex w={'100%'} position={'absolute'} h={'100%'} pointerEvents={'none'}>
      <Flex
        pointerEvents={'none'}
        opacity={0}
        ref={lineRef}
        pb={'3px'}
        alignItems={'center'}
        flexDirection={'column'}
        position={'absolute'}
        w={'0px'}
        h={'100%'}>
        <Text
          ref={labelRef}
          borderRadius={'4px'}
          bgColor={'black.3-40'}
          fontWeight={'800'}
          textStyle={'sm'}
          color={'accent.1'}>
          12:21
        </Text>
        <Flex bgColor={'accent.1'} borderRadius={'8px'} flexShrink={0} w={'8px'} h={'8px'}></Flex>
        <Divider pointerEvents={'none'} orientation="vertical" borderColor={'accent.1'} />
      </Flex>
    </Flex>
  )
}

interface IGoalMarksProps {
  goalMarks: {
    goalStart: {
      value: number | null
      percent: number
    }
    goalEnd: {
      value: number | null
      percent: number
    }
  }
}

export const GoalMarks: FC<IGoalMarksProps> = ({goalMarks}) => {
  const {goalStart, goalEnd} = goalMarks
  return (
    <Flex w={'100%'} position={'absolute'} h={'100%'} mt={'-2px'} pointerEvents={'none'}>
      {goalStart.value !== null && goalStart.percent >= 0 && goalStart.percent <= 100 && (
        <Flex
          transition={`left ${ANIMATION_CHART_DURATION} ease-in`}
          pb={'3px'}
          opacity={0.7}
          _hover={{opacity: 1}}
          alignItems={'center'}
          flexDirection={'column'}
          position={'absolute'}
          left={`calc(${goalStart.percent.toFixed(2)}% - 12px)`}
          w={'24px'}
          h={'100%'}>
          <Flex
            bgColor={'state.positive'}
            borderRadius={'24px'}
            flexShrink={0}
            alignItems={'center'}
            justifyContent={'center'}
            w={'24px'}
            h={'24px'}>
            <Icon width={18} height={18} type={'start'} />
          </Flex>
          <Divider orientation="vertical" borderColor={'state.positive'} />
        </Flex>
      )}
      {goalEnd.value !== null && goalEnd.percent >= 0 && goalEnd.percent <= 100 && (
        <Flex
          className={'goal-line'}
          pb={'3px'}
          opacity={0.7}
          _hover={{opacity: 1}}
          alignItems={'center'}
          flexDirection={'column'}
          position={'absolute'}
          left={`calc(${goalEnd.percent.toFixed(2)}% - 12px)`}
          w={'24px'}
          h={'100%'}>
          <Flex
            bgColor={'state.positive'}
            borderRadius={'24px'}
            flexShrink={0}
            alignItems={'center'}
            justifyContent={'center'}
            w={'24px'}
            h={'24px'}>
            <Icon width={18} height={18} type={'end'} />
          </Flex>
          <Divider orientation="vertical" borderColor={'state.positive'} />
        </Flex>
      )}
    </Flex>
  )
}

interface IDangerZone {
  start: number
}

export const DangerZone: FC<IDangerZone> = ({start}) => {
  if (!start) {
    return null
  }
  return (
    <Flex w={'100%'} position={'absolute'} h={'100%'} mt={'-2px'} pointerEvents={'none'}>
      <Flex
        w={`${(100 - start).toFixed(2)}%`}
        bgGradient={'linear(to-r, state.negative-0 0%, state.negative 40%)'}
        pb={'3px'}
        opacity={0.08}
        position={'absolute'}
        left={`${start.toFixed(2)}%`}
        h={'100%'}
      />
    </Flex>
  )
}

export interface ITimeline {
  ticks: ITick[]
  offset?: string
}

export const TimeLine: FC<ITimeline> = ({ticks, offset = '0px'}) => {
  return (
    <Flex ml={'-9px'} w={`calc(100% + 18px - ${offset})`} h={'16px'} mb={'14px'} justifyContent={'space-between'}>
      {ticks.map((tick, index) => (
        <Text w={'18px'} textAlign={'center'} key={index} textStyle={'sm'} color={'white.3'}>
          {tick.isShow && tick.value}
        </Text>
      ))}
    </Flex>
  )
}

export const Grid: FC<ITimeline> = ({ticks}) => {
  return (
    <Flex
      ml={'-9px'}
      w={'calc(100% + 18px)'}
      pt={'30px'}
      pb={'5px'}
      h={'100%'}
      position={'absolute'}
      justifyContent={'space-between'}>
      {ticks.map((tick, index) => (
        <Divider
          key={index}
          ml={'9px'}
          w={'9px'}
          opacity={tick.isShow ? 0.3 : 0.2}
          color={'white.3'}
          variant={'dashed'}
          orientation="vertical"
        />
      ))}
    </Flex>
  )
}

const FULL_DAY = 60 * 60 * 24

export const generateDayStartEnd = (
  rangeStart: Date,
  rangeEnd: Date,
  goalStart: IGoal | undefined,
  goalEnd: IGoal | undefined
) => {
  const start = rangeStart
  const end = rangeEnd

  const rangeDuration = differenceInSeconds(end, start)
  const offset = differenceInSeconds(start, startOfDay(start))
  const fullDayWithOffsetPercent = ((FULL_DAY - offset) / rangeDuration) * 100

  const goalMarks = {
    goalStart: {
      value: goalStart?.value || null,
      percent: (((goalStart?.value || 0) - offset) / rangeDuration) * 100
    },
    goalEnd: {
      value: goalEnd?.value || null,
      percent: (((goalEnd?.value || 0) - offset) / rangeDuration) * 100
    }
  }
  return {
    start,
    end,
    goalMarks,
    rangeDuration,
    offset,
    dangerZoneStart: fullDayWithOffsetPercent > 100 ? 0 : fullDayWithOffsetPercent
  }
}

import { AxiosInstance } from "axios";
import {INotification} from '../../types/models/domain/notifications.interface'

export const getNotifications = async (api: AxiosInstance): Promise<INotification[]> => {
  const response = await api.get("/user/notifications");
  return response.data?.notifications || [];
};

export const markAsRead = async (data: string[], api: AxiosInstance) => {
  await api.post("/user/notifications/read", {ids: data});
};

export const markAsReadAll = async (api: AxiosInstance) => {
  await api.post("/user/notifications/read-all");
};
import React, {FC, useEffect, useState} from 'react'
import {Text, Flex, Popover, PopoverContent, PopoverTrigger, Divider} from '@chakra-ui/react'
import {Button, IconButton, PressableBox} from '../UI'
import {api} from 'services/api'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from '@tanstack/react-query'
import {notificationsKeys} from '@kaef/common/api/notifications'
import {format} from 'date-fns'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {logShowNotificationAuto, logShowNotificationByUser} from 'services/analytics/analytics'

interface IProps {

}

export const Notifications: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()
  const [isOpen, setIsOpen] = useState(false)

  const notificationsQuery = api.useNotificationsQuery({refetchOnWindowFocus: true})
  const markAllNotificationAsReadMutation = api.useMarkAllNotificationAsReadMutation()

  const isNew = notificationsQuery.data?.some(notification => !notification.isRead)

  const onMarkAll = () => {
    markAllNotificationAsReadMutation.mutate(
      undefined,
      {
        onSuccess: () => {
          queryClient.invalidateQueries(notificationsKeys.all())
        }
      }
    )
  }

  const onOpenNotification = (id: string) => {
    logShowNotificationByUser(id)
    navigate(`${paths.notifications.path}/${id}`, {state: {backgroundLocation: location}})
  }

  useEffect(() => {
    const notificationsToShow = notificationsQuery.data?.filter(notification => !notification.isRead && notification.showAlert)
    if (notificationsToShow && notificationsToShow.length > 0) {
      logShowNotificationAuto(notificationsToShow.map(notification => notification.id))
      navigate(`${paths.notifications.path}`, {state: {backgroundLocation: location}})
    }
  }, [notificationsQuery.data])


  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} placement="bottom">
      <PopoverTrigger>
        <Flex mr={'8px'} position={'relative'}>
          <IconButton onClick={() => setIsOpen(true)} variant={'ghost'} aria-label={'Notifications'} icon={'bell'} iconProps={{width: 20, height: 20}} />
          {isNew && <Flex top={'8px'} right={'8px'} position={'absolute'} w={'10px'} h={'10px'} borderRadius={'8px'} borderWidth={'2px'} borderColor={'black.1'} bgColor={'state.negative'}/>}
        </Flex>
      </PopoverTrigger>
      <PopoverContent w={'383px'} backdropFilter={'blur(30px)'} bgColor={'white.1-10'} p={'24px'} maxH={'752px'} mr={'96px'}>
        <Flex flexDirection={'column'}>
          <Flex justify={'space-between'} align={'center'} mb={'24px'}>
            <Text textStyle={'lg'}>{t('notifications.title')}</Text>
            <Button w={'120px'} variant={'text'} onClick={onMarkAll}>
              <Text textStyle={'md'} color={'accent.2'} >
                {t('notifications.markAll')}
              </Text>
            </Button>
          </Flex>
          <Divider bgColor={'black.4'} orientation={'horizontal'} />
          <Flex flexDirection={'column'} overflowY={'scroll'} maxH={'600px'} h={'100%'} pt={'24px'} gap={'24px'}>
            {notificationsQuery.data?.length === 0 && <Text color={'white.3'}>{t('notifications.empty')}</Text>}
            {notificationsQuery.data?.map(notification => (
              <PressableBox key={notification.id} onClick={() => onOpenNotification(notification.id)}>
                <Flex flexDirection={'column'}>
                  <Flex align={'center'}>
                    {!notification.isRead && <Flex bgColor={'state.negative'} mr={'8px'} w={'8px'} h={'8px'} borderRadius={'10px'} />}
                    <Text textStyle={'lg'}>{notification.title}</Text>
                  </Flex>
                  <Text mt={'4px'} mb={'16px'} textStyle={'sm'} color={'white.3'}>{format(new Date(notification.createdAt), 'dd-MM-yyyy')}</Text>
                  <Flex overflow={'hidden'} maxH={'100px'} >
                    <Text display={'-webkit-box'} sx={{WebkitLineClamp: '3', WebkitBoxOrient: 'vertical'}} textOverflow={'ellipsis'} color={'white.2'} textStyle={'md'}>{notification.description}</Text>
                  </Flex>
                </Flex>
              </PressableBox>
            ))}
          </Flex>
        </Flex>
      </PopoverContent>
    </Popover>
  )
}
import {FC} from 'react'
import {Flex, Grid, Text} from '@chakra-ui/react'
import {RECOMMEND_CONFIG} from 'stores'
import {daysButtonsConfig} from 'scenes/Onboarding/Onboarding.utils'
import {Button} from '../../UI'
import {useTranslation} from 'react-i18next'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'

interface IProps {
  days: number[]
  setDays: (days: number[]) => void
}

export const DaysModal: FC<IProps> = ({days, setDays}) => {
  const {t} = useTranslation()
  const {isGoalRecommendationEnabled} = useRemoteConfig()

  const onDayClick = (item: number) => {
    if (days.includes(item)) {
      setDays(days.filter((day) => day !== item))
    } else {
      setDays([...days, item].sort())
    }
  }

  const isNotRecommendedDays = () => {
    if (days.length !== RECOMMEND_CONFIG.WORK_DAYS.length) {
      return true
    }

    return days.find((item, idx) => item !== RECOMMEND_CONFIG.WORK_DAYS[idx])
  }

  return (
    <>
      <Flex minHeight={'140px'}>
        <Flex flexDirection={'column'}>
          <Text mb={'6px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.days.title')}
          </Text>
          {isGoalRecommendationEnabled && isNotRecommendedDays() ? (
            <>
              <Text textStyle={'md'} color={'state.neutral'}>
                {t('onboarding.days.subtitleRecommend')}
              </Text>
              <Text color={'white.3'} cursor={'pointer'} onClick={() => setDays(RECOMMEND_CONFIG.WORK_DAYS)}>
                {t('onboarding.days.setRecommend')}
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>
      {/*// TODO: Replace with ButtonGroup*/}
      <Grid gridTemplateColumns={'repeat(7, auto)'} gridGap={'0 10px'} mb={'20px'}>
        {daysButtonsConfig.map((item, idx) => (
          <Button
            key={`${item}${idx}`}
            onClick={() => {
              onDayClick(item.value)
            }}
            variant={days.includes(item.value) ? 'accent' : 'secondary'}>
            {t(`common.calendar.${item.label}`)}
          </Button>
        ))}
      </Grid>
    </>
  )
}

import React, {FC, useEffect} from 'react'
import {Box, Divider, Flex, Text} from '@chakra-ui/react'
import {IconButton} from 'components'
import {ERange} from '@kaef/common/types'
import DayLayout from './components/DayLayout/DayLayout'
import WeekLayout from './components/WeekLayout/WeekLayout'
import MonthLayout from './components/MonthLayout/MonthLayout'
import YearLayout from './components/YearLayout/YearLayout'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {api} from 'services/api'
import {colorFuse} from '@kaef/common/utils/helpers'
import {emojiPalette} from 'services/chart'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useQueryClient} from '@tanstack/react-query'
import {motion} from 'framer-motion'

interface IOverviewMainProps {}

const OverviewMain: FC<IOverviewMainProps> = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()

  const {range} = useParams()

  const [searchParams] = useSearchParams()

  const projectId = searchParams.get('projectId')

  const projectQuery = api.useProjectQuery(projectId)
  const saveProjectMutation = api.useSaveProjectMutation()

  const onBack = () => {
    navigate(`${paths.overview.path}/${range}`)
  }

  const onEdit = () => {
    navigate(`${paths.editProject.path}/${projectId}`, {state: {backgroundLocation: location}})
  }

  const onChangeVisibility = () => {
    if (!projectQuery.data) {
      return
    }
    saveProjectMutation.mutate(
      {
        ...projectQuery.data,
        show: !projectQuery.data.show
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(overviewKeys.all())
          queryClient.invalidateQueries(projectsKeys.all())
        }
      }
    )
  }

  const renderRangeLayout = () => {
    switch (range) {
      default:
      case ERange.DAY:
        return <DayLayout projectId={projectId} />
      case ERange.WEEK:
        return <WeekLayout projectId={projectId} />
      case ERange.MONTH:
        return <MonthLayout projectId={projectId} />
      case ERange.YEAR:
        return <YearLayout projectId={projectId} />
    }
  }

  const isProjectView = projectId && projectQuery.data

  useEffect(() => {
    if (isProjectView) {
      const main = document.getElementById('main-container') as HTMLElement
      main.scrollTo(0, 0)
      const root = document.getElementById('root') as HTMLElement
      root.classList.add('highlight')
    } else {
      const root = document.getElementById('root') as HTMLElement
      root.classList.remove('highlight')
    }
  }, [isProjectView])

  return (
    <Flex
      width={'100%'}
      flexDirection={'column'}
      bgColor={'white.1-05'}
      borderWidth={'1px'}
      borderColor={'black.5-40'}
      borderRadius={'8px'}>
      <motion.div
        initial={{opacity: 0, x: 40, height: 0}}
        transition={{type: 'tween', duration: 0.2, ease: 'easeOut'}}
        animate={{opacity: 1, x: 0, height: isProjectView ? 88 : 0}}>
        {isProjectView && (
          <Flex w={'100%'} p={[0, '24px']} justifyContent={'space-between'}>
            <Flex alignItems={'center'}>
              <IconButton
                onClick={onBack}
                icon={'arrowBack'}
                color={'white.3'}
                aria-label={'back'}
                variant={'ghost'}
                mr={'12px'}
              />
              <Box
                mr={'8px'}
                filter={'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5))'}
                w={'14px'}
                h={'14px'}
                bgColor={colorFuse(projectQuery.data?.color)}
                borderRadius={'16px'}
              />
              <Text mr={'8px'} textStyle={'lg'}>
                {projectQuery.data?.icon || emojiPalette.getProjectEmoji(projectQuery.data?.id)}
              </Text>
              <Text
                overflow={'hidden'}
                maxWidth={['170px', '170px', '400px']}
                textOverflow={'ellipsis'}
                whiteSpace={'nowrap'}
                textStyle={'lg'}
                color={'white.1'}>
                {projectQuery.data?.name}
              </Text>
            </Flex>
            <Flex>
              <IconButton
                onClick={onEdit}
                icon={'edit'}
                color={'white.3'}
                borderColor={'black.4'}
                aria-label={'back'}
                variant={'outline'}
                mr={'8px'}
              />
              <IconButton
                onClick={onChangeVisibility}
                icon={'eyeShow'}
                color={'white.3'}
                borderColor={'black.4'}
                aria-label={'back'}
                variant={'outline'}
              />
            </Flex>
          </Flex>
        )}
      </motion.div>
      {isProjectView && (
        <Divider
          width={'auto'}
          orientation={'horizontal'}
          opacity={1}
          borderColor={'black.4'}
          mr={[0, '24px']}
          ml={[0, '24px']}
        />
      )}
      <Flex pl={['10px', '40px']} pr={['10px', '40px']} pb={['10px', '40px']}>
        {renderRangeLayout()}
      </Flex>
    </Flex>
  )
}

export default OverviewMain

import {EGoalResult, ERange, IOverviewGoal, TGoalResultMap} from '../../types'

export const textColorMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'state.positive',
  [EGoalResult.FAIL]: 'state.negative',
  [EGoalResult.NO_GOAL]: 'white.3',
  [EGoalResult.INDETERMINATE]: 'white.1',
  [EGoalResult.IN_PROGRESS]: 'white.1',
  [EGoalResult.NOT_STARTED]: 'white.1',
  [EGoalResult.LOCKED]: 'white.1',
}

export const progressLineColorMap: {fillColor: TGoalResultMap, longMarkColor: TGoalResultMap} = {
  fillColor: {
    [EGoalResult.SUCCESS]: 'state.positive-50',
    [EGoalResult.FAIL]: 'state.negative-50',
    [EGoalResult.NO_GOAL]: 'white.3',
    [EGoalResult.INDETERMINATE]: 'white.1-50',
    [EGoalResult.IN_PROGRESS]: 'accent.1-50',
    [EGoalResult.NOT_STARTED]: 'white.1',
    [EGoalResult.LOCKED]: 'white.1-50',
  },
  longMarkColor: {
    [EGoalResult.SUCCESS]: 'state.positive',
    [EGoalResult.FAIL]: 'state.negative',
    [EGoalResult.NO_GOAL]: 'white.3',
    [EGoalResult.INDETERMINATE]: 'white.1',
    [EGoalResult.IN_PROGRESS]: 'accent.1',
    [EGoalResult.NOT_STARTED]: 'white.1',
    [EGoalResult.LOCKED]: 'white.1',
  }
}

export const getGoalStatus = (range: ERange, goal: IOverviewGoal) => {
  const {skipped, success, failed} = goal!.stats
  switch (range) {
    case ERange.DAY:
      return goal?.history[0].result
    case ERange.YEAR:
    case ERange.MONTH:
    case ERange.WEEK:
      return goal.history.length === (success + skipped)
        ? EGoalResult.SUCCESS
        : goal.history.length === (failed + skipped)
          ? EGoalResult.FAIL : EGoalResult.INDETERMINATE
  }
}

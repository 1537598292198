import {FC, forwardRef} from 'react'
import {Input as ChakraInput, InputProps} from '@chakra-ui/react'

interface IProps extends InputProps {}

export const Input: FC<IProps> = forwardRef((props, ref) => {
  return (
    <ChakraInput
      pl={'12px'}
      pr={'12px'}
      borderWidth={'1px'}
      borderColor={'black.4'}
      borderRadius={'4px'}
      size={'md'}
      backgroundColor={'black.3'}
      outline={'none'}
      sx={{outline: 'none'}}
      focusBorderColor={'accent.1-30'}
      _focus={{
        outline: 'none',
        backgroundColor: 'accent.1-05'
      }}
      ref={ref}
      {...props}
    />
  )
})

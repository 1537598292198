import {create} from 'zustand'
import {startOfMonth, startOfWeek, startOfYear} from 'date-fns'
import {ERange} from '@kaef/common/types'

export interface IFriendsState {
  day: Date
  setDay: (date: Date) => void
  week: Date
  setWeek: (date: Date) => void
  month: Date
  setMonth: (date: Date) => void
  year: Date
  setYear: (date: Date) => void
  range: ERange
  setRange: (range: ERange) => void
}

export const useFriendsStore = create<IFriendsState>()((set) => ({
  day: new Date(),
  setDay: (day) => set({day}),
  week: startOfWeek(new Date(), {weekStartsOn: 1}),
  setWeek: (week) => set({week}),
  month: startOfMonth(new Date()),
  setMonth: (month) => set({month}),
  year: startOfYear(new Date()),
  setYear: (year) => set({year}),
  range: ERange.DAY,
  setRange: (range: ERange) => set({range})
}))

export const friendsDateByRangeSelector = (range: ERange, state: IFriendsState) => {
  switch (range) {
    default:
    case ERange.DAY:
      return state.day
    case ERange.WEEK:
      return state.week
    case ERange.MONTH:
      return state.month
    case ERange.YEAR:
      return state.year
  }
}

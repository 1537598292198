import {FC, memo} from 'react'
import {Flex, Text, useToken} from '@chakra-ui/react'
import {ANIMATION_CHART_DURATION} from '@kaef/common/utils/helpers'
import './BoxWithTime.css'
import {EGoalResult, TGoalResultMap} from '../../../../../common/types'
import {Icon} from '../../UI'

interface IProps {
  onClick: () => void
  max: number
  value: number
  formatter?: (value: number) => string
  status: EGoalResult
  withoutGoal?: boolean
  withOpacity?: boolean
}

const boxColorMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'state.positive-50',
  [EGoalResult.FAIL]: 'state.negative-50',
  [EGoalResult.NO_GOAL]: 'white.1-50',
  [EGoalResult.NOT_STARTED]: 'transparent',
  [EGoalResult.INDETERMINATE]: 'white.1-50',
  [EGoalResult.IN_PROGRESS]: 'accent.1',
  [EGoalResult.LOCKED]: 'transparent'
}

const RawBoxWithTime: FC<IProps> = ({
                                      withOpacity,
                                      withoutGoal,
                                      status,
                                      onClick,
                                      value,
                                      formatter = (value) => value,
                                      max
                                    }) => {
  const [textShadowColor] = useToken('colors', ['blackAlpha.900'])
  const progress = value > max ? 1 : value / max
  const fillHeight = progress * 40
  const isLocked = status === EGoalResult.LOCKED
  const lockedProps = {
    borderWidth: '1px',
    borderColor: 'white.3',
    borderStyle: 'dotted',
    bg: 'linear-gradient(0deg, #FBFBFB00 0%, #FBFBFB1A 100%)'
  }
  return (
    <Flex
      onClick={onClick}
      cursor={'pointer'}
      alignItems={isLocked ? 'center' : 'flex-end'}
      justifyContent={'center'}
      position={'relative'}
      opacity={withOpacity ? 0.3 : 1}
      w={'40px'}
      h={'40px'}>
      <Flex
        animation={`box-with-time-initial ${ANIMATION_CHART_DURATION} 0s 1 ease-in`}
        transition={`height ${ANIMATION_CHART_DURATION} ease-in, backgroundColor ${ANIMATION_CHART_DURATION} ease-in`}
        bottom={0}
        position={'absolute'}
        w={'40px'}
        {...(isLocked && lockedProps)}
        backgroundColor={(withoutGoal && !isLocked) ? 'accent.1' : boxColorMap[status]}
        h={`${isLocked ? 40 : fillHeight}px`}
        borderRadius={'4px'}
      />
      {isLocked && <Icon type={'lock'} color={'white.3'} />}
      {!isLocked &&
        <Text zIndex={'5'} mb={'5px'} textStyle={'sm'} color={'white.1'} textShadow={`0px 0px 4px ${textShadowColor}`}>
          {formatter(value)}
        </Text>}
    </Flex>
  )
}

export const BoxWithTime = memo(RawBoxWithTime)

import {FC} from 'react'
import {Button, Center, Stack, StackDivider, StackProps} from '@chakra-ui/react'

type TButtonGroupValue = string | number

type TButtonVariant = 'solid' | 'outline' | 'ghost' | 'link' | 'accent' | 'secondary'

interface IButtonGroupOptions {
  value: TButtonGroupValue
  label: string
}

interface IButtonGroupProps {
  options?: IButtonGroupOptions[]
  value?: TButtonGroupValue
  variant?: TButtonVariant
  colorScheme?: string
  onChange: (value: any) => void
  containerProps?: StackProps
}

export const ButtonGroup: FC<IButtonGroupProps> = ({
  colorScheme = 'white',
  variant = 'accent',
  options,
  value,
  onChange,
  containerProps = {}
}) => {
  if (!options) {
    return null
  }

  const divider = (
    <Center height={'20px'}>
      <StackDivider borderColor="black.4" />
    </Center>
  )

  return (
    <Stack
      alignItems="center"
      w={'100%'}
      divider={divider}
      direction={'row'}
      borderRadius={'8px'}
      spacing={'0'}
      padding={'2px'}
      bg={'black.3'}
      gap={'1px'}
      {...containerProps}>
      {options.map((option, index) => (
        <Button
          key={index}
          h={'36px'}
          colorScheme={colorScheme}
          w={'100%'}
          borderRadius={'8px'}
          variant={option.value === value ? variant : 'ghost'}
          onClick={() => onChange(option.value)}>
          {option.label}
        </Button>
      ))}
    </Stack>
  )
}

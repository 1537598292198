export enum ENotificationType {
  SYSTEM = 'SYSTEM',
  ASK_REVIEW = 'ASK_REVIEW',
  WAKATIME_IMPORT = 'WAKATIME_IMPORT',
}

export enum ENotificationDestination {
  WEB = 'WEB',
  PLUGIN = 'PLUGIN',
  MOBILE = 'MOBILE',
}

export interface INotification {
  id: string
  title: string
  description?: string
  imageUrl?: string
  type: ENotificationType
  destination: ENotificationDestination
  showAlert: boolean
  isRead: boolean
  createdAt: string
  params: {
    buttons?: INotificationButton[]
  }
}

export interface INotificationButton {
  text: string
  type: 'accent' | 'secondary' | 'negative' | 'withIcon' | 'ghost' | 'text',
  link: string
  isNavigationLink?: boolean
}

import { AxiosInstance } from 'axios'
import {ISubscription} from '../../types'

export const getSubscriptions = async (api: AxiosInstance): Promise<ISubscription[]> => {
  const response = await api.get("/subscription/product-list");
  return response.data?.productList || [];
};


export const cancelSubscription = async (subscriptionId: string, api: AxiosInstance): Promise<void>  => {
  await api.post("/subscription/cancel", {
    subscriptionId
  });
};

export const refreshSubscriptionStatus = async (api: AxiosInstance): Promise<void>  => {
  await api.post("/subscription/refresh");
};

export const checkoutSubscription = async (productKey: string, api: AxiosInstance): Promise<string>  => {
  const response = await api.post("/subscription/checkout", {
    productKey
  });
  return response.data?.url
};
import { IFriendOverviewRequest, TOverviewRequest } from "../../types";

export const mapDataToKeys = (data?: TOverviewRequest) => {
  if (!data) {
    return ["nodata"];
  }
  const result = [];
  if (data.range) {
    result.push(data.range);
  }
  if (data.date) {
    result.push(data.date);
  }
  if (data.filter) {
    Object.entries(data.filter).forEach((entry) => {
      result.push(`${entry[0]}:${entry[1]}`);
    });
  }

  return result;
};

export const mapFriendsOverviewDataToKeys = (data?: IFriendOverviewRequest) => {
  if (!data) {
    return ["nodata"];
  }
  const result = [];
  if (data.range) {
    result.push(data.range);
  }
  if (data.date) {
    result.push(data.date);
  }
  if (data.limit) {
    result.push(data.limit);
  }
  if (data.filter) {
    Object.entries(data.filter).forEach((entry) => {
      result.push(`${entry[0]}:${entry[1]}`);
    });
  }

  return result;
};

export const getTwitterUrl = (text: string, url: string) => {
  return `https://x.com/intent/tweet?url=${encodeURI(url)}&text=${encodeURI(text)}&related=nau_time&hashtags=DevLive%2Cprogramming%2Ccode`
}

export const getLinkedinUrl = (text: string, url: string) => {
  return `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURI(url)}`
}

export const getFacebookUrl = (text: string, url: string) => {
  return `https://www.facebook.com/sharer/sharer.php?u=${encodeURI(url)}`
}

import ReactECharts from 'echarts-for-react'
import {FC} from 'react'
import {ANIMATION_ECHART_DURATION, secondsToString} from '@kaef/common/utils/helpers'
import {TPieData} from '@kaef/common/types'
import './tooltip.css'
import {useToken} from '@chakra-ui/react'
import {i18n} from 'services/i18n'

const generateSeries = (data: TPieData[], textColor: string, seriesOptions?: any) => {
  return {
    name: '_pie_chart',
    type: 'pie',
    radius: ['75%', '100%'],
    itemStyle: {
      borderWidth: 3,
      borderColor: '#1A1A23' //FIXME hardcoded color for transparent borders
    },
    animation: true,
    animationDuration: 0,
    animationDurationUpdate: ANIMATION_ECHART_DURATION,
    label: {
      color: textColor,
      fontSize: '16',
      position: 'center',
      formatter: () => {
        return i18n.t('overview.pieChart.title', {count: data.length})
      }
    },
    emphasis: {
      scale: false,
      focus: 'none'
    },
    data: data.map((item) => ({
      value: item.value,
      name: item.name,
      valueSeconds: item.valueSeconds,
      itemStyle: {
        color: item.color
      },
      ...seriesOptions
    }))
  }
}

const generateChartOption = (
  data: TPieData[],
  textColor: string,
  chartOptions?: {
    showLabel?: boolean
    yAxis?: any
    xAxis?: any
    seriesOptions?: any
  }
) => {
  const series = generateSeries(data, textColor, chartOptions?.seriesOptions)
  return {
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'item',
      axisPointer: {
        type: 'shadow'
      },
      padding: 0,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      formatter: (rawSerie: any) => {
        const seriesHtml = `<div class='tooltip-item-container'>
                      <div class='tooltip-item-title'>
                        <div class='tooltip-circle' style='background-color: ${rawSerie.color}'></div>
                        <span class='tooltip-text'>${rawSerie.name}</span>
                      </div>
                      <span class='tooltip-text'>
                        ${secondsToString(rawSerie.data.valueSeconds)}
                      </span>
                       <span class='tooltip-text-secondary'>
                        ${rawSerie.value.toFixed(1)}%
                      </span>
                    </div>`

        return `<div class='tooltip-container'>${seriesHtml}</div>`
      }
    },
    grid: {
      right: 20,
      left: 20,
      top: 20,
      bottom: 20
    },
    series: [series]
  }
}

interface IProps {
  height: number
  data: TPieData[]
  onClick?: (date: Date) => void
  chartOptions?: {
    showLabel?: boolean
    yAxis?: any
    xAxis?: any
    seriesOptions?: any
  }
}

export const PieChart: FC<IProps> = ({onClick, height, data, chartOptions}) => {
  const [textColor] = useToken('colors', ['white.3'])

  const _onClick = (event: any) => {
    if (event.componentType === 'series') {
      onClick && onClick(event.dataIndex)
    }
  }
  const option = generateChartOption(data, textColor, chartOptions)
  return <ReactECharts option={option} theme={'dark'} notMerge={true} style={{height, width: '100%'}} />
}

import React, {FC} from 'react'
import {api} from 'services/api'
import {paths} from 'shared/constants/paths'
import {Button, Icon} from 'components'
import {Flex, Text} from '@chakra-ui/react'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {logOpenPaywall} from 'services/analytics/analytics'

interface IProps {}

export const UpgradeButton: FC<IProps> = ({}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const {t} = useTranslation()
  const {data} = api.useUserInfoQuery()

  const onOpenPaywall = () => {
    logOpenPaywall('header')
    navigate(paths.paywall.path, {state: {backgroundLocation: location}})
  }

  const onOpenSubscription = () => {
    if (data?.subscription.trial) {
      logOpenPaywall('upgradeTrial')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    navigate(paths.subscription.path, {state: {backgroundLocation: location}})
  }

  if ((data?.subscription.trial && (data?.subscription.daysUntilEnd || 0) > 0) || data?.subscription.pro) {
    return (
      <Button mr={'12px'} style={{opacity: 1}} variant={'text'} onClick={onOpenSubscription}>
        <Icon type={'flash'} color={'accent.1'} />
        <Flex flexDirection={'column'} ml={'8px'} alignItems={'flex-start'}>
          <Text textStyle={'md'} color={'white.1'}>
            {t('navBar.proIsActive')}
          </Text>
          {data?.subscription.trial && (
            <Text color={'white.3'}>
              {t('subscription.trialDaysLeft', {count: data?.subscription.daysUntilEnd || 0}) as unknown as string}
            </Text>
          )}
        </Flex>
      </Button>
    )
  }
  return (
    <Button mr={'12px'} variant={'text'} onClick={onOpenPaywall}>
      <Icon type={'flash'} color={'accent.2'} />
      <Text ml={'8px'} textStyle={'md'} color={'accent.2'}>
        {t('navBar.upgradeToPro')}
      </Text>
    </Button>
  )
}

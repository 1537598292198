import {analytics as firebaseAnalytics} from '../firebase/firebase'
import {logEvent as firebaseLogEvent, setUserId, setUserProperties} from 'firebase/analytics'
import * as amplitude from '@amplitude/analytics-browser'
import {IUser} from '@kaef/common/types'

/* init */

class AnalyticsService {
  source: string | null = null

  constructor() {
    console.log('DEV', import.meta.env.DEV)
    if (import.meta.env.DEV) {
      return
    }
    amplitude.init('ffd926885e132b3d0460e070c0465083')
  }
}

export const analytics = new AnalyticsService()

const EVENTS = {
  REGISTER_USER: 'register_user',
  LOGIN_USER: 'login_user',
  ONBOARDING_SET_GOAL: 'onboarding_set_goal',
  ONBOARDING_SKIP_GOAL: 'onboarding_skip_goal',
  CHANGE_OVERVIEW: 'change_overview',
  DAY_CHANGE: 'day_change',
  OPEN_DAY: 'open_day',
  OPEN_CHART: 'open_chart',
  OPEN_PAYWALL: 'open_paywall',
  SUBSCRIPTION_CHECKOUT: 'subscription_checkout',
  SUBSCRIPTION_CANCEL: 'subscription_cancel',
  CHANGE_SLICE: 'change_slice',
  SET_GOAL: 'set_goal',
  CHANGE_RANGE: 'change_range',
  PROJECT_OPEN_DETAILS: 'project_open_details',
  PROJECT_EDIT: 'project_edit',
  PROJECT_TOGGLE_VISIBILITY: 'project_toggle_visibility',
  LINK_PLUGIN: 'link_plugin',
  SET_WEEK_PLAN: 'set_week_plan',
  CHANGE_DAY_TYPE: 'change_day_type',
  SHOW_NOTIFICATION_BY_USER: 'show_notification_by_user',
  SHOW_NOTIFICATION_AUTO: 'show_notification_auto',
  IMPORT_WAKATIME: 'import_wakatime',
  FEATURE_POPULARITY: 'feature_popularity',
  SHARE: 'share',
  SHARE_MODAL: 'share_modal',
  CHANGE_FRIENDS_RANGE: 'change_friends_range',
  CHANGE_FRIENDS_DAY: 'change_friends_day',
  ADD_FRIEND: 'add_friend',
  ACCEPT_FRIEND_REQUEST: 'accept_friend_request',
  DENY_FRIEND_REQUEST: 'deny_friend_request',
  CANCEL_FRIEND_REQUEST: 'cancel_friend_request'
}

export const initUserForAnalytics = (user: IUser) => {
  amplitude.setUserId(String(user.id))
  const identifyEvent = new amplitude.Identify()
  identifyEvent.setOnce('registration_date', user.registerDate)
  amplitude.identify(identifyEvent)

  setUserId(firebaseAnalytics, String(user.id))
  setUserProperties(firebaseAnalytics, {registration_date: user.registerDate})
}

const logEvent = (name: string, params?: any) => {
  if (import.meta.env.DEV) {
    return
  }
  try {
    firebaseLogEvent(firebaseAnalytics, name, params)
    amplitude.logEvent(name, params)
  } catch (err) {
    console.log('track event error')
  }
}

export const logRegister = (params: {provider: string | null}, afterAuthRedirect?: string | null) => {
  let source = null
  if (afterAuthRedirect) {
    try {
      const afterAuthRedirectArr = afterAuthRedirect.split('?')
      if (afterAuthRedirectArr.length > 1) {
        const searchParams = new URLSearchParams(afterAuthRedirectArr[1])
        source = searchParams.get('utm_source')
      }
    } catch (err) {}
  }
  logEvent(EVENTS.REGISTER_USER, {...params, source})
}

export const logLogin = (provider: string | null) => {
  logEvent(EVENTS.LOGIN_USER, {provider})
}

export const logOnboardingSetGoal = (goal: string) => {
  logEvent(EVENTS.ONBOARDING_SET_GOAL, {goal})
}

export const logOnboardingSkipGoal = (goal: string) => {
  logEvent(EVENTS.ONBOARDING_SKIP_GOAL, {goal})
}

export const logOpenChart = (chart: string) => {
  logEvent(EVENTS.OPEN_CHART, {chart})
}

export const logChangeSlice = (slice: string, range: string) => {
  logEvent(EVENTS.CHANGE_SLICE, {slice, range})
}

export const logChangeRange = (range: string) => {
  logEvent(EVENTS.CHANGE_RANGE, {range})
}

export const logSetGoal = (goal: string, value: string) => {
  logEvent(EVENTS.SET_GOAL, {goal, value})
}

export const logChangeDayType = (day: string, type: string) => {
  logEvent(EVENTS.CHANGE_DAY_TYPE, {day, type})
}

export const logSetWeekPlan = (days: number[]) => {
  logEvent(EVENTS.SET_WEEK_PLAN, {days})
}

export const logLinkPlugin = (type: string | null) => {
  logEvent(EVENTS.LINK_PLUGIN, {type})
  if (type) {
    const identifyEvent = new amplitude.Identify()
    identifyEvent.append('linked_plugin', type)
    amplitude.identify(identifyEvent)
    setUserProperties(firebaseAnalytics, {linked_plugin: type})
  }
}

export const logShowNotificationByUser = (notificationId: string) => {
  logEvent(EVENTS.SHOW_NOTIFICATION_BY_USER, {notificationId})
}

export const logShowNotificationAuto = (ids: string[]) => {
  logEvent(EVENTS.SHOW_NOTIFICATION_AUTO, {ids})
}

export const logOpenDay = (from: string, dayType?: string | null, dayResult?: string) => {
  logEvent(EVENTS.OPEN_DAY, {from, dayType, dayResult})
}

export const logProjectOpenDetails = () => {
  logEvent(EVENTS.PROJECT_OPEN_DETAILS)
}

export const logProjectToggleVisibility = () => {
  logEvent(EVENTS.PROJECT_TOGGLE_VISIBILITY)
}

export const logProjectEdit = (changed: {name: boolean; icon: boolean; color: boolean}) => {
  logEvent(EVENTS.PROJECT_EDIT, {...changed})
}

type TOpenPaywall =
  | 'upgradeTrial'
  | 'goals'
  | 'header'
  | 'day_change'
  | 'week_day_click'
  | 'week_slice_details'
  | 'month_day_click'
  | 'year_month_click'
  | 'month_slice_details'
  | 'year_slice_details'
  | 'week_share'
  | 'month_share'
  | 'year_share'
  | 'friends_day_change'

export const logOpenPaywall = (from: TOpenPaywall) => {
  logEvent(EVENTS.OPEN_PAYWALL, {from})
}

export const logCheckoutSubscription = (type: string) => {
  logEvent(EVENTS.SUBSCRIPTION_CHECKOUT, {type})
}

export const logCancelSubscription = (type: string) => {
  logEvent(EVENTS.SUBSCRIPTION_CANCEL, {type})
}

export const logChangeOverview = (range: string, chart?: string, slice?: string) => {
  logEvent(EVENTS.CHANGE_OVERVIEW, {range, chart, slice})
}

export const logChangeFriendsRange = (range: string) => {
  logEvent(EVENTS.CHANGE_FRIENDS_RANGE, {range})
}

export const logChangeFriendsDay = (range: string) => {
  logEvent(EVENTS.CHANGE_FRIENDS_DAY, {range})
}

export const logDayChange = (range: string, chart?: string, slice?: string) => {
  logEvent(EVENTS.DAY_CHANGE, {range, chart, slice})
}

export const logImportWakatime = (from: string) => {
  logEvent(EVENTS.IMPORT_WAKATIME, {from})
}

export const logFeaturePopularity = (feature: string) => {
  logEvent(EVENTS.FEATURE_POPULARITY, {feature})
}

export const logOpenShareModal = (range: string, project: boolean, chart?: string, slice?: string) => {
  logEvent(EVENTS.SHARE_MODAL, {range, chart, slice, project})
}
export const logShare = (social: string, range: string, project: boolean) => {
  logEvent(EVENTS.SHARE, {social, range, project})
}

export const logAddFriend = (source: string) => {
  logEvent(EVENTS.ADD_FRIEND, {source})
}

export const logAcceptFriendRequest = () => {
  logEvent(EVENTS.ACCEPT_FRIEND_REQUEST)
}

export const logDenyFriendRequest = () => {
  logEvent(EVENTS.DENY_FRIEND_REQUEST)
}

export const logCancelFriendRequest = () => {
  logEvent(EVENTS.CANCEL_FRIEND_REQUEST)
}

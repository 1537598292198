import {FC, ReactNode} from 'react'
import {Button as ChakraButton, Text, ButtonProps, forwardRef} from '@chakra-ui/react'
import {Icon, IIcon, TIconType} from '../Icons/Icon'

type TVariant = 'accent' | 'secondary' | 'negative' | 'withIcon' | 'ghost' | 'text'

export interface IButtonProps extends ButtonProps {
  variant: TVariant
  onClick: () => void
  icon?: TIconType
  children?: ReactNode | ReactNode[] | string
  iconProps?: Omit<IIcon, 'type'>
  iconPosition?: 'left' | 'right'
  textColor?: string
}

const bgColorMap = {
  accent: 'accent.1',
  secondary: 'black.3',
  negative: 'state.negative',
  withIcon: 'white.1-05',
  ghost: 'white.1-05',
  text: 'transparent'
}

const borderColorMap = {
  accent: 'accent.1-30',
  secondary: 'black.3-40',
  negative: 'state.negative-30',
  withIcon: 'white.1',
  ghost: 'transparent',
  text: 'transparent'
}

export const Button: FC<IButtonProps> = forwardRef<IButtonProps, 'button'>(
  ({iconProps, icon, variant, onClick, children, iconPosition = 'left', textColor = 'white.1', ...props}, ref) => {
    const isString = typeof children === 'string'
    return (
      <ChakraButton
        borderRadius={'4px'}
        h={'40px'}
        w={'100%'}
        opacity={0.8}
        alignItems={'center'}
        justifyContent={'center'}
        borderWidth={'1px'}
        borderColor={variant === 'withIcon' ? 'white.3' : 'transparent'}
        backgroundColor={bgColorMap[variant]}
        onClick={onClick}
        _hover={{
          opacity: 1,
          borderColor: borderColorMap[variant]
        }}
        ref={ref}
        {...props}>
        {variant === 'withIcon' && iconPosition === 'left' && icon && (
          <Icon type={icon} color={'white.1'} {...iconProps} />
        )}
        {isString && (
          <Text ml={variant === 'withIcon' ? '12px' : '0'} color={textColor} textStyle={'md'}>
            {children}
          </Text>
        )}
        {!isString && children}
        {variant === 'withIcon' && iconPosition === 'right' && icon && (
          <Icon type={icon} color={'white.1'} {...iconProps} />
        )}
      </ChakraButton>
    )
  }
)

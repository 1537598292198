import {FC, ReactNode} from 'react'
import {Box, BoxProps} from '@chakra-ui/react'

interface IProps extends BoxProps {
  onClick: () => void
  disabled?: boolean
  withoutPadding?: boolean
  toolkit?: ReactNode
  toolkitWidth?: number
}

export const PressableBox: FC<IProps> = ({
  children,
  disabled,
  onClick,
  withoutPadding,
  toolkit,
  toolkitWidth = 0,
  ...props
}) => {
  return (
    <Box
      cursor={disabled ? 'default' : 'pointer'}
      className={'pressable-box'}
      position={'relative'}
      onClick={disabled ? () => {} : onClick}
      w={'100%'}
      {...props}>
      <Box
        zIndex={2}
        className={'pressable-box-trigger'}
        left={withoutPadding ? 0 : '-8px'}
        display={disabled ? 'none' : 'block'}
        position={'absolute'}
        h={'100%'}
        minW={`calc(100% + ${withoutPadding ? toolkitWidth : 16 + toolkitWidth}px)`}>
        <Box
          sx={
            disabled
              ? {}
              : {
                  '.pressable-box-trigger:hover &': {
                    borderColor: 'black.5',
                    bgColor: 'black.5-40',
                    display: 'block'
                  }
                }
          }
          zIndex={-1}
          borderWidth={'1px'}
          borderRadius={'4px'}
          left={0}
          display={'none'}
          position={'absolute'}
          h={'100%'}
          w={`calc(100% - ${toolkitWidth}px)`}
        />

        {!!toolkit && (
          <Box
            sx={
              disabled
                ? {}
                : {
                    '.pressable-box-trigger:hover &': {
                      display: 'block'
                    }
                  }
            }
            zIndex={1}
            right={0}
            display={'none'}
            position={'absolute'}
            h={'100%'}
            w={`${toolkitWidth}px`}>
            {toolkit}
          </Box>
        )}
      </Box>
      {children}
    </Box>
  )
}

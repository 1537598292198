import { IGoal } from "../../types";

export const goals: IGoal[] = [
  {
    type: "START",
    value: 36000, // seconds from day start 36000 === 10:00
  },
  {
    type: "END",
    value: 72000, // seconds from day start 72000 === 20:00
  },
  {
    type: "DURATION",
    value: 4 * 60 * 60, // in seconds
  },
  {
    type: "DAYS",
    value: [0, 1, 2, 3, 4],
  },
];

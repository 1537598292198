import {INotification} from './notifications.interface'

export enum EWsMessageType {
  DATA_UPDATE = 'DATA_UPDATE',
  PLUGIN_FIRST_EVENT = 'PLUGIN_FIRST_EVENT',
  NOTIFICATION = 'NOTIFICATION',
}

export enum EDataUpdateType {
  ALL = 'ALL',
  LAST = 'LAST'
}

export interface IDataUpdateMessage {
  type: EWsMessageType.DATA_UPDATE
  update: EDataUpdateType
}

export interface IPluginFirstEventMessage {
  type: EWsMessageType.PLUGIN_FIRST_EVENT
  plugin: {
    id: string,
    type: string,
    createdAt: string,
    lastEvent: string
  },
}

export interface INotificationMessage {
  type: EWsMessageType.NOTIFICATION
  notification: INotification
}

export type TWsMessage = IDataUpdateMessage | IPluginFirstEventMessage | INotificationMessage
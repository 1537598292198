import React, {FC, useMemo} from 'react'
import {Center, CircularProgress, Flex, Link, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {IconButton} from 'components'
import {Link as DomLink, useLocation, useNavigate} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {prepareFriendsRequestData, prepareOverviewRequestData} from '@kaef/common/utils/helpers'
import {ERange, IFriend} from '@kaef/common/types'
import {api} from 'services/api'
import {FriendsChart} from 'components/FriendsChart/FriendsChart'
import {logAddFriend} from 'services/analytics/analytics'

interface IProps {
  range: ERange
  date: Date
}

export const FriendsLeaderboard: FC<IProps> = ({range, date}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()

  const friendsQuery = api.useFriendsStatsQuery(
    prepareFriendsRequestData({
      day: date,
      range,
      limit: 10
    }),
    {enabled: !!range}
  )
  const friendsRequestsQuery = api.useFriendsRequestsQuery()
  const userQuery = api.useUserInfoQuery()

  const dayOverview = api.useOverviewDayQuery(prepareOverviewRequestData(date, ERange.DAY), {
    enabled: !!range && range === ERange.DAY
  })
  const weekOverview = api.useOverviewWeekQuery(prepareOverviewRequestData(date, ERange.WEEK), {
    enabled: !!range && range === ERange.WEEK
  })
  const monthOverview = api.useOverviewMonthQuery(prepareOverviewRequestData(date, ERange.MONTH), {
    enabled: !!range && range === ERange.MONTH
  })
  const yearOverview = api.useOverviewYearQuery(prepareOverviewRequestData(date, ERange.YEAR), {
    enabled: !!range && range === ERange.YEAR
  })

  const selectTotalByRange = () => {
    switch (range) {
      case ERange.DAY:
        return dayOverview.data?.total
      case ERange.WEEK:
        return weekOverview.data?.total
      case ERange.MONTH:
        return monthOverview.data?.total
      case ERange.YEAR:
        return yearOverview.data?.total
      default:
        return dayOverview.data?.total
    }
  }

  const me = useMemo((): IFriend | null => {
    const total = selectTotalByRange()
    if (!userQuery.data || !total) {
      return null
    }

    return {
      id: userQuery.data.id,
      name: userQuery.data.name,
      email: userQuery.data.email,
      avatar: userQuery.data.picture,
      stats: {
        totalSeconds: total || 0,
        totalDurationGoal: total || 0
      }
    }
  }, [dayOverview.data, userQuery.data, weekOverview.data, monthOverview.data, yearOverview.data])

  const onAdd = () => {
    logAddFriend('leaderboard')
    navigate(paths.addFriend.path, {state: {backgroundLocation: location}})
  }

  return (
    <Flex direction={'column'} gap={'16px'}>
      <Flex justifyContent={'space-between'} align={'center'}>
        <Flex gap={'12px'} align={'center'}>
          <Text textStyle={'lg'}>{t('sidebar.friends.title')}</Text>
          <IconButton
            minW={'32px'}
            w={'32px'}
            h={'32px'}
            p={0}
            borderColor={'white.1-05'}
            bgColor={'black.3-40'}
            variant={'outline'}
            onClick={onAdd}
            icon={'plus'}
            color={'white.3'}
            aria-label={'add-friend'}
          />
        </Flex>
        <Link _hover={{textTransform: 'none'}} fontWeight={400} as={DomLink} color={'accent.1'} to={paths.friends.path}>
          {t('sidebar.friends.goto')}
        </Link>
      </Flex>
      {friendsQuery.isLoading && (
        <Center>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      )}
      {friendsQuery.data && (
        <FriendsChart friends={friendsQuery.data} me={me} incomingRequests={friendsRequestsQuery.data || []} />
      )}
    </Flex>
  )
}

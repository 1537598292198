import React, {FC} from 'react'
import {Divider, Flex, Text} from '@chakra-ui/react'
import {paths} from 'shared/constants/paths'
import {Button, Logo, Notifications, PluginIcon} from 'components'
import UserMenu from './components/UserMenu/UserMenu'
import {MobileMenu} from './components/MobileMenu/MobileMenu'
import {DesktopMenu} from './components/DesktopMenu/DesktopMenu'
import {UpgradeButton} from './components/UpgradeButton/UpgradeButton'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'
import {useNavigate} from 'react-router-dom'

const NavBar: FC = () => {
  const {isSubscriptionsEnabled} = useRemoteConfig()
  const navigate = useNavigate()

  return (
    <>
      <Flex maxW={'1400px'} m={'0 auto'} p={['0 16px 0', '20px 40px 20px', '20px 40px 0']} flexDirection={'column'}>
        <Flex w={'100%'} justifyContent={'space-between'}>
          <Flex>
            <Logo path={paths.dashboard.path} />
            <Flex display={['none', 'none', 'flex']} ml={'60px'}>
              <DesktopMenu />
            </Flex>
          </Flex>

          <Flex display={['none', 'none', 'flex']}>
            {isSubscriptionsEnabled && <UpgradeButton />}
            <Button onClick={() => navigate(`${paths.instructions.path}/wakatime-import`)}
                    mr={'12px'}
                    variant={'secondary'}>
              <Flex alignItems={'center'} flexShrink={0}>
                <PluginIcon flexShrink={0} type={'wakatime'} width={'24px'} height={'24px'} />
                <Text flexShrink={0} ml={'12px'} textStyle={'md'} color={'white.2'}>Import from Wakatime</Text>
              </Flex>
            </Button>
            <Notifications />
            <UserMenu />
          </Flex>

          <Flex display={['flex', 'flex', 'none']}>
            <Notifications />
            <MobileMenu />
          </Flex>
        </Flex>

        <Divider display={['none', 'block']} mt={'16px'} orientation={'horizontal'} bgColor={'white.3-20'} />
      </Flex>
    </>
  )
}

export default NavBar

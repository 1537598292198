import React from 'react'
import {Box, Text} from '@chakra-ui/react'

interface IFeatureItem {
  heading: string
  description: string
}

const FeatureItem = ({heading, description}: IFeatureItem) => {
  return (
    <Box minWidth={['auto', 'auto', 'auto', 'auto', '300px']}>
      <Text
        fontSize={['16px', '16px', '20px']}
        borderBottom={'1px solid rgba(251, 251, 251, 0.1)'}
        margin={['0 0 8px', '0 0 12px']}
        padding={['0 0 8px', '0 0 16px']}>
        {heading}
      </Text>
      <Text color={'white.3'} fontSize={'16px'}>
        {description}
      </Text>
    </Box>
  )
}

export default FeatureItem

import React, {FC, useEffect, useState} from 'react'
import {Flex, Center, CircularProgress} from '@chakra-ui/react'
import {IconButton, Modal, Stepper} from 'components'
import {ChooseName, ChooseStart, ChooseDuration, ChooseEnd, ChooseDays, SetupPlugin} from './scenes'
import {useNavigate} from 'react-router-dom'
import {useOnboardingStore} from 'stores'
import {api} from 'services/api'
import {paths} from 'shared/constants/paths'
import {useQueryClient} from '@tanstack/react-query'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {IUser} from '@kaef/common/types'
import {goalsKeys} from '../../../../common/api/goals'
import {userKeys} from '../../../../common/api/user'
import {ImportData} from './scenes/ImportData/ImportData'

type TOnboardingStep = 'NAME' | 'START' | 'END' | 'DURATION' | 'DAYS' | 'SETUP' | 'IMPORT'

const onboardingConfig: TOnboardingStep[] = ['NAME', 'START', 'END', 'DURATION', 'DAYS', 'SETUP', 'IMPORT']
const OnboardingScene: FC = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const [name, start, end, duration, days] = useOnboardingStore((state) => [
    state.name,
    state.start,
    state.end,
    state.duration,
    state.days
  ])
  const setName = useOnboardingStore((state) => state.setName)

  const [stepIndex, setStepIndex] = useState(0)

  const userQuery = api.useUserInfoQuery()
  const onboardingMutation = api.useOnboardingMutation()
  const step = onboardingConfig[stepIndex]

  const onNextStep = () => {
    if (step === 'DAYS') {
      onboardingMutation.mutate(
        {name, start, finish: end, duration, days},
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.goals())
            queryClient.setQueryData(['user'], (old) => ({
              ...(old as IUser),
              onboarding: {
                ...(old as IUser).onboarding,
                isOnboarded: true
              }
            }))
            queryClient.invalidateQueries(userKeys.all())
            queryClient.invalidateQueries(userKeys.weekPlan())
            queryClient.invalidateQueries(goalsKeys.all())
            queryClient.invalidateQueries(overviewKeys.goals())
          }
        }
      )
    }
    if (step === 'IMPORT') {
      navigate(paths.dashboard.path)
    }
    setStepIndex(stepIndex + 1)
  }

  useEffect(() => {
    if (userQuery.data) {
      setName(userQuery.data.name)
    }
  }, [userQuery.data])

  return (
    <Modal
      onClose={() => navigate(paths.dashboard.path)}
      withoutClose={stepIndex !== onboardingConfig.length - 1}
      size={'xl'}
      closeOnOverlayClick={stepIndex === onboardingConfig.length - 1}>
      {!onboardingMutation.isLoading && (
        <Flex h={'40px'} position={'relative'} alignItems={'center'} w={'100%'} top={'-14px'} justifyContent={'center'}>
          {stepIndex > 0 && (
            <IconButton
              left={0}
              position={'absolute'}
              icon={'arrowBack'}
              variant={'ghost'}
              onClick={() => setStepIndex(stepIndex - 1)}
              aria-label={'navToWelcome'}
            />
          )}
          <Stepper stepIndex={stepIndex} stepsAmount={onboardingConfig.length} />
        </Flex>
      )}
      {onboardingMutation.isLoading ? (
        <Center height={'334px'}>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      ) : (
        <>
          {step === 'NAME' && <ChooseName onNextStep={onNextStep} />}
          {step === 'START' && <ChooseStart onNextStep={onNextStep} />}
          {step === 'END' && <ChooseEnd onNextStep={onNextStep} />}
          {step === 'DURATION' && <ChooseDuration onNextStep={onNextStep} />}
          {step === 'DAYS' && <ChooseDays onNextStep={onNextStep} />}
          {step === 'SETUP' && <SetupPlugin onNextStep={onNextStep} />}
          {step === 'IMPORT' && <ImportData onNextStep={onNextStep} />}
        </>
      )}
    </Modal>
  )
}

export default OnboardingScene

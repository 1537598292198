import data from '@emoji-mart/data'
import Picker from '@emoji-mart/react'
import React, {FC, useState} from 'react'
import {Box, Button, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Portal} from '@chakra-ui/react'

interface IProps {
  icon: string
  onChange: (color: string) => void
}

export const EmojiPicker: FC<IProps> = ({icon, onChange}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onSelect = (emoji: any) => {
    onChange(emoji.native)
    setIsOpen(false)
  }

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} placement="top">
      <PopoverTrigger>
        <Button
          alignItems={'center'}
          onClick={() => setIsOpen(true)}
          variant={'ghost'}
          size={'sm'}
          padding={0}
          fontSize={'18px'}>
          {icon}
        </Button>
      </PopoverTrigger>
      <Portal>
        <Box
          sx={{
            '& .chakra-popover__popper': {
              zIndex: 'popover'
            }
          }}>
          <PopoverContent zIndex={20} w={'354px'} bgColor={'black.3'} borderColor={'white.1-05'}>
            <PopoverArrow bgColor={'black.3'} />
            <Picker
              theme={'dark'}
              data={data}
              onEmojiSelect={onSelect}
              previewPosition={'none'}
              searchPosition={'none'}
            />
          </PopoverContent>
        </Box>
      </Portal>
    </Popover>
  )
}

import {ERange, IFriendOverviewRequest} from '../../types'
import {format, startOfMonth, startOfWeek, startOfYear} from 'date-fns'

type TFriendRequest = {
  day: Date
  range: ERange
  limit?: number
  projectId?: string
  id?: string
}

export const prepareFriendsRequestData = ({
  day,
  range,
  limit,
  projectId,
  id
}: TFriendRequest): IFriendOverviewRequest => {
  let date = format(day, 'yyyy-MM-dd')
  switch (range) {
    case ERange.WEEK:
      date = format(startOfWeek(day, {weekStartsOn: 1}), 'yyyy-MM-dd')
      break
    case ERange.MONTH:
      date = format(startOfMonth(day), 'yyyy-MM-dd')
      break
    case ERange.YEAR:
      date = format(startOfYear(day), 'yyyy-MM-dd')
      break
  }
  return {
    date,
    range,
    ...(id ? {id} : {}),
    ...(limit ? {limit} : {}),
    ...(projectId ? {filter: {projectId}} : {})
  }
}

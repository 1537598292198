import React, {FC} from 'react'
import {Flex} from '@chakra-ui/react'
import {FriendsLeaderboard} from 'components'
import {overviewDateByRangeSelector, useFriendsStore} from 'stores'

interface IProps {}

export const FriendSidebar: FC<IProps> = () => {
  const friendsState = useFriendsStore()
  const date = overviewDateByRangeSelector(friendsState.range, friendsState)

  return (
    <Flex flexDirection={'column'} w={'100%'} gap={'8px'}>
      <FriendsLeaderboard date={date} range={friendsState.range} />
    </Flex>
  )
}

import React, {FC} from 'react'
import {ERange} from '@kaef/common/types'
import {Button} from 'components'
import {Text} from '@chakra-ui/react'
import {paths} from 'shared/constants/paths'
import {format} from 'date-fns'
import {logOpenShareModal} from 'services/analytics/analytics'
import {api} from 'services/api'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {
  overviewDateByRangeSelector,
  useDaySliceStore,
  useMonthSliceStore,
  useOverviewStore,
  useWeekSliceStore,
  useYearSliceStore
} from 'stores'

interface IProps {
  range: ERange
}

export const ShareButton: FC<IProps> = ({range}) => {
  const shareMutation = api.useShareToTwitterMutation()
  const navigate = useNavigate()
  const location = useLocation()
  const {id} = useParams()

  const overviewState = useOverviewStore((state) => state)
  const [dayChart, daySlice] = useDaySliceStore((state) => [state.chart, state.slice])
  const [weekChart, weekSlice] = useWeekSliceStore((state) => [state.chart, state.slice])
  const [monthChart, monthSlice] = useMonthSliceStore((state) => [state.chart, state.slice])
  const [yearChart, yearSlice] = useYearSliceStore((state) => [state.chart, state.slice])

  const date = overviewDateByRangeSelector(range, overviewState)

  const onShare = () => {
    navigate(`${paths.share.path}?date=${format(date, 'yyyy-MM-dd')}&type=${range}${!id ? '' : `&projectUid=${id}`}`, {
      state: {backgroundLocation: location}
    })
    switch (range) {
      case ERange.DAY:
        logOpenShareModal(range, !!id, dayChart, daySlice?.type)
        break
      case ERange.WEEK:
        logOpenShareModal(range, !!id, weekChart, weekSlice?.type)
        break
      case ERange.MONTH:
        logOpenShareModal(range, !!id, monthChart, monthSlice?.type)
        break
      case ERange.YEAR:
        logOpenShareModal(range, !!id, yearChart, yearSlice?.type)
        break
    }
  }

  return (
    <Button
      isLoading={shareMutation.isLoading}
      flexShrink={0}
      h={'28px'}
      w={'90px'}
      size={'sm'}
      variant={'withIcon'}
      iconProps={{width: 18, height: 18}}
      iconPosition={'left'}
      icon={'share'}
      onClick={onShare}>
      <Text textStyle={'md'} ml={'4px'}>
        Share
      </Text>
    </Button>
  )
}

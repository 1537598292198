import React, {FC, useState} from 'react'
import {Box, Flex, Popover, PopoverArrow, PopoverContent, PopoverTrigger, Button} from '@chakra-ui/react'
import {chartColors} from '@kaef/common/ui'

interface IProps {
  color: string
  onChange: (color: string) => void
}
export const ColorPicker: FC<IProps> = ({color, onChange}) => {
  const [isOpen, setIsOpen] = useState(false)

  const onColorSelect = (selectedColor: string) => {
    onChange(selectedColor)
    setIsOpen(false)
  }

  return (
    <Popover isOpen={isOpen} onClose={() => setIsOpen(false)} placement="top">
      <PopoverTrigger>
        <Button variant={'ghost'} size={'sm'} padding={0} onClick={() => setIsOpen(true)}>
          <Box w={'16px'} h={'16px'} borderRadius={'16px'} backgroundColor={color} />
        </Button>
      </PopoverTrigger>
      <PopoverContent w={'294px'} p={'16px'} bgColor={'black.3'} borderColor={'white.1-05'}>
        <PopoverArrow bgColor={'black.3'} />
        <Flex flexWrap={'wrap'} gap={'10px'}>
          {chartColors.map((item) => (
            <Box
              key={item}
              cursor={'pointer'}
              onClick={() => onColorSelect(item)}
              bgColor={item}
              w={'20px'}
              h={'20px'}
              borderRadius={'20px'}
            />
          ))}
        </Flex>
      </PopoverContent>
    </Popover>
  )
}

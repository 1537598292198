import {getUser, getUserInfo, getWeekPlan, linkPlugin, saveWeekPlan, sendWakatimeUrl, updateUser} from './user.api'
import { AxiosError, AxiosInstance } from "axios";
import { useMutation, useQuery } from "@tanstack/react-query";
import {IUser, IUserUpdate} from '../../types'
import { UseMutationOptions } from "@tanstack/react-query/src/types";
import {userKeys} from './user.keys'

export const useUserQuery = (
  options: any,
  api: AxiosInstance,
  isDemo?: boolean
) => {
  return useQuery<IUser>(userKeys.all(), () => getUser(api, isDemo), options);
};

export const useUserInfoQuery = (
  options: any,
  api: AxiosInstance
) => {
  return useQuery<IUser>(userKeys.info(), () => getUserInfo(api), options);
};

export const useUpdateUserMutation = (
  options: UseMutationOptions<IUserUpdate, AxiosError, IUserUpdate>,
  api: AxiosInstance,
) => {
  return useMutation<IUserUpdate, AxiosError, IUserUpdate>({
    mutationFn: (user: IUserUpdate) => updateUser(user, api),
    ...options,
  });
};

export const useLinkPluginMutation = (
  options: UseMutationOptions<string, AxiosError, string>,
  api: AxiosInstance
) => {
  return useMutation<string, AxiosError, string>({
    mutationFn: (pluginId: string) => linkPlugin(pluginId, api),
    ...options,
  });
};

export const useWeekPlanQuery = (
  options: any,
  api: AxiosInstance
) => {
  return useQuery<number[]>(userKeys.weekPlan(), () => getWeekPlan(api), options);
};


export const useSaveWeekPlanMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any, number[]>((data: number[]) => saveWeekPlan(data, api), options)
};

export const useSendWakatimeUrlMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any, string>((data: string) => sendWakatimeUrl(data, api), options)
};
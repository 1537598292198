import {FC} from 'react'
import {IconButton as ChakraIconButton, IconButtonProps} from '@chakra-ui/react'
import {Icon, IIcon, TIconType} from '../Icons/Icon'

interface IIconButton extends Omit<IconButtonProps, 'icon'> {
  icon: TIconType
  iconProps?: Omit<IIcon, 'type' | 'color'>
  color?: string
  variant?: 'solid' | 'ghost' | 'accent' | 'outline'
  isActive?: boolean
}

export const IconButton: FC<IIconButton> = ({
  isActive,
  icon,
  variant = 'solid',
  color,
  onClick,
  iconProps,
  ...props
}) => {
  return (
    <ChakraIconButton
      w={'40px'}
      h={'40px'}
      borderRadius={'8px'}
      onClick={onClick}
      variant={variant}
      colorScheme={color}
      icon={<Icon type={icon} color={color} {...iconProps} />}
      {...props}
    />
  )
}

import {
  acceptActionFriendRequest,
  getFriendRequests,
  getFriends,
  getFriendsStats,
  getFriendStats,
  sendFriendRequest
} from './friends.api'
import {AxiosInstance} from 'axios'
import {useMutation, useQuery} from '@tanstack/react-query'
import {friendsKeys} from './friends.keys'
import {EFriendRequestAction, IFriend, IFriendOverviewRequest} from '../../types'

export const useFriendsQuery = (options: any, api: AxiosInstance) => {
  return useQuery(friendsKeys.all(), () => getFriends(api), options)
}

export const useFriendsStatsQuery = (data: IFriendOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery(friendsKeys.stats(data), () => getFriendsStats(data, api), options)
}

export const useFriendStatsQuery = (data: IFriendOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery(friendsKeys.byId(data), () => getFriendStats(data, api), options)
}

export const useFriendsRequestsQuery = (options: any, api: AxiosInstance) => {
  return useQuery(friendsKeys.requests(), () => getFriendRequests(api), options)
}

export const useSendFriendRequestMutation = (options: any, api: AxiosInstance) => {
  return useMutation<void, any, string>((email: string) => sendFriendRequest(email, api), options)
}

export const useAcceptFriendRequestMutation = (options: any, api: AxiosInstance) => {
  return useMutation<void, any, IFriend>(
    (friend: IFriend) =>
      acceptActionFriendRequest(
        {
          friendId: friend.id,
          action: EFriendRequestAction.ACCEPT
        },
        api
      ),
    options
  )
}

export const useDenyFriendRequestMutation = (options: any, api: AxiosInstance) => {
  return useMutation<void, any, IFriend>(
    (friend: IFriend) =>
      acceptActionFriendRequest(
        {
          friendId: friend.id,
          action: EFriendRequestAction.DENY
        },
        api
      ),
    options
  )
}

export const useRemoveFriendMutation = (options: any, api: AxiosInstance) => {
  return useMutation<void, any, string>(
    (id: string) =>
      acceptActionFriendRequest(
        {
          friendId: id,
          action: EFriendRequestAction.REMOVE
        },
        api
      ),
    options
  )
}

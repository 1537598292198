import React, {FC, useMemo} from 'react'
import {Modal} from 'components'
import {useTranslation} from 'react-i18next'
import {Center, CircularProgress, Divider, Flex, Text} from '@chakra-ui/react'
import {PricingBlock} from '../../PricingBlock/PricingBlock'
import {subscriptions} from '@kaef/common/ui/constants/subscriptions'
import {api} from 'services/api'
import {IPricingItem} from '@kaef/common/types'
import {paths} from 'shared/constants/paths'
import {currencyMap} from '@kaef/common/utils/helpers/subscription.helper'
import {useGoBack} from 'shared/utils/navigation.helper'
import {logCheckoutSubscription} from 'services/analytics/analytics'

const PaywallModal: FC = () => {
  const {t} = useTranslation()
  const goBack = useGoBack()
  const {data, isLoading} = api.useSubscriptionsListQuery()
  const checkoutMutation = api.useCheckoutSubscriptionMutation()
  const {data: userData} = api.useUserInfoQuery()


  const onClose = () => {
    goBack(paths.dashboard.path)
  }

  const freePlan = {
    ...subscriptions[0],
    badgeKey: 'paywall.nextPlan'
  }

  const trialPlan = {
    ...subscriptions[3],
    price: t('subscription.trial.title'),
    badgeKey: 'paywall.currentPlan'
  }

  if (userData?.subscription.trial) {
    freePlan.badgeKey = 'paywall.nextPlan'
  }


  const subscriptionsList = (data || [])
    .map((item) => {
      const findSub = subscriptions.find((sub) => sub.id === item.key)
      if (!findSub) {
        return null
      }
      return {
        ...findSub,
        price: `${item.amount / 100}${currencyMap[item.ccy]}`
      }
    })
    .filter(Boolean) as IPricingItem[]

  const onCheckout = async (item: IPricingItem) => {
    logCheckoutSubscription(item.id)
    const url = await checkoutMutation.mutateAsync(item.id)
    window.open(url, '_self')
  }

  return (
    <Modal size={'2xl'} onClose={onClose}>
      <Flex h={'20px'} />
      <Flex justify={'space-between'} w={'100%'} gap={'20px'} >
        {userData?.subscription.trial && <PricingBlock secondBadge={t('subscription.daysLeft', {count: userData.subscription.daysUntilEnd || 0}) as string} item={trialPlan} badgeColor={'black.5'} shortVersion />}
        <PricingBlock item={freePlan} badgeColor={'black.5'} shortVersion />
      </Flex>
      <Divider mt={'32px'} mb={'20px'} />
      <Text textStyle={'xl'}>{t('paywall.title')}</Text>
      <Text textStyle={'md'} color={'white.3'}>
        {t('paywall.subtitle')}
      </Text>
      {isLoading && (
        <Center h={'200px'}>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      )}
      {!isLoading && (
        <Flex mt={'20px'} gap={'20px'} flexDirection={['column', 'row']}>
          {subscriptionsList.map((item) => (
            <PricingBlock
              buttonText={t('paywall.upgrade') as string}
              onClick={() => onCheckout(item)}
              key={item.id}
              item={item}
              badgeColor={item.id === 'pro_year' ? 'accent.1-30' : 'black.5'}
              isAccent={true}
            />
          ))}
        </Flex>
      )}
    </Modal>
  )
}

export default PaywallModal

import {FC, Fragment} from 'react'
import {Center, Divider, Flex, Text} from '@chakra-ui/react'
import {Icon} from 'components'

interface IProps {
  features: string[]
  mode: 'done' | 'inprogress'
  badge?: string
}

export const FeatureList: FC<IProps> = ({features, mode, badge}) => {
  return (
    <Flex flexDirection={'column'} width={'100%'} alignItems={'flex-start'}>
      {features.map((item, index) => {
        const isFirst = index === 0
        const icon = mode === 'done' ? 'check' : isFirst ? 'flash' : 'circle'
        const iconColor = mode === 'done' ? 'white.1' : isFirst ? 'logo.neon' : 'white.1-10'
        const textColor = mode === 'done' ? 'white.1' : isFirst ? 'logo.neon' : 'white.1'
        return (
          <Fragment key={index}>
            {!isFirst && (
              <Center height="16px" ml={'12px'}>
                <Divider orientation="vertical" />
              </Center>
            )}
            <Flex alignItems={'center'} h={'44px'}>
              <Icon type={icon} color={iconColor} width={24} height={24} />
              <Text ml={'11px'} textStyle={'lg'} color={textColor}>
                {item}
              </Text>
              {isFirst && mode === 'inprogress' && (
                <Text ml={'12px'} bgColor={'logo.neon-10'} p={'4px 8px'} borderRadius={'8px'} color={'logo.neon'}>
                  {badge}
                </Text>
              )}
            </Flex>
          </Fragment>
        )
      })}
    </Flex>
  )
}

import {FC} from 'react'
import {Flex, FlexProps, Text} from '@chakra-ui/react'

interface IProps extends FlexProps {
  value: string
  title: string
}

export const Stat: FC<IProps> = ({value, title, ...containerProps}) => {
  if (!value) {
    return null
  }
  return (
    <Flex flexDirection={'column'} {...containerProps}>
      <Text textStyle={'sm'} color={'white.3'} mb={'2px'}>
        {title}
      </Text>
      <Text textStyle={'lg'} color={'white.1'}>
        {value}
      </Text>
    </Flex>
  )
}

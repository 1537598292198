import {create} from 'zustand'
import {startOfMonth, startOfWeek, startOfYear} from 'date-fns'
import {ERange, IMonthSlice, ISlice, IWeekSlice, IYearSlice, TChart} from '@kaef/common/types'
import {IFriendsState} from '../friends/friends.store'

export interface IOverviewState {
  day: Date
  setDay: (date: Date) => void
  week: Date
  setWeek: (date: Date) => void
  month: Date
  setMonth: (date: Date) => void
  year: Date
  setYear: (date: Date) => void
  range: ERange
  setRange: (range: ERange) => void
}

export const useOverviewStore = create<IOverviewState>()((set) => ({
  day: new Date(),
  setDay: (day) => set({day}),
  week: startOfWeek(new Date(), {weekStartsOn: 1}),
  setWeek: (week) => set({week}),
  month: startOfMonth(new Date()),
  setMonth: (month) => set({month}),
  year: startOfYear(new Date()),
  setYear: (year) => set({year}),
  range: ERange.DAY,
  setRange: (range: ERange) => set({range})
}))

export const overviewDateByRangeSelector = (range: ERange, state: IOverviewState | IFriendsState) => {
  switch (range) {
    default:
    case ERange.DAY:
      return state.day
    case ERange.WEEK:
      return state.week
    case ERange.MONTH:
      return state.month
    case ERange.YEAR:
      return state.year
  }
}

interface ISliceState<T> {
  slice: T | undefined
  setSlice: (slice: T | undefined) => void
  chart: TChart
  setChart: (chart: TChart) => void
}

export const useDaySliceStore = create<ISliceState<ISlice>>()((set) => ({
  slice: undefined,
  setSlice: (slice) => set({slice}),
  chart: 'DAY_TOTAL',
  setChart: (chart) => set({chart})
}))

export const useWeekSliceStore = create<ISliceState<IWeekSlice>>()((set) => ({
  slice: undefined,
  setSlice: (slice) => set({slice}),
  chart: 'WEEK_AVERAGE',
  setChart: (chart) => set({chart})
}))

export const useMonthSliceStore = create<ISliceState<IMonthSlice>>()((set) => ({
  slice: undefined,
  setSlice: (slice) => set({slice}),
  chart: 'MONTH_CALENDAR_TOTAL',
  setChart: (chart) => set({chart})
}))

export const useYearSliceStore = create<ISliceState<IYearSlice>>()((set) => ({
  slice: undefined,
  setSlice: (slice) => set({slice}),
  chart: 'YEAR_TOTAL',
  setChart: (chart) => set({chart})
}))

import {
  differenceInCalendarDays,
  endOfMonth,
  endOfWeek,
  endOfYear,
  parse,
  startOfMonth,
  startOfWeek,
  startOfYear
} from 'date-fns'
import React, {FC, useEffect, useMemo} from 'react'
import {useDaySliceStore, useMonthSliceStore, useOverviewStore, useWeekSliceStore, useYearSliceStore} from 'stores'
import {EAbility, ERange} from '@kaef/common/types'
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {api} from 'services/api'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'
import {useIsPrevRangeLocked} from 'components/RangeSwitcher/useIsPrevRangeLocked'
import {logChangeOverview, logDayChange, logOpenPaywall} from 'services/analytics/analytics'
import {RangeSwitcher} from 'components/RangeSwitcher/RangeSwitcher'

interface IProps {
  range: ERange
}

export const OverviewRangeSwitcher: FC<IProps> = ({range}) => {
  const navigate = useNavigate()
  const location = useLocation()
  const [searchParams, setSearchParams] = useSearchParams()
  const {isSubscriptionsEnabled, freeDaysPeriod} = useRemoteConfig()
  const userInfoQuery = api.useUserInfoQuery()

  const isFullAccess = useMemo(() => {
    return !isSubscriptionsEnabled || userInfoQuery.data?.subscription.abilities.includes(EAbility.INFINITY_HISTORY)
  }, [isSubscriptionsEnabled, userInfoQuery.data?.subscription])

  const [day, setDay, week, setWeek, month, setMonth, year, setYear] = useOverviewStore((state) => [
    state.day,
    state.setDay,
    state.week,
    state.setWeek,
    state.month,
    state.setMonth,
    state.year,
    state.setYear
  ])

  const [dayChart, daySlice] = useDaySliceStore((state) => [state.chart, state.slice])
  const [weekChart, weekSlice] = useWeekSliceStore((state) => [state.chart, state.slice])
  const [monthChart, monthSlice] = useMonthSliceStore((state) => [state.chart, state.slice])
  const [yearChart, yearSlice] = useYearSliceStore((state) => [state.chart, state.slice])

  const date = useMemo(() => {
    switch (range) {
      default:
      case 'day':
        return day
      case 'week':
        return week
      case 'month':
        return month
      case 'year':
        return year
    }
  }, [range, day, week, month, year])
  const isPrevLocked = useIsPrevRangeLocked(range, date, freeDaysPeriod) && !isFullAccess

  const onChange = (value: Date) => {
    switch (range) {
      case 'day':
        setDay(value)
        logChangeOverview(range, dayChart, daySlice?.type)
        logDayChange(range, dayChart, daySlice?.type)
        break
      case 'week':
        setWeek(value)
        logChangeOverview(range, weekChart, weekSlice?.type)
        logDayChange(range, weekChart, weekSlice?.type)
        break
      case 'month':
        setMonth(value)
        logChangeOverview(range, monthChart, monthSlice?.type)
        logDayChange(range, monthChart, monthSlice?.type)
        break
      case 'year':
        setYear(value)
        logChangeOverview(range, yearChart, yearSlice?.type)
        logDayChange(range, yearChart, yearSlice?.type)
        break
    }
  }

  const onRedirectToPaywall = () => {
    onChange(new Date())
    logOpenPaywall('day_change')
    navigate(`${paths.paywall.path}`, {state: {backgroundLocation: location}})
    return
  }

  const onDayFromParam = (date: Date) => {
    switch (range) {
      case 'day':
        onChange(date)
        break
      case 'week':
        if (
          !isFullAccess &&
          differenceInCalendarDays(new Date(), endOfWeek(date, {weekStartsOn: 1})) > freeDaysPeriod
        ) {
          return onRedirectToPaywall()
        }
        onChange(startOfWeek(date, {weekStartsOn: 1}))
        break
      case 'month':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfMonth(date)) > freeDaysPeriod) {
          return onRedirectToPaywall()
        }
        onChange(startOfMonth(date))
        break
      case 'year':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfYear(date)) > freeDaysPeriod) {
          return onRedirectToPaywall()
        }
        onChange(startOfYear(date))
        break
    }
  }

  useEffect(() => {
    if (searchParams.has('day')) {
      let date = new Date()
      try {
        date = parse(searchParams.get('day') || '', 'yyyy-MM-dd', new Date())
      } catch (err) {}
      onDayFromParam(date)
      searchParams.delete('day')
      setSearchParams(searchParams)
      return
    }
    if (searchParams.has('date')) {
      let date = new Date()
      try {
        date = parse(searchParams.get('date') || '', 'yyyy-MM-dd', new Date())
      } catch (err) {}
      onDayFromParam(date)
      searchParams.delete('date')
      setSearchParams(searchParams)
    }
  }, [searchParams])

  useEffect(() => {
    switch (range) {
      case 'day':
        if (!isFullAccess && differenceInCalendarDays(new Date(), date) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
      case 'week':
        if (
          !isFullAccess &&
          differenceInCalendarDays(new Date(), endOfWeek(date, {weekStartsOn: 1})) > freeDaysPeriod
        ) {
          onRedirectToPaywall()
        }
        break
      case 'month':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfMonth(date)) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
      case 'year':
        if (!isFullAccess && differenceInCalendarDays(new Date(), endOfYear(date)) > freeDaysPeriod) {
          onRedirectToPaywall()
        }
        break
    }
  }, [date])

  const onOpenPaywall = () => {
    logOpenPaywall('day_change')
    navigate(`${paths.paywall.path}`, {state: {backgroundLocation: location}})
  }

  return (
    <RangeSwitcher range={range} date={date} isPrevLocked={isPrevLocked} onChange={onChange} onLocked={onOpenPaywall} />
  )
}

import {api} from './api'
import {useEffect} from 'react'
import {EDataUpdateType, ENotificationType, EWsMessageType} from '@kaef/common/types'
import {useQueryClient} from '@tanstack/react-query'
import {overviewForDateKeys, overviewKeys} from '@kaef/common/api/overview'
import {notificationsKeys} from '@kaef/common/api/notifications'
import {userKeys} from '@kaef/common/api/user'

export const useWebSocket = () => {
  const {data} = api.useWsLastMessageQuery()
  const queryClient = useQueryClient()

  useEffect(() => {
    if (!data) {
      return
    }
    switch (data.type) {
      case EWsMessageType.DATA_UPDATE:
        if (data.update === EDataUpdateType.ALL) {
          queryClient.invalidateQueries(overviewKeys.all())
          return
        }
        if (data.update === EDataUpdateType.LAST) {
          overviewForDateKeys(new Date()).forEach(key => queryClient.invalidateQueries(key))
          return
        }
        return
      case EWsMessageType.PLUGIN_FIRST_EVENT:
        queryClient.invalidateQueries(overviewKeys.all())
        queryClient.invalidateQueries(userKeys.info())
        return
      case EWsMessageType.NOTIFICATION:
        if (data.notification.type === ENotificationType.WAKATIME_IMPORT) {
          queryClient.invalidateQueries(overviewKeys.all())
        }
        queryClient.invalidateQueries(notificationsKeys.all())
        return
    }
  }, [data])
}
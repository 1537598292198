import React, {FC} from 'react'
import {Button, Input, Modal} from '../../UI'
import {api} from 'services/api'
import {Text, Flex} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'
import {emailRegex} from '@kaef/common/utils/helpers'
import {useForm} from 'react-hook-form'

interface IProps {}

export const AddFriendModal: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const {register, handleSubmit, formState} = useForm<{email: string}>({
    mode: 'onBlur'
  })

  const sendFriendRequestMutation = api.useSendFriendRequestMutation()

  const goBack = useGoBack()

  const onClose = () => {
    goBack(paths.addFriend.backgroundPath)
  }

  const onSave = (data: {email: string}) => {
    sendFriendRequestMutation.mutate(data.email, {
      onSuccess: () => {
        onClose()
      }
    })
  }

  return (
    <Modal size={'md'} onClose={onClose}>
      <Text mb={'24px'} textStyle={'lg'}>
        {t('addFriend.title')}
      </Text>

      <Input
        {...register('email', {required: t('authorization.errors.emailRequired') as string, pattern: emailRegex})}
        type={'email'}
        placeholder={String(t('addFriend.placeholder'))}
        isInvalid={!!formState.errors?.email}
      />

      <Flex gap={'20px'} mt={'32px'} justifyContent={'flex-end'}>
        <Button mb={'16px'} onClick={onClose} variant={'secondary'} disabled={sendFriendRequestMutation.isLoading}>
          {t('addFriend.cancel')}
        </Button>
        <Button onClick={handleSubmit(onSave)} variant={'accent'} isLoading={sendFriendRequestMutation.isLoading}>
          {t('addFriend.sendRequest')}
        </Button>
      </Flex>
    </Modal>
  )
}

import { AxiosInstance } from "axios";
import {IOnboardingData} from '../../types';

export const onboarding = async (data: IOnboardingData, api: AxiosInstance, isDemo?: boolean) => {
  if (isDemo) {
    return true;
  }
  const response = await api.post("/user/onboarding", data);
  return response.data;
};

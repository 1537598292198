import {IMap, ISliceData, TMappedRange, TRange} from '../../types'
import {toDate} from './date.helper'
import {differenceInSeconds, startOfDay} from 'date-fns'
import {TDayLineRange} from '@kaef/web/src/components'
import {colorFuse} from './colors.helper'

export const generateTimeRangeValues = (
  range: TRange,
  rangeStart: Date,
  rangeInSeconds: number
) => {
  const startDate = toDate(range.from)
  const start = differenceInSeconds(startDate, rangeStart)
  const startPercent = (start / rangeInSeconds) * 100
  const endDate = toDate(range.to)
  const end = differenceInSeconds(endDate, rangeStart)
  const endPercent = (end / rangeInSeconds) * 100
  return {
    start,
    startDate,
    startPercent,
    end,
    endDate,
    endPercent
  }
}

export const generateRangeValuesWeek = (
  ranges: TMappedRange[] | undefined,
  sliceData: IMap<ISliceData>,
  day: Date,
  rangeStart: Date,
  rangeEnd: Date
): TDayLineRange[] => {
  if (!ranges || ranges.length === 0) {
    return []
  }
  const rangeInSeconds = differenceInSeconds(rangeEnd, rangeStart)
  const startOfDayForCalc = startOfDay(day)
  const rangeStartWithOffset = new Date(rangeStart.getTime())
  rangeStartWithOffset.setFullYear(startOfDayForCalc.getFullYear())
  rangeStartWithOffset.setMonth(startOfDayForCalc.getMonth())
  rangeStartWithOffset.setDate(startOfDayForCalc.getDate())
  return ranges.map((range) => {
    const color = colorFuse(sliceData[range.id].color)
    return {
      ...generateTimeRangeValues(range, rangeStartWithOffset, rangeInSeconds),
      color
    }
  })
}

export const generateRangeValues = (
  ranges: TMappedRange[] | undefined,
  rangeStart: Date,
  rangeEnd: Date,
  color: string,
  data?: Map<number, ISliceData>
): TDayLineRange[] => {
  if (!ranges || ranges.length === 0) {
    return []
  }
  const rangeInSeconds = differenceInSeconds(rangeEnd, rangeStart)
  return ranges.map((range) => {
    return {
      ...generateTimeRangeValues(range, rangeStart, rangeInSeconds),
      color: range.color || data?.get(range.id)?.color || color
    }
  })
}

import React, {FC, useEffect} from 'react'
import {Box, Flex} from '@chakra-ui/react'
import OverviewMain from './components/OverviewMain/OverviewMain'
import OverviewSidebar from './components/OverviewSidebar/OverviewSidebar'
import {useLocation, useNavigate, useParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {api} from 'services/api'
import {AnimatePresence, motion} from 'framer-motion'
import {ERange, ISlice, TChart} from '@kaef/common/types'
import {ButtonGroup} from 'components'
import {useTranslation} from 'react-i18next'
import {logChangeOverview, logChangeRange} from 'services/analytics/analytics'
import {DayTypeSwitcher} from './components/DayTypeSwitcher/DayTypeSwitcher'
import {ShareButton} from './components/ShareButton/ShareButton'
import {useDaySliceStore, useMonthSliceStore, useWeekSliceStore, useYearSliceStore} from 'stores'
import {OverviewRangeSwitcher} from './components/OverviewMain/components/RangeSwitcher/OverviewRangeSwitcher'

const useCurrentChartSlice = () => {
  const [dayChart, daySlice] = useDaySliceStore((state) => [state.chart, state.slice])
  const [weekChart, weekSlice] = useWeekSliceStore((state) => [state.chart, state.slice])
  const [monthChart, monthSlice] = useMonthSliceStore((state) => [state.chart, state.slice])
  const [yearChart, yearSlice] = useYearSliceStore((state) => [state.chart, state.slice])

  return (range: ERange): [chart: TChart, slice: ISlice | undefined] => {
    switch (range) {
      case ERange.DAY:
        return [dayChart, daySlice]
      case ERange.WEEK:
        return [weekChart, weekSlice]
      case ERange.MONTH:
        return [monthChart, monthSlice]
      case ERange.YEAR:
        return [yearChart, yearSlice]
      default:
        return [dayChart, daySlice]
    }
  }
}

const Overview: FC = () => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const {range, id} = useParams()
  const {data: userData} = api.useUserInfoQuery()
  const getCurrentChartSlice = useCurrentChartSlice()
  const rangeOptions = [
    {
      label: t('overview.ranges.d'),
      value: ERange.DAY
    },
    {
      label: t('overview.ranges.w'),
      value: ERange.WEEK
    },
    {
      label: t('overview.ranges.m'),
      value: ERange.MONTH
    },
    {
      label: t('overview.ranges.y'),
      value: ERange.YEAR
    }
  ]

  const changeRange = (value: ERange) => {
    logChangeRange(value)
    const [chart, slice] = getCurrentChartSlice(value)
    logChangeOverview(value, chart, slice?.type)
    navigate(`${paths.overview.path}/${value}/${id || ''}`)
  }

  useEffect(() => {
    if (location.pathname === paths.overview.path) {
      navigate(`${paths.overview.path}/day`, {replace: true})
    }
  }, [location])

  useEffect(() => {
    if (!userData) {
      return
    }
    if (!userData.onboarding?.isOnboarded) {
      navigate(paths.onboarding.path, {state: null})
      return
    }
  }, [userData])

  return (
    <Flex w={'100%'} alignItems={'center'} flexDirection={'column'}>
      <Flex
        maxW={'1400px'}
        pl={[0, '40px', '40px']}
        pr={[0, '40px', '40px']}
        pt={[0, 0, '40px']}
        mb={'24px'}
        w={'100%'}>
        <Flex
          w={'100%'}
          bgColor={'white.1-05'}
          borderWidth={'1px'}
          borderColor={'black.5-40'}
          borderRadius={'8px'}
          p={[0, '8px']}
          justifyContent={'space-between'}
          flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']}>
          <Flex gap={'12px'} align={'center'}>
            {!!range ? <OverviewRangeSwitcher range={range as ERange} /> : <Flex />}
            {!!range ? <DayTypeSwitcher range={range as ERange} /> : <Flex />}
            {!!range ? <ShareButton range={range as ERange} /> : <Flex />}
          </Flex>
          <ButtonGroup
            options={rangeOptions}
            value={range}
            onChange={changeRange}
            containerProps={{
              w: ['100%', '100%', '100%', '100%', '368px'],
              mb: ['12px', '12px', '12px', '12px', 0],
              flexShrink: 0,
              bg: 'black.4'
            }}
          />
        </Flex>
      </Flex>

      <Flex
        maxW={'1400px'}
        w={'100%'}
        alignItems={['center', 'center', 'flex-start']}
        flexDirection={['column', 'column', 'row']}
        pl={[0, '40px', '40px']}
        pr={[0, '40px', '40px']}>
        <Box flexGrow={0} w={'100%'} mr={[0, 0, '24px']} mb={['24px', '24px', 0]}>
          <OverviewMain />
        </Box>
        <Box flexGrow={0} w={'100%'} maxWidth={['415px', '383px', '383px']}>
          <AnimatePresence>
            {!id && (
              <motion.div
                transition={{duration: 0.2, type: 'tween', ease: 'easeOut'}}
                initial={{opacity: 0, x: 200}}
                animate={{opacity: 1, x: 0}}
                exit={{opacity: 0, x: 200}}>
                <OverviewSidebar />
              </motion.div>
            )}
          </AnimatePresence>
        </Box>
      </Flex>
    </Flex>
  )
}

export default Overview

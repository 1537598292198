import React, {FC} from 'react'
import {Flex, Heading, List, ListItem, Text} from '@chakra-ui/react'
import {Button, Icon} from '../UI'
import {IPricingItem} from '@kaef/common/types'
import {useTranslation} from 'react-i18next'

interface IPricingBlock {
  item: IPricingItem
  secondBadge?: string
  badgeColor: string
  isAccent?: boolean
  shortVersion?: boolean
  onClick?: () => void
  buttonText?: string
}

export const PricingBlock: FC<IPricingBlock> = ({
  buttonText,
  onClick = () => {},
  shortVersion = false,
  item,
  isAccent,
  badgeColor,
  secondBadge
}) => {
  const {t} = useTranslation()
  return (
    <Flex
      width={'305px'}
      minH={shortVersion ? 'auto' : '350px'}
      flexDirection={'column'}
      padding={'24px'}
      position={'relative'}
      bg={'white.1-05'}
      borderRadius={'8px'}>
      {item.badgeKey ? (
        <Flex position={'absolute'} top={'-10px'} left={'12px'}>
          <Text borderRadius={'8px'} padding={'0 12px'} bg={badgeColor}>
            {t(item.badgeKey)}
          </Text>
          {secondBadge && (
            <Text ml={'12px'} borderRadius={'8px'} padding={'0 12px'} bg={'black.5'}>
              {secondBadge}
            </Text>
          )}
        </Flex>


      ) : null}

      {!shortVersion && (
        <Flex h={'100px'} borderBottomWidth={'1px'} borderBottomColor={'white.1-10'} flexDirection={'column'}>
          <Heading as={'h3'} fontWeight={'400'} margin={'0 0 10px'} fontSize={'20px'}>
            {t(item.titleKey)}
          </Heading>
          <Text textStyle={'md'} color={'white.3'} padding={'0 0 24px'} margin={'0 0 15px'}>
            {t(item.subtitleKey)}
          </Text>
        </Flex>
      )}
      <Flex margin={'0 0 8px'} gridGap={'0 8px'} alignItems={'baseline'}>
        <Text fontSize={'40px'} fontWeight={'500'}>
          {item.price}
        </Text>
        {item.periodKey ? (
          <Text textStyle={'md'} color={'white.3'}>
            {t(item.periodKey)}
          </Text>
        ) : null}
      </Flex>
      {!shortVersion && (
        <Button
          onClick={onClick}
          variant={isAccent ? 'accent' : 'secondary'}
          margin={'0 0 20px'}
          border={isAccent ? 'none' : '1px solid'}>
          {buttonText?.toUpperCase()}
        </Button>
      )}
      <List spacing={'8px'}>
        {item.featuresKeys.map((key, idx) => (
          <ListItem display={'flex'} alignItems={'center'} color={'white.3'} key={idx}>
            <Icon height={14} width={16} type={'check'} color={'white.3'} />
            <Text ml={'8px'} textStyle={'md'}>
              {t(key)}
            </Text>
          </ListItem>
        ))}
      </List>
    </Flex>
  )
}

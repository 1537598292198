import {FC, Suspense, useEffect} from 'react'
import {matchPath, Navigate, Route, Routes, useLocation} from 'react-router-dom'

import {ProtectedRoute} from 'components/ProtectedRoute'
import {paths} from './shared/constants/paths'
import LinkPlugin from './scenes/RedirectsWithAction/LinkPlugin/LinkPlugin'
import {useAuth} from './services/auth/useAuth'
import {Center, CircularProgress, useColorMode} from '@chakra-ui/react'
import {useFirebaseRoutesAnalytics} from './services/firebase/useFirebaseRoutesAnalytics'
import {APP_VERSION} from './config'
import LinkPluginConfirm from './scenes/RedirectsWithAction/LinkPluginConfirm/LinkPluginConfirm'
import PaywallModal from './components/Modals/Paywall/Paywall.modal'
import SubscriptionModal from './components/Modals/Subscription/Subscription.modal'
import NotificationsModal from './components/Modals/Notifications/Notifications.modal'
import LandingRouter from './scenes/Landing/LandingRouter'
import HomeScene from './scenes/Landing/scenes/Home/Home.scene'
import TermsScene from './scenes/Terms/Terms.scene'
import PrivacyScene from './scenes/Privacy/Privacy.scene'
import DashboardRouter from './scenes/Dashboard/DashboardRouter'
import StatisticsScene from './scenes/Dashboard/scenes/Statistics/Statistics.scene'
import GoalsScene from './scenes/Dashboard/scenes/Goals/Goals.scene'
import ProjectsScene from './scenes/Dashboard/scenes/Projects/Projects.scene'
import PluginsScene from './scenes/Dashboard/scenes/Plugins/Plugins.scene'
import TrendsScene from './scenes/Dashboard/scenes/Trends/Trends.scene'
import AuthorizationScene from './scenes/Authorization/Authorization.scene'
import OnboardingScene from 'scenes/Onboarding/Onboarding.scene'
import OverviewScene from './scenes/Dashboard/scenes/Overview/Overview.scene'
import EditGoalModal from 'scenes/Dashboard/scenes/Overview/components/OverviewSidebar/components/TodayGoals/components/EditGoalModal/EditGoalModal'
import EditProjectModal from 'components/Modals/Project/EditProject.modal'
import ProfileModal from 'components/Modals/Profile/Profile.modal'
import InstructionsModal from 'components/Modals/Instructions/Instructions.modal'
import {useWebSocket} from './services/api'
import FriendsScene from './scenes/Dashboard/scenes/Friends/Friends.scene'
import {AddFriendModal, ShareModal} from './components'
import FriendScene from './scenes/Dashboard/scenes/Friends/scenes/Friend/Friend.scene'

/**
 * This module manages all routes
 *
 * The lazy component should be a module with a default export
 * https://reactjs.org/docs/code-splitting.html#reactlazy
 */
/*
const LazyLandingRouter = lazy(() => import('scenes/Landing/LandingRouter'))
const LazyDashboardRouter = lazy(() => import('scenes/Dashboard/DashboardRouter'))
const LazyOverviewScene = lazy(() => import('scenes/Dashboard/scenes/Overview/Overview.scene'))
const LazyStatisticsScene = lazy(() => import('scenes/Dashboard/scenes/Statistics/Statistics.scene'))
const LazyGoalsScene = lazy(() => import('scenes/Dashboard/scenes/Goals/Goals.scene'))
const LazyProjectsScene = lazy(() => import('scenes/Dashboard/scenes/Projects/Projects.scene'))
const LazyPluginsScene = lazy(() => import('scenes/Dashboard/scenes/Plugins/Plugins.scene'))
const LazyPrivacyScene = lazy(() => import('scenes/Privacy/Privacy.scene'))
const LazyTermsScene = lazy(() => import('scenes/Terms/Terms.scene'))
const LazyHomeScene = lazy(() => import('scenes/Landing/scenes/Home/Home.scene'))
const LazyTrendsScene = lazy(() => import('scenes/Dashboard/scenes/Trends/Trends.scene'))

const LazyOnboardingScene = lazy(() => import('scenes/Onboarding/Onboarding.scene'))
const LazyEditGoalScene = lazy(
  () =>
    import(
      'scenes/Dashboard/scenes/Overview/components/OverviewSidebar/components/TodayGoals/components/EditGoalModal/EditGoalModal'
    )
)
const LazyEditProjectScene = lazy(() => import('components/Modals/Project/EditProject.modal'))
const LazyAuthorizationScene = lazy(() => import('scenes/Authorization/Authorization.scene'))
const LazyProfileScene = lazy(() => import('components/Modals/Profile/Profile.modal'))
const LazyInstructionsScene = lazy(() => import('components/Modals/Instructions/Instructions.modal'))
*/

type TProps = {}

const AppRouter: FC<TProps> = () => {
  const location = useLocation()
  useFirebaseRoutesAnalytics()
  useWebSocket()
  const isReady = useAuth()
  const {colorMode, toggleColorMode} = useColorMode()
  useEffect(() => {
    if (colorMode === 'light') {
      toggleColorMode()
    }
    console.log('version', APP_VERSION)
  }, [])
  const state = location.state as {backgroundLocation?: Location}
  const currentPath = Object.values(paths).find((item) => {
    return item.path === location.pathname || (item.relPath && matchPath({path: item.relPath}, location.pathname))
  })
  const mainLocation = currentPath?.isModal
    ? state?.backgroundLocation || currentPath?.backgroundPath
    : state?.backgroundLocation || location

  if (!isReady) {
    return (
      <Center h={'100vh'} w={'100%'}>
        <CircularProgress isIndeterminate color={'accent.1'} />
      </Center>
    )
  }
  return (
    <Suspense fallback={<></>}>
      <Routes location={mainLocation}>
        <Route path={paths.home.path} element={<LandingRouter />}>
          <Route path={paths.home.path} element={<HomeScene />} />
          <Route path={paths.terms.path} element={<TermsScene />} />
          <Route path={paths.privacy.path} element={<PrivacyScene />} />
        </Route>

        <Route path={paths.linkPlugin.path} element={<LinkPlugin />} />
        <Route
          path={`${paths.linkPluginConfirm.path}/:id`}
          element={<ProtectedRoute component={LinkPluginConfirm} />}
        />
        <Route path={paths.dashboard.path} element={<ProtectedRoute component={DashboardRouter} />}>
          <Route path={`${paths.overview.path}/:range?/:id?`} element={<ProtectedRoute component={OverviewScene} />} />
          <Route path={paths.statistics.path} element={<ProtectedRoute component={StatisticsScene} />} />
          <Route path={paths.goals.path} element={<ProtectedRoute component={GoalsScene} />} />
          <Route path={paths.projects.path} element={<ProtectedRoute component={ProjectsScene} />} />
          <Route path={paths.plugins.path} element={<ProtectedRoute component={PluginsScene} />} />
          <Route path={paths.trends.path} element={<ProtectedRoute component={TrendsScene} />} />
          <Route path={`${paths.friends.path}/:id`} element={<ProtectedRoute component={FriendScene} />} />
          <Route path={paths.friends.path} element={<ProtectedRoute component={FriendsScene} />} />
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
      </Routes>
      {/* Show the modal when a `backgroundLocation` is set */}
      {(state?.backgroundLocation || currentPath?.isModal) && (
        <Routes>
          <Route path={`${paths.authorization.path}/*`} element={<AuthorizationScene />} />
          <Route path={`${paths.onboarding.path}`} element={<ProtectedRoute component={OnboardingScene} />} />
          <Route path={`${paths.editGoal.path}`} element={<ProtectedRoute component={EditGoalModal} />} />
          <Route path={`${paths.editProject.path}/:id`} element={<ProtectedRoute component={EditProjectModal} />} />
          <Route path={`${paths.profile.path}`} element={<ProtectedRoute component={ProfileModal} />} />
          <Route path={`${paths.instructions.path}/:type`} element={<ProtectedRoute component={InstructionsModal} />} />
          <Route path={`${paths.paywall.path}`} element={<ProtectedRoute component={PaywallModal} />} />
          <Route path={`${paths.paywallSuccess.path}`} element={<ProtectedRoute component={SubscriptionModal} />} />
          <Route path={`${paths.subscription.path}`} element={<ProtectedRoute component={SubscriptionModal} />} />
          <Route path={`${paths.share.path}`} element={<ProtectedRoute component={ShareModal} />} />
          <Route path={`${paths.paywallFail.path}`} element={<Navigate replace to={paths.paywall.path} />} />
          <Route
            path={`${paths.notifications.path}/:id?`}
            element={<ProtectedRoute component={NotificationsModal} />}
          />
          <Route path={`${paths.addFriend.path}`} element={<ProtectedRoute component={AddFriendModal} />} />
        </Routes>
      )}
    </Suspense>
  )
}

export default AppRouter

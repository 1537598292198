import {FC} from 'react'
import {Link, Text, useToast} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Button} from 'components'
import {signInWithPopup, setPersistence, browserLocalPersistence} from 'firebase/auth'
import {appleProvider, auth, facebookProvider, githubProvider, googleProvider} from 'services/firebase/firebase'
import {useAppStore, useAuthStore} from 'stores'
import {Link as DomLink, useLocation, useNavigate} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {api} from 'services/api'
import {generatePathFromLocation} from 'shared/utils/navigation.helper'

interface IProps {
  navToSignIn: () => void
}

const WelcomeScene: FC<IProps> = ({navToSignIn}) => {
  const toast = useToast()
  const location = useLocation()
  const navigate = useNavigate()

  const {t} = useTranslation()
  const setAfterAuthRedirect = useAuthStore((state) => state.setAfterAuthRedirect)
  const setIsDemo = useAppStore((state) => state.setIsDemo)

  const onGoogleAuth = async () => {
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await signInWithPopup(auth, googleProvider)
    } catch (error) {
      const _error = error as {code: string}
      if (_error.code === 'auth/account-exists-with-different-credential') {
        toast({
          title: t('authorization.welcome.authError.title'),
          description: t('authorization.welcome.authError.description'),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }
  const onFacebookAuth = async () => {
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await signInWithPopup(auth, facebookProvider)
    } catch (error) {
      const _error = error as {code: string}
      if (_error.code === 'auth/account-exists-with-different-credential') {
        toast({
          title: t('authorization.welcome.authError.title'),
          description: t('authorization.welcome.authError.description'),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }
  const onGithubAuth = async () => {
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await signInWithPopup(auth, githubProvider)
    } catch (error) {
      const _error = error as {code: string}
      if (_error.code === 'auth/account-exists-with-different-credential') {
        toast({
          title: t('authorization.welcome.authError.title'),
          description: t('authorization.welcome.authError.description'),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const onAppleAuth = async () => {
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await signInWithPopup(auth, appleProvider)
    } catch (error) {
      const _error = error as {code: string}
      if (_error.code === 'auth/account-exists-with-different-credential') {
        toast({
          title: t('authorization.welcome.authError.title'),
          description: t('authorization.welcome.authError.description'),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    }
  }

  const onDemoAuth = () => {
    setIsDemo(true)
    api.isDemo = true
    navigate(paths.dashboard.path)
  }

  return (
    <>
      <Text mb={'8px'} textStyle={'lg'} color={'white.1'}>
        {t('authorization.welcome.title')}
      </Text>
      <Text mb={'32px'} textStyle={'md'} color={'white.3'}>
        {t('authorization.welcome.subtitle')}
      </Text>
      <Button mb={'16px'} onClick={onGoogleAuth} icon={'google'} variant={'withIcon'}>
        {t('authorization.welcome.google')}
      </Button>
      {/*      <Button mb={'16px'} onClick={onFacebookAuth} icon={'facebook'} variant={'withIcon'}>
        {t('authorization.welcome.facebook')}
      </Button>*/}
      <Button mb={'16px'} onClick={onGithubAuth} icon={'github'} variant={'withIcon'}>
        {t('authorization.welcome.github')}
      </Button>
      <Button mb={'16px'} onClick={onAppleAuth} icon={'apple'} variant={'withIcon'}>
        {t('authorization.welcome.apple')}
      </Button>
      <Button onClick={navToSignIn} icon={'email'} variant={'withIcon'}>
        {t('authorization.welcome.email')}
      </Button>

      <Text marginTop={'32px'} textStyle={'sm'} color={'white.3'} textAlign={'center'}>
        By clicking the buttons above, you agree to our
        <br />
        <Link color={'accent.1'} as={DomLink} to={paths.terms.path} target={'_blank'}>
          Terms of Service
        </Link>{' '}
        and{' '}
        <Link color={'accent.1'} as={DomLink} to={paths.terms.path} target={'_blank'}>
          Privacy Policy
        </Link>
      </Text>
    </>
  )
}

export default WelcomeScene

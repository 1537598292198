import {FC} from 'react'
import {CircularProgressbarWithChildren} from 'react-circular-progressbar'
import {IReactComponentProps} from '@kaef/common/types/models/react-component-props.interface'
import {useToken, Text} from '@chakra-ui/react'
import {ANIMATION_CHART_DURATION, secondsToString} from '@kaef/common/utils/helpers'

interface IProps extends IReactComponentProps {
  goalTime: number
  spentTime?: number
}

const GRADIENT_ID = `gradient_progress_bar`

const GradientSVG = () => {
  const [startColor, endColor] = useToken('colors', ['accent.1', 'black.4'])
  const gradientTransform = `rotate(90)`
  return (
    <svg style={{height: 0, width: 0}}>
      <defs>
        <linearGradient id={GRADIENT_ID} gradientTransform={gradientTransform}>
          <stop offset="0%" stopColor={startColor} />
          <stop offset="100%" stopColor={endColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}

export const CircularProgress: FC<IProps> = ({goalTime, spentTime = 0}) => {
  const [pathColor, trailColor, finishColor] = useToken('colors', ['accent.1', 'black.4', 'state.positive'])

  const value = (spentTime / goalTime) * 100

  return (
    <CircularProgressbarWithChildren
      value={value}
      strokeWidth={11}
      styles={{
        root: {
          width: '220px'
        },
        path: {
          stroke: value >= 100 ? finishColor : pathColor,
          strokeLinecap: 'round',
          transition: `stroke-dashoffset ${ANIMATION_CHART_DURATION} ease 0s`,
          transformOrigin: 'center center'
        },
        trail: {
          stroke: trailColor,
          strokeLinecap: 'round',
          transformOrigin: 'center center'
        }
      }}>
      <Text textAlign={'center'} textStyle={'lg'} color={'white.1'}>
        {value.toFixed(0)}%
      </Text>
      <Text textAlign={'center'} textStyle={'md'} color={'white.3'}>
        {secondsToString(spentTime)}
      </Text>
    </CircularProgressbarWithChildren>
  )
}

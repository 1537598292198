import React, {FC, useState} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {Button, PluginIcon} from 'components'
import {useTranslation} from 'react-i18next'
import {JetBrains} from 'components/Modals/Instructions/JetBrains'
import {IPlugin} from '@kaef/common/types'
import {VsCode} from 'components/Modals/Instructions/VsCode'
import {PLUGINS} from '@kaef/common/ui/constants/plugins'

interface IProps {
  onNextStep: () => void
}
export const SetupPlugin: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [plugin, setPlugin] = useState<IPlugin | null>(null)

  const onNext = () => {
    onNextStep()
  }

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {plugin?.type === 'jetbrains' && <JetBrains />}
      {plugin?.type === 'vscode' && <VsCode />}
      {!plugin && (
        <>
          <Text mb={'20px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.setup.title')}
            Let's install the plugin to start getting the first statistics.
          </Text>
          <Flex alignItems={'center'} w={'100%'} maxW={'650px'} gap={'12px'} flexWrap={'wrap'}>
            {PLUGINS.filter(item => item.type !== 'wakatime-import').map((item) => {
              return (
                <Flex
                  flexDirection={'column'}
                  borderRadius={'8px'}
                  borderWidth={'1px'}
                  cursor={'pointer'}
                  borderColor={'black.5-40'}
                  backgroundColor={'white.1-05'}
                  display={'flex'}
                  p={'24px 0'}
                  key={item.name}
                  w={'calc(25% - 9px)'}
                  onClick={() => setPlugin(item)}
                  _hover={{
                    borderColor: 'accent.1',
                    backgroundColor: 'accent.1-05'
                  }}
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <PluginIcon type={item.icon} width={'40px'} height={'40px'} />
                  <Text textStyle={'md'} mt={'8px'}>
                    {item.name}
                  </Text>
                </Flex>
              )
            })}
          </Flex>
        </>
      )}
      <Flex w={'100%'} mt={'20px'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.setup.next')}
        </Button>
      </Flex>
    </Flex>
  )
}

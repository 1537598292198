import {FC} from 'react'
import {Flex} from '@chakra-ui/react'

interface IProps {
  isEmpty?: boolean
  trackColor: string
  longMark?: {
    color: string
    position: number
  }
  shortMark?: {
    color: string
    position: number
  }
  fillBar?: {
    color: string
    start: number
    end: number
  },
  secondFillBar?: {
    color: string
    start: number
    end: number
  }
}

export const ProgressLine: FC<IProps> = ({isEmpty, trackColor = 'white.3-10', fillBar, secondFillBar, shortMark, longMark}) => {
  return (
    <Flex flexShrink={0} flexGrow={0} position={'relative'} borderRadius={'1px'} h={'8px'} w={'100%'} bgColor={trackColor}>
      {isEmpty && <Flex position={'absolute'}
                        left={0}
                        right={0}
                        bgSize={'4px 4px'}
                        bgImage={'linear-gradient(135deg, transparent 25%, white.1-10 25%, white.1-10 50%, transparent 50%, transparent 75%, white.1-10 75%, white.1-10 100%)'}
                        h={'100%'}/>}
      {!isEmpty && (
        <>
          {fillBar && (
            <Flex
              position={'absolute'}
              left={`${fillBar.start}%`}
              right={`${100 - (fillBar.end > 100 ? 100 : fillBar.end)}%`}
              h={'100%'}
              bgColor={fillBar.color}
            />
          )}
          {secondFillBar && (
            <Flex
              position={'absolute'}
              left={`${secondFillBar.start}%`}
              right={`${100 - (secondFillBar.end > 100 ? 100 : secondFillBar.end)}%`}
              h={'100%'}
              bgColor={secondFillBar.color}
            />
          )}
          {shortMark && (
            <Flex
              position={'absolute'}
              left={`${shortMark.position}%`}
              top={0}
              w={'1px'}
              h={'8px'}
              bgColor={shortMark.color}
            />
          )}
          {longMark && (
            <Flex
              position={'absolute'}
              left={`${longMark.position > 100 ? 100 : longMark.position}%`}
              top={'-4px'}
              w={'1px'}
              h={'16px'}
              bgColor={longMark.color}
            />
          )}
        </>
      )}
    </Flex>
  )
}
import {getValue} from 'firebase/remote-config'
import {remoteConfig} from './firebase'

const devConfig = {
  subscriptions: true,
  goalRecommendation: false,
  freeDaysPeriod: 14
}

export const useRemoteConfig = () => {
  const isDev = import.meta.env.DEV
  const isSubscriptionsEnabled = getValue(remoteConfig, "subscriptions").asBoolean()
  const isGoalRecommendationEnabled = getValue(remoteConfig, "goalRecommendation").asBoolean() || false
  const freeDaysPeriod = getValue(remoteConfig, "freeDaysPeriod").asNumber()

  if (isDev) {
    return {
      isGoalRecommendationEnabled: devConfig.goalRecommendation,
      isSubscriptionsEnabled: devConfig.subscriptions,
      freeDaysPeriod: devConfig.freeDaysPeriod
    }
  }

  return {
    isGoalRecommendationEnabled,
    isSubscriptionsEnabled,
    freeDaysPeriod
  }
}
import React, {FC} from 'react'
import {GridItem, Heading, ListItem, Text, UnorderedList} from '@chakra-ui/react'

interface IProps {}

export const PrivacyScene: FC<IProps> = ({}) => {
  return (
    <GridItem
      area={'main'}
      display={'flex'}
      flexDirection={'column'}
      gridGap={'25px'}
      justifyContent={'start'}
      alignItems={'flex-start'}>
      <Heading as={'h2'} fontSize={['20px', '20px', '40px']} maxWidth={'640px'}>
        Privacy Policy
      </Heading>
      <Text textStyle={'lg'}>Information Collection</Text>
      <Text textStyle={'md'} color={'white.3'}>
        At Nau Time Tracker, we gather information as outlined below:
        <UnorderedList>
          <ListItem>
            Account and Profile Data: Upon registering for a Nau Time Tracker account, we collect details such as your
            email address, which remains confidential unless you choose to share it.
          </ListItem>
          <ListItem>
            Payment Information: For purchases of Nau Time Tracker's premium offerings, we collect your billing address
            and credit card details, which are directly transmitted to our secure payment processor and are not retained
            by us.
          </ListItem>
          <ListItem>
            Plugin Data: We accumulate data related to your use of our plugins, including file paths, timestamps,
            project and branch names, editor types and versions, coding languages, libraries, line counts, cursor
            positions, computer hostname, timezone, operating system details, and browser history. This information is
            collected to enhance our service and is not publicly disclosed without your permission. Such data is removed
            upon account deletion.
          </ListItem>
          <ListItem>
            Integrations: Should you enable integrations with other services, we connect to those services and collect
            only the necessary data to facilitate the integration. For instance, linking Google Calendar allows us to
            store names and details of selected calendars temporarily. No passwords or source code from these
            integrations are stored. Integration data is deleted when you remove the integration or delete your Nau Time
            Tracker account.
          </ListItem>
          <ListItem>
            Usage Data: We monitor how you interact with our services, including website visits and feature usage,
            through cookies and similar technologies. This may involve third-party services like Google Analytics for
            tracking purposes.{' '}
          </ListItem>
        </UnorderedList>
      </Text>
      <Text textStyle={'lg'}>Use of Collected Information</Text>
      <Text textStyle={'md'} color={'white.3'}>
        The information collected is utilized to maintain, safeguard, and enhance our services, ensuring a better user
        experience. We obtain your consent before using your data for any purposes not covered by this Privacy Policy.
        Communications from Nau Time Tracker, such as service updates or improvements, will be based on the information
        you provide, like your name and email address.{' '}
      </Text>
      <Text textStyle={'lg'}>Information Sharing</Text>
      <Text textStyle={'md'} color={'white.3'}>
        Your personal information is not sold to third parties. We share your information under these specific
        conditions, with a focus on protecting your privacy:
        <UnorderedList>
          <ListItem>
            With Consent: Your data may be shared if you give explicit permission, such as when you link third-party
            integrations to your account.
          </ListItem>
          <ListItem>
            Aggregated Data: We may distribute aggregated or anonymized data that cannot identify you, like usage
            statistics.
          </ListItem>
          <ListItem>
            Legal Requirements: Your information could be disclosed in response to legal processes like court orders or
            subpoenas.
          </ListItem>
        </UnorderedList>
      </Text>
      <Text textStyle={'lg'}>Information Security</Text>
      <Text textStyle={'md'} color={'white.3'}>
        Nau Time Tracker is committed to securing your data, employing SSL encryption for user communication and hashing
        algorithms for password storage.
      </Text>
      <Text textStyle={'lg'}>Policy Changes</Text>
      <Text textStyle={'md'} color={'white.3'}>
        Nau Time Tracker reserves the right to update this Privacy Policy as necessary. We recommend reviewing this
        policy periodically for changes. Significant amendments will be communicated to you or will include an option
        for "opt-in" when appropriate.
      </Text>
    </GridItem>
  )
}

export default PrivacyScene

import {IGoal} from './goals.interface'
import {ERange} from '../../enums'

export type TDayType = 'WORK' | 'DAY_OFF' | 'SICK' | 'HOLIDAY' | 'WEEKEND' | 'VACATION'

export enum EDayType {
  WORK = 'WORK',
  DAY_OFF = 'DAY_OFF',
  SICK = 'SICK',
  HOLIDAY = 'HOLIDAY',
  WEEKEND = 'WEEKEND',
  VACATION = 'VACATION'
}

export type TRange = {
  from: string
  to: string
}

export type TMappedRange = TRange & {
  id: number
  color?: string
}

export type TAverageRange = TRange & {
  value: number
}

export type TTotalValue = {
  id: number
  seconds: number
}

export interface IActions {
  editTitle: boolean
  editIcon: boolean
  editColor: boolean
  canBeHidden: boolean
  params: {
    [key: string]: string
  }
}

export interface ILinkParams extends Record<string, string> {}

export interface IBaseItem {
  name: string
  uuid: string
  color: string
  icon?: string
  mapId: number
  percent: number
  seconds: number
  linkParams?: ILinkParams
}

export interface IBaseOverview {
  type: string
  total: number
}

export interface IBadge extends IBaseItem {
  actions: IActions
}

export interface ISubItem extends IBaseItem {
  ranges?: TMappedRange[] // only for day
  actions: IActions
}

export interface ISliceData extends IBaseItem {}

export interface IItem extends IBaseItem {
  show: boolean
  isLocked?: boolean
  badges: IBadge[]
  subItems: ISubItem[]
  actions: IActions
  ranges?: TMappedRange[] // only for day
  parent?: IBaseItem // for file/branch
}

export interface ISlice {
  type: string
  title: string
  total?: number
  data: IBaseItem[]
  items: IItem[]
  settings: {
    showPie: boolean
    showRanges: boolean
    showBars: boolean
    showGoal: boolean
  }
}

export interface IRangeSlice extends ISlice {
  dayType: EDayType
  day: string
  start: string
  end: string
}

export interface IParent {
  title: string
  icon?: string
  params: {
    [key: string]: string
  }
}

export interface IOverviewDay {
  dayType: EDayType
  start: string
  end: string
  total: number
  parent: IParent
  isPrevLocked: boolean
  slices: IRangeSlice[]
}

export interface IDayGranularity {
  isLocked: boolean
  isCurMonth?: boolean
  day: string
  break: boolean
  total: number
  totalList: TTotalValue[]
}

export interface IOverviewDayInWeek extends IDayGranularity {
  ranges: TMappedRange[]
}

export interface IWeekSlice extends IRangeSlice {
  byDays: IOverviewDayInWeek[]
}

export interface IOverviewWeek {
  start: string
  end: string
  average: number
  total: number
  isPrevLocked: boolean
  averageList: TAverageRange[]
  slices: IWeekSlice[]
}

export interface IMonthSlice extends ISlice {
  byDays: IDayGranularity[]
}

export interface ITotalDayInMonth {
  day: string
  break: boolean
  value: number
  isCurMonth: boolean
  isLocked: boolean
  result: EGoalResult
}

export interface IOverviewMonth {
  total: number
  average: number
  isPrevLocked: boolean
  totalList: ITotalDayInMonth[]
  slices: IMonthSlice[]
}

export interface IMonthInYear {
  month: string
  break?: string
  total: number
  totalList: TTotalValue[]
}

export interface IYearSlice extends ISlice {
  byMonth: IMonthInYear[]
}

export interface IOverviewYear {
  isPrevLocked: boolean
  average: number
  total: number
  slices: IYearSlice[]
}

export enum EGoalResult {
  SUCCESS = 'SUCCESS',
  FAIL = 'FAIL',
  IN_PROGRESS = 'IN_PROGRESS',
  NO_GOAL = 'NO_GOAL',
  INDETERMINATE = 'INDETERMINATE',
  NOT_STARTED = 'NOT_STARTED',
  LOCKED = 'LOCKED'
}

export type TGoalResultMap = {
  [key in EGoalResult]: string
}

export interface IGoalHistory {
  day: string
  value: string | number | number[]
  dayType: TDayType | null
  color: string
  result: EGoalResult
  isCurMonth: boolean
}

export interface IOverviewGoal extends IGoal {
  history: IGoalHistory[]
  stats: IGoalStats
}

export interface IGoalStats {
  total: number //total days
  success: number //success days
  failed: number //failed days
  skipped: number //skipped days (with no goal)
  maxStreak: number //max streak without fails
  average: number //average value of goal for period (duration/start/end)
}

export type TDayChart = 'DAY_TOTAL' | 'DAY_DETAILED'
export type TWeekChart = 'WEEK_TOTAL' | 'WEEK_AVERAGE'
export type TMonthChart = 'MONTH_CALENDAR_TOTAL' | 'MONTH_CALENDAR_COMPARE' | 'MONTH_TOTAL'
export type TYearChart = 'YEAR_TOTAL' | 'YEAR_HEATMAP'

export type TChart = TDayChart | TWeekChart | TMonthChart | TYearChart

export type TOverviewRequest = {
  date: string
  range?: ERange
  filter?: TFilter
}

export type TFilter = {
  projectId: string
}

export type TChangeDayTypeRequest = {
  date: string
  type: TDayType
}

import React, {FC, memo} from 'react'
import AppCode from 'assets/plugins/AppCode_icon.svg'
import AquaCode from 'assets/plugins/Aqua_icon.svg'
import Clion from 'assets/plugins/CLion_icon.svg'
import DataGrip from 'assets/plugins/DataGrip_icon.svg'
import DataSpell from 'assets/plugins/DataSpell_icon.svg'
import GoLand from 'assets/plugins/GoLand_icon.svg'
import IntellijIdea from 'assets/plugins/IntelliJ_IDEA_icon.svg'
import MPS from 'assets/plugins/MPS_icon.svg'
import PhpStorm from 'assets/plugins/PhpStorm_icon.svg'
import PyCharm from 'assets/plugins/PyCharm_icon.svg'
import Rider from 'assets/plugins/Rider_icon.svg'
import RubyMine from 'assets/plugins/RubyMine_icon.svg'
import WebStorm from 'assets/plugins/WebStorm_icon.svg'
import AndroidStudio from 'assets/plugins/Android_studio.svg'
import VsCode from 'assets/plugins/vscode.svg'
import RustRover from 'assets/plugins/RustRover_icon.svg'
import JetBrains from 'assets/plugins/jb_beam.svg'
import Wakatime from 'assets/plugins/Wakatime_icon.svg'
import {TPluginIconType} from '@kaef/common/types'
import {Image, ImageProps} from '@chakra-ui/react'

interface IProps extends ImageProps {
  type: TPluginIconType
}

const RawPluginIcon: FC<IProps> = ({type, ...props}) => {
  const getIconSrc = () => {
    switch (type) {
      case 'jetbrains':
        return JetBrains
      case 'appcode':
        return AppCode
      case 'aqua':
        return AquaCode
      case 'clion':
        return Clion
      default:
      case 'intellijidea':
        return IntellijIdea
      case 'webstorm':
        return WebStorm
      case 'datagrip':
        return DataGrip
      case 'dataspell':
        return DataSpell
      case 'rubymine':
        return RubyMine
      case 'goland':
        return GoLand
      case 'pycharm':
        return PyCharm
      case 'phpstorm':
        return PhpStorm
      case 'mps':
        return MPS
      case 'rider':
        return Rider
      case 'androidstudio':
        return AndroidStudio
      case 'rustrover':
        return RustRover
      case 'vscode':
        return VsCode
      case 'wakatime':
        return Wakatime
    }
  }
  return <Image src={getIconSrc()} {...props} />
}

export const PluginIcon = memo(RawPluginIcon)

import React, {FC, useMemo} from 'react'
import {IFriend} from '@kaef/common/types'
import {Center, Flex, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Avatar, Icon, PressableBox} from '../UI'
import {paths} from '../../shared/constants/paths'
import {extractInitials, secondsToString} from '@kaef/common/utils/helpers'
import {Link as DomLink, useNavigate} from 'react-router-dom'

interface IProps {
  friends: IFriend[]
  me: IFriend | null
  incomingRequests?: IFriend[]
}

export const FriendsChart: FC<IProps> = ({friends, me, incomingRequests}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const data = useMemo(() => {
    const sortedFriends: IFriend[] = [...friends]
    if (me) {
      sortedFriends.push(me)
    }
    if (sortedFriends.length === 0) {
      return []
    }
    sortedFriends.sort((a, b) => b.stats!.totalSeconds - a.stats!.totalSeconds)
    const max = sortedFriends[0].stats!.totalSeconds
    return sortedFriends.map((friend) => ({
      percent: Math.round((friend.stats!.totalSeconds / max) * 100),
      data: friend
    }))
  }, [friends, me])

  const requestsAmount = incomingRequests?.length || 0

  const renderFriend = (item: {percent: number; data: IFriend}) => {
    if (item.data.id === me?.id) {
      return (
        <Flex
          key={item.data.id}
          h={'48px'}
          w={'100%'}
          pt={'3px'}
          pb={'3px'}
          borderTopWidth={'1px'}
          borderBottomWidth={'1px'}
          borderColor={'accent.2'}>
          <Flex
            position={'relative'}
            w={'100%'}
            h={'40px'}
            justifyContent={'space-between'}
            alignItems={'center'}
            pl={'12px'}
            pr={'12px'}>
            <Flex position={'absolute'} left={0} height={'100%'} w={`${item.percent}%`} bgColor={'accent.2-30'} />
            <Flex alignItems={'center'} gap={'10px'} zIndex={1}>
              <Avatar name={item.data.name} />
              <Text textStyle={'md'}>{item.data.name}</Text>
            </Flex>
            <Text textStyle={'md'} color={'accent.2'} zIndex={1}>
              {secondsToString(item.data.stats!.totalSeconds, {withWhitespace: true, withZero: true})}
            </Text>
          </Flex>
        </Flex>
      )
    }
    return (
      <PressableBox withoutPadding onClick={() => navigate(`${paths.friends.path}/${item.data.id}`)} key={item.data.id}>
        <Flex
          position={'relative'}
          w={'100%'}
          h={'40px'}
          justifyContent={'space-between'}
          alignItems={'center'}
          pl={'12px'}
          pr={'12px'}>
          <Flex position={'absolute'} left={0} height={'100%'} w={`${item.percent}%`} bgColor={'white.1-05'} />
          <Flex alignItems={'center'} gap={'10px'} zIndex={1}>
            <Center
              width={'32px'}
              height={'32px'}
              background={'black.4'}
              borderRadius={'50%'}
              fontSize={'15px'}
              flexShrink={0}
              fontWeight={'700'}>
              {item.data.name && extractInitials(item.data.name)}
              {!item.data.name && <Icon type={'profile'} />}
            </Center>
            <Text textStyle={'md'}>{item.data.name}</Text>
          </Flex>
          <Text textStyle={'md'} color={'white.3'} zIndex={1}>
            {secondsToString(item.data.stats!.totalSeconds, {withWhitespace: true, withZero: true})}
          </Text>
        </Flex>
      </PressableBox>
    )
  }

  return (
    <Flex direction={'column'} width={'100%'} gap={'8px'}>
      {requestsAmount > 0 && (
        <Flex
          to={paths.friends.path}
          as={DomLink}
          _hover={{
            background: 'linear-gradient(90.6deg, #2E3076 0%, #060D21 100%)',
            borderColor: 'accent.1-40'
          }}
          _active={{
            opacity: 0.8
          }}
          cursor={'pointer'}
          align={'center'}
          pl={'12px'}
          pr={'12px'}
          justifyContent={'space-between'}
          background={'linear-gradient(90.6deg, #2E307666 -6.52%, #060D2166 125.02%)'}
          borderRadius={'8px'}
          borderColor={'accent.1-40'}
          borderWidth={'1px'}
          w={'100%'}
          h={'40px'}>
          <Text textStyle={'md'}>{t('friends.newRequests', {count: requestsAmount})}</Text>
          <Icon color={'accent.1'} type={'rightArrow'} />
        </Flex>
      )}
      {data.map((item) => renderFriend(item))}
    </Flex>
  )
}

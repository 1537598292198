import {AxiosInstance} from 'axios'
import {IShareData, IShareDataRequest, IShareLink, IShareLinkRequest} from '../../types'

export const getShareData = async (
  data: IShareDataRequest | null,
  api: AxiosInstance
): Promise<IShareData> => {
  const response = await api.post(`/share-data/twitter`, data)
  return response.data
}


export const shareToTwitter = async (
  data: IShareLinkRequest | null,
  api: AxiosInstance
): Promise<IShareLink> => {
  const response = await api.post(`/user/share/twitter`, data)
  return response.data
}

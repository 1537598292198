import React, {FC} from 'react'
import {Box, Flex} from '@chakra-ui/react'
import {FriendsMain} from './components/FriendsMain/FriendsMain'
import {FriendsSidebar} from './components/FriendsSidebar/FriendsSidebar'
import {AnimatePresence, motion} from 'framer-motion'

interface IProps {}

const FriendsScene: FC<IProps> = ({}) => {
  return (
    <Flex w={'100%'} alignItems={'center'} flexDirection={'column'}>
      <Flex
        mt={'40px'}
        maxW={'1400px'}
        w={'100%'}
        alignItems={['center', 'center', 'flex-start']}
        flexDirection={['column', 'column', 'row']}
        pl={[0, '40px', '40px']}
        pr={[0, '40px', '40px']}>
        <Box flexGrow={0} w={'100%'} mr={[0, 0, '24px']} mb={['24px', '24px', 0]}>
          <FriendsMain />
        </Box>
        <Box flexGrow={0} w={'100%'} maxWidth={['415px', '383px', '383px']}>
          <AnimatePresence>
            <motion.div
              transition={{duration: 0.2, type: 'tween', ease: 'easeOut'}}
              initial={{opacity: 0, x: 200}}
              animate={{opacity: 1, x: 0}}
              exit={{opacity: 0, x: 200}}>
              <FriendsSidebar />
            </motion.div>
          </AnimatePresence>
        </Box>
      </Flex>
    </Flex>
  )
}

export default FriendsScene

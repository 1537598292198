import {ERange} from '@kaef/common/types'
import {Flex} from '@chakra-ui/react'
import {FC} from 'react'

interface IProps {
  range: ERange
}

export const DayTypeSwitcher: FC<IProps> = ({}) => {
  return <Flex />
}

import {Box, Button, Flex, Text} from '@chakra-ui/react'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  endOfWeek,
  format,
  isToday,
  isWithinInterval,
  subDays,
  subMonths,
  subWeeks,
  subYears
} from 'date-fns'
import {Icon, IconButton} from 'components'
import React, {FC, useEffect, useRef} from 'react'
import {useTranslation} from 'react-i18next'
import {ERange} from '@kaef/common/types'

interface IProps {
  range: ERange
  date: Date
  isPrevLocked: boolean
  onChange: (date: Date) => void
  onLocked: () => void
}

const isCurrentPeriod = (value: Date, range: ERange) => {
  switch (range) {
    case 'day':
      console.log('value', value, isToday(value))
      return isToday(value)
    case 'week':
      return isWithinInterval(new Date(), {
        start: value,
        end: addWeeks(value, 1)
      })
    case 'month':
      return isWithinInterval(new Date(), {
        start: value,
        end: addMonths(value, 1)
      })
    case 'year':
      return isWithinInterval(new Date(), {
        start: value,
        end: addYears(value, 1)
      })
  }
}

export const RangeSwitcher: FC<IProps> = ({range, date, onChange, isPrevLocked, onLocked}) => {
  const {t} = useTranslation()

  const onChangeRef = useRef<(date: Date) => void>(onChange)
  const valueRef = useRef<Date>(date)
  const rangeRef = useRef<ERange>(range)
  const isPrevLockedRef = useRef(isPrevLocked)
  const onToday = () => {
    onChange(new Date())
  }

  const onNext = () => {
    if (isCurrentPeriod(valueRef.current, rangeRef.current)) {
      return
    }
    switch (rangeRef.current) {
      case 'day':
        onChangeRef.current(addDays(valueRef.current, 1))
        break
      case 'week':
        onChangeRef.current(addWeeks(valueRef.current, 1))
        break
      case 'month':
        onChangeRef.current(addMonths(valueRef.current, 1))
        break
      case 'year':
        onChangeRef.current(addYears(valueRef.current, 1))
        break
    }
  }
  const onPrevious = () => {
    if (isPrevLockedRef.current) {
      onLocked()
      return
    }
    switch (rangeRef.current) {
      case 'day':
        onChangeRef.current(subDays(valueRef.current, 1))
        break
      case 'week':
        onChangeRef.current(subWeeks(valueRef.current, 1))
        break
      case 'month':
        onChangeRef.current(subMonths(valueRef.current, 1))
        break
      case 'year':
        onChangeRef.current(subYears(valueRef.current, 1))
        break
    }
  }

  const renderTitle = () => {
    switch (range) {
      case 'day':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(date, 'dd MMM, yyyy')}</Text>
            {isToday(date) && (
              <Text color={'white.3'} ml={'8px'} textStyle={'lg'}>
                {t('common.today')}
              </Text>
            )}
          </Flex>
        )
      case 'week':
        return (
          <Flex>
            <Text textStyle={'lg'}>
              {format(date, 'dd MMM')} - {format(endOfWeek(date, {weekStartsOn: 1}), 'dd MMM yyyy')}
            </Text>
          </Flex>
        )
      case 'month':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(date, 'MMMM')}</Text>
          </Flex>
        )
      case 'year':
        return (
          <Flex>
            <Text textStyle={'lg'}>{format(date, 'yyyy')}</Text>
          </Flex>
        )
      default:
        return null
    }
  }

  const renderShowTodayButton = () => {
    if (isCurrentPeriod(date, range)) {
      return null
    }
    return (
      <Button ml={'8px'} onClick={onToday} p={'2px'} color={'accent.1'} variant={'ghost'}>
        {t('common.goToToday')}
      </Button>
    )
  }

  const onArrowPress = (event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      onPrevious()
      return
    }
    if (event.key === 'ArrowRight') {
      onNext()
      return
    }
  }

  useEffect(() => {
    document.addEventListener('keyup', onArrowPress, false)
    return () => {
      document.removeEventListener('keyup', onArrowPress, false)
    }
  }, [])

  useEffect(() => {
    isPrevLockedRef.current = isPrevLocked
    rangeRef.current = range
    valueRef.current = date
    onChangeRef.current = onChange
  }, [range, date, onChange, isPrevLocked])

  return (
    <Flex justifyContent={'flex-start'} w={'100%'} alignItems={'center'}>
      <Flex mr={'12px'} position={'relative'}>
        <Box>
          <IconButton icon={'leftArrow'} variant={'ghost'} onClick={onPrevious} aria-label={'previous-range'} />
          {isPrevLocked && (
            <Box position={'absolute'} left={'25px'} bottom={'25px'}>
              <Icon color={'white.3'} type={'lock'} width={16} height={16} />
            </Box>
          )}
        </Box>
        <IconButton
          isDisabled={isCurrentPeriod(date, range)}
          icon={'rightArrow'}
          variant={'ghost'}
          onClick={onNext}
          aria-label={'next-range'}
        />
      </Flex>
      {renderTitle()}
      {renderShowTodayButton()}
    </Flex>
  )
}

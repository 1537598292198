import React, {FC, useState} from 'react'
import {Flex, Box, CircularProgress} from '@chakra-ui/react'
import {Button, DaysModal, Modal} from 'components'
import {useSearchParams} from 'react-router-dom'
import {DurationModal, StartModal, EndModal} from 'components'
import {api} from 'services/api'
import {goalSelector} from '@kaef/common/api/goals/goals.selector'
import {useTranslation} from 'react-i18next'
import {useQueryClient} from '@tanstack/react-query'
import {IGoal} from '@kaef/common/types'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {logSetGoal, logSetWeekPlan} from 'services/analytics/analytics'
import {userKeys} from '@kaef/common/api/user'
import {goalsKeys} from '@kaef/common/api/goals'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'

const EditGoalModal: FC = () => {
  const queryClient = useQueryClient()
  const {t} = useTranslation()
  const goBack = useGoBack()

  const [searchParams] = useSearchParams()
  const type = searchParams.get('type')
  const goalQuery = api.useGoalsQuery()
  const weekPlanQuery = api.useWeekPlanQuery()
  const saveGoalMutation = api.useSaveGoalMutation()
  const saveDaysMutation = api.useSaveWeekPlanMutation()

  const goalDuration = goalSelector('DURATION')(goalQuery.data)
  const goalStart = goalSelector('START')(goalQuery.data)
  const goalEnd = goalSelector('END')(goalQuery.data)

  const [start, setStart] = useState(goalStart?.value)
  const [end, setEnd] = useState(goalEnd?.value)
  const [duration, setDuration] = useState(goalDuration?.value)

  const [days, setDays] = useState(weekPlanQuery?.data || [])


  const {isLoading} = goalQuery

  const onSaveWeekPlan = async () => {
    logSetWeekPlan(days)
    saveDaysMutation.mutate(days, {
      onSuccess: () => {
        queryClient.invalidateQueries(goalsKeys.all())
        queryClient.invalidateQueries(overviewKeys.goals())
        queryClient.invalidateQueries(overviewKeys.monthAll())
        queryClient.invalidateQueries(overviewKeys.dayAll())
        queryClient.invalidateQueries(userKeys.weekPlan())
      },
      onSettled: () => {
        goBack(paths.editGoal.backgroundPath)
      }
    })
  }

  const onSave = async () => {
    if (type === 'DAYS') {
      onSaveWeekPlan()
      return;
    }
    let data: IGoal | null = null
    let secondData: IGoal | null = null
    switch (type) {
      case 'START':
        data = {
          type,
          value: start
        }
        break
      case 'END':
        data = {
          type,
          value: end
        }
        if (goalStart?.value > end) {
          secondData = {
            type: 'START',
            value: end
          }
        }
        break
      case 'DURATION':
        data = {
          type,
          value: duration
        }
        break
    }
    if (secondData) {
      logSetGoal(secondData.type, secondData.value)
      await saveGoalMutation.mutateAsync(secondData)
    }
    if (data) {
      logSetGoal(data.type, data.value)
      saveGoalMutation.mutate(data, {
        onSuccess: () => {
          queryClient.invalidateQueries(goalsKeys.all())
          queryClient.invalidateQueries(overviewKeys.goals())
          queryClient.invalidateQueries(overviewKeys.monthAll())
          queryClient.invalidateQueries(overviewKeys.dayAll())
        },
        onSettled: () => {
          goBack(paths.editGoal.backgroundPath)
        }
      })
    }
  }

  return (
    <Modal onClose={() => goBack(paths.editGoal.backgroundPath)}>
      <Flex
        h={'40px'}
        position={'relative'}
        alignItems={'center'}
        w={'100%'}
        top={'-14px'}
        left={'-14px'}
        justifyContent={'space-between'}>
        <Box />
      </Flex>
      {isLoading && <CircularProgress isIndeterminate color={'accent.1'} />}
      {!isLoading && type === 'START' && <StartModal startValue={start} setStart={setStart} />}
      {!isLoading && type === 'END' && (
        <EndModal setEnd={setEnd} setStart={setStart} startValue={start} endValue={end} />
      )}
      {!isLoading && type === 'DURATION' && <DurationModal duration={duration} setDuration={setDuration} />}
      {!weekPlanQuery.isLoading && type === 'DAYS' && <DaysModal days={days} setDays={setDays} />}

      <Flex gap={'20px'}>
        <Button mb={'16px'} onClick={() => goBack(paths.editGoal.backgroundPath)} variant={'secondary'} disabled={saveGoalMutation.isLoading}>
          {t('sidebar.todayGoals.editGoals.cancel')}
        </Button>
        <Button onClick={onSave} variant={'accent'} isLoading={saveGoalMutation.isLoading}>
          {t('sidebar.todayGoals.editGoals.save')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default EditGoalModal

import React, {FC, memo, useRef} from 'react'
import {Flex, useToken, Text} from '@chakra-ui/react'
import {IGoal, IMap, IOverviewDayInWeek, ISliceData, TAverageRange} from '@kaef/common/types'
import {mapSlice, secondsToString, generateRangeValuesWeek} from '@kaef/common/utils/helpers'
import {
  DangerZone,
  generateDayStartEnd,
  generateTicks,
  GoalMarks,
  Grid,
  HoverLine, Icon,
  Line,
  PressableBox,
  TDayLineRange,
  TimeLine,
} from 'components'
import {addDays, endOfToday, format, isAfter} from 'date-fns'

interface IProps {
  onDayClick: (date: Date, isLocked: boolean) => void
  goalStart?: IGoal
  goalEnd?: IGoal
  data?: IOverviewDayInWeek[]
  average?: TAverageRange[]
  sliceData?: ISliceData[]
  week: Date
  rangeStart: Date
  rangeEnd: Date
}

type TWrappedRanges = {
  total: number
  day: Date
  ranges: TDayLineRange[]
  isLocked: boolean
}

type TGenerateDays = (
  week: Date,
  data: IOverviewDayInWeek[],
  sliceMap: IMap<ISliceData>,
  start: Date,
  end: Date
) => TWrappedRanges[]

const generateDays: TGenerateDays = (week, data, sliceMap, start, end) => {
  const days = []
  for (let i = 0; i < 7; i++) {
    const day = addDays(week, i)
    const ranges = generateRangeValuesWeek(data[i]?.ranges, sliceMap, day, start, end)
    days.push({
      total: data[i]?.total || 0,
      day,
      ranges,
      isLocked: data[i]?.isLocked
    })
  }
  return days
}

const AverageWeekChartRaw: FC<IProps> = ({
  onDayClick,
  goalStart,
  goalEnd,
  data,
  week,
  rangeStart,
  rangeEnd,
  sliceData
}) => {
  const [totalLine] = useToken('colors', ['white.3-20'])

  const containerRef = useRef<HTMLDivElement>(null)

  if (!data || !sliceData) {
    return null
  }
  const mappedSliceData = mapSlice(sliceData)
  const days = generateDays(week, data, mappedSliceData, rangeStart, rangeEnd)

  const totalLinesRanges = days?.reduce((acc: TDayLineRange[], item) => [...acc, ...item.ranges], [])

  const {start, end, goalMarks, rangeDuration, offset, dangerZoneStart} = generateDayStartEnd(
    rangeStart,
    rangeEnd,
    goalStart,
    goalEnd
  )

  const ticks = generateTicks(start, end)

  return (
    <Flex position={'relative'} flexDirection={'column'} w={'100%'} mb={'34px'} ref={containerRef}>
      <Flex w={'100%'} ml={'60px'}>
        <TimeLine ticks={ticks} offset={'120px'} />
      </Flex>

      <Flex mb={'10px'} ml={'60px'} mr={'60px'}>
        {totalLinesRanges && <Line thinLine withTrail={false} singleColor={totalLine} ranges={totalLinesRanges} />}
      </Flex>

      {days.map((item, index) => (
        <PressableBox
          disabled={isAfter(item.day, endOfToday())}
          w={'100%'}
          key={index}
          onClick={() => onDayClick(item.day, item.isLocked)}>
          <Flex w={'100%'} alignItems={'center'} h={'37px'}>
            <Flex
              h={'100%'}
              borderStyle={'dotted'}
              borderColor={'white.3-20'}
              borderBottomWidth={'1px'}
              borderTopStyle={'solid'}
              borderTopWidth={index === 0 ? '1px' : 0}
              borderRightWidth={'1px'}
              w={'60px'}
              flexShrink={0}
              flexGrow={0}
              alignItems={'center'}>
              <Text textStyle={'sm'} color={'white.3'}>
                {format(item.day, 'EEE, dd')}
              </Text>
            </Flex>
            <Flex
              borderColor={'white.3-20'}
              borderStyle={'dotted'}
              borderBottomWidth={'1px'}
              borderTopStyle={'solid'}
              borderTopWidth={index === 0 ? '1px' : 0}
              h={'100%'}
              w={'100%'}
              alignItems={'center'}>
              {item.isLocked && <Flex bgSize={'4px 4px'}
                                    borderWidth={'1px'}
                                    borderRadius={'1px'}
                                    borderColor={'white.3-20'}
                                    bgImage={'linear-gradient(135deg, transparent 25%, white.1-20 25%, white.1-20 50%, transparent 50%, transparent 75%, white.1-20 75%, white.1-20 100%)'}
                                    flexShrink={0} w={'100%'} h={'12px'}/>}
              {!item.isLocked && <Line ranges={item?.ranges} />}
            </Flex>
            <Flex w={'65px'} flexShrink={0} flexGrow={0} alignItems={'center'} h={'100%'} pl={'12px'}>
              {item.isLocked && <Icon width={18} height={18} type={'lock'} color={'white.3'}/>}
              {!item.isLocked && <Text textStyle={'sm'} color={'white.3'}>
                {secondsToString(item.total)}
              </Text>}
            </Flex>
          </Flex>
        </PressableBox>
      ))}

      <Flex h={'100%'} position={'absolute'} w={'calc(100% - 120px)'} left={'60px'} top={0} pointerEvents={'none'}>
        <Grid ticks={ticks} />
        <GoalMarks goalMarks={goalMarks} />
        <DangerZone start={dangerZoneStart} />
        <HoverLine
          offset={offset}
          rangeDuration={rangeDuration}
          containerRef={containerRef}
          parentOffsetLeft={60}
          parentOffsetRight={60}
        />
      </Flex>
    </Flex>
  )
}

export const AverageWeekChart = memo(AverageWeekChartRaw)
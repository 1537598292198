import {FC} from 'react'
import {
  Box,
  Flex,
  RangeSlider,
  RangeSliderFilledTrack,
  RangeSliderMark,
  RangeSliderThumb,
  RangeSliderTrack,
  Text
} from '@chakra-ui/react'
import {ONBOARDING_CONFIG, RECOMMEND_CONFIG} from 'stores'
import {secondsToTime} from '@kaef/common/utils/helpers'
import {LABEL_STYLES, startFinishMarksConfig} from 'scenes/Onboarding/Onboarding.utils'
import {useTranslation} from 'react-i18next'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'

interface IProps {
  startValue: number
  endValue: number
  setStart: (value: number) => void
  setEnd: (value: number) => void
}

export const EndModal: FC<IProps> = ({startValue, endValue, setStart, setEnd}) => {
  const {t} = useTranslation()
  const {isGoalRecommendationEnabled} = useRemoteConfig()

  return (
    <>
      <Flex minHeight={'140px'}>
        <Flex flexDirection={'column'}>
          <Text mb={'6px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.finish.title')}
          </Text>
          {isGoalRecommendationEnabled && endValue > RECOMMEND_CONFIG.END ? (
            <>
              <Text textStyle={'md'} color={'state.neutral'}>
                {t('onboarding.finish.subtitleRecommend', {value: secondsToTime(RECOMMEND_CONFIG.END)})}
              </Text>
              <Text
                color={'white.3'}
                cursor={'pointer'}
                onClick={() => {
                  setEnd(RECOMMEND_CONFIG.END)
                  if (startValue >= RECOMMEND_CONFIG.END) {
                    setStart(RECOMMEND_CONFIG.START)
                  }
                }}>
                {t('onboarding.finish.setRecommend')}
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Box pt={'15px'} pb={'15px'}>
        <RangeSlider
          value={[startValue, endValue]}
          max={ONBOARDING_CONFIG.FULL_DAY}
          step={ONBOARDING_CONFIG.TIME_STEP}
          onChange={(val) => {
            setEnd(val[1])
          }}>
          {startFinishMarksConfig.map((item, idx) => (
            <RangeSliderMark key={`${item}${idx}`} value={item.value} {...LABEL_STYLES}>
              {item.label}
            </RangeSliderMark>
          ))}
          <RangeSliderMark
            value={endValue}
            textAlign="center"
            borderColor={'black.4'}
            bg={'black.2'}
            borderRadius={'8px'}
            borderWidth={'1px'}
            p={'10px'}
            color="white"
            m={'-85px 0 0 -40px'}>
            <Text>{secondsToTime(endValue)}</Text>
            <Text textStyle={'sm'} color={'white.3'} whiteSpace={'nowrap'}>
              {t('onboarding.finish.sliderLabel')}
            </Text>
          </RangeSliderMark>
          <RangeSliderTrack height={'8px'}>
            <RangeSliderFilledTrack bg={'accent.1-30'} />
          </RangeSliderTrack>
          <RangeSliderThumb width={'24px'} height={'24px'} bg={'accent.1-30'} index={0} />
          <RangeSliderThumb width={'24px'} height={'24px'} bg={'accent.1'} index={1} />
        </RangeSlider>
      </Box>
    </>
  )
}

import {FC} from 'react'
import {useToken} from '@chakra-ui/react'

type TColorScheme = 'neon' | 'orange' | 'yellow' | 'white' | 'black'
type TColorTheme = 'light' | 'dark'

interface ILogo {
  width?: number
  height?: number
  colorScheme?: TColorScheme
  colorTheme?: TColorTheme
  withText?: boolean
}

const ratio = 78 / 22

type IColorsMap = {
  [key in TColorScheme]: {
    [key in TColorTheme]: {
      eyeToken: string
      bodyToken: string
    }
  }
}

const COLORS_MAP: IColorsMap = {
  neon: {
    dark: {
      eyeToken: 'logo.white',
      bodyToken: 'logo.neon'
    },
    light: {
      eyeToken: 'logo.black',
      bodyToken: 'logo.neon'
    }
  },
  orange: {
    dark: {
      eyeToken: 'logo.white',
      bodyToken: 'logo.orange'
    },
    light: {
      eyeToken: 'logo.black',
      bodyToken: 'logo.orange'
    }
  },
  yellow: {
    dark: {
      eyeToken: 'logo.white',
      bodyToken: 'logo.yellow'
    },
    light: {
      eyeToken: 'logo.black',
      bodyToken: 'logo.yellow'
    }
  },
  white: {
    dark: {
      eyeToken: 'logo.white',
      bodyToken: 'logo.white'
    },
    light: {
      eyeToken: 'logo.black',
      bodyToken: 'logo.black'
    }
  },
  black: {
    dark: {
      eyeToken: 'logo.white',
      bodyToken: 'logo.white'
    },
    light: {
      eyeToken: 'logo.black',
      bodyToken: 'logo.black'
    }
  }
}

export const LogoIcon: FC<ILogo> = ({width, height, colorScheme = 'neon', colorTheme = 'dark', withText = true}) => {
  const _width = width ? width : height ? height * ratio : 78
  const _height = width ? width / ratio : height ? height : 22

  const {eyeToken, bodyToken} = COLORS_MAP[colorScheme][colorTheme]

  const [eyeColor, bodyColor] = useToken('colors', [eyeToken, bodyToken])
  return (
    <svg
      style={{flexShrink: 0}}
      width={_width}
      height={_height}
      viewBox="0 0 78 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        d="M35.6777 19.0353V5.63454C35.6777 4.36571 36.4245 3.66211 37.4887 3.66211C38.3501 3.66211 38.8704 4.02421 39.2555 4.79552L45.5292 17.425C45.7555 17.8784 45.8908 18.0609 46.2083 18.0609C46.4787 18.0609 46.6169 17.8577 46.6169 17.4485V3.88879H47.9986V17.3131C47.9986 18.582 47.1608 19.262 46.2553 19.262C45.4615 19.262 44.8295 18.9205 44.4444 18.1286L38.1501 5.38431C37.9679 4.99865 37.8326 4.81613 37.5622 4.81613C37.2212 4.81613 37.0653 5.06636 37.0653 5.54034V19.0324H35.6777V19.0353Z"
        fill={eyeColor}
      />
      <path
        d="M50.7383 13.5037C50.7383 10.3743 53.2078 7.78955 56.4886 7.78955C59.7254 7.78955 62.2625 10.2831 62.2625 13.5037V19.0354H60.9278V16.4741C60.0899 18.1286 58.3907 19.2621 56.3093 19.2621C53.1372 19.2621 50.7383 16.6773 50.7383 13.5037ZM60.8602 13.5243C60.8602 11.1221 58.9346 9.1261 56.4886 9.1261C54.0662 9.1261 52.1406 11.1221 52.1406 13.5243C52.1406 15.9266 54.0662 17.9226 56.4886 17.9226C58.9346 17.9255 60.8602 15.9295 60.8602 13.5243Z"
        fill={eyeColor}
      />
      <path
        d="M74.2192 13.5035V8.03662H75.6245V13.6595C75.6245 16.8331 73.6783 19.2589 70.4386 19.2589C67.2459 19.2589 65.3203 16.8095 65.3203 13.636V8.03662H66.7256V13.5241C66.7256 15.8586 68.0603 17.9223 70.4386 17.9223C72.8375 17.9253 74.2192 15.8616 74.2192 13.5035Z"
        fill={eyeColor}
      />
      <path
        d="M24.8886 3.07651C23.548 2.33464 21.9723 2.37879 20.6729 3.19426L19.8203 3.73006L14.8079 6.8889C14.5521 7.05081 14.4757 7.38937 14.6344 7.64549C14.7373 7.81035 14.9166 7.90161 15.0989 7.90161C15.1989 7.90161 15.2988 7.87511 15.39 7.81624L20.5994 4.5367L21.2549 4.12455C22.2104 3.52398 23.3716 3.4916 24.3594 4.03623C25.3472 4.5838 25.9381 5.58474 25.9381 6.71521V7.18035C26.3027 7.16563 26.6672 7.15974 27.0347 7.15974V6.71226C27.0317 5.18142 26.2321 3.82132 24.8886 3.07651Z"
        fill="url(#paint0_linear_2630_19426)"
      />
      <path
        d="M8.8255 15.2614V14.2369C8.8255 13.2743 8.04351 12.4941 7.08512 12.4941C6.12673 12.4941 5.34473 13.2772 5.34473 14.2369V15.2614C5.34473 16.2212 6.12673 17.0042 7.08512 17.0042C8.04351 17.0042 8.8255 16.2212 8.8255 15.2614ZM7.91121 15.2614C7.91121 15.7177 7.54079 16.0916 7.08218 16.0916C6.62356 16.0916 6.25314 15.7207 6.25314 15.2614V14.9111H6.28548C6.72351 14.9111 7.08218 14.5549 7.08218 14.1162V13.4097C7.53785 13.4097 7.91121 13.7806 7.91121 14.2399V15.2614Z"
        fill={eyeColor}
      />
      <path
        d="M4.99178 19.1151C6.33235 19.857 7.90811 19.8128 9.20752 18.9974L10.0601 18.4616L15.0755 15.3057C15.3312 15.1438 15.4077 14.8052 15.2489 14.5491C15.146 14.3842 14.9667 14.293 14.7844 14.293C14.6845 14.293 14.5845 14.3195 14.4934 14.3783L9.28396 17.6549L8.62837 18.0671C7.67292 18.6677 6.51168 18.7 5.52389 18.1554C4.5361 17.6078 3.9452 16.6069 3.9452 15.4764V15.0113C3.58065 15.026 3.21611 15.0319 2.84863 15.0319V15.4794C2.84863 17.0132 3.65121 18.3733 4.99178 19.1151Z"
        fill="url(#paint1_linear_2630_19426)"
      />
      <path
        d="M26.9995 6.18259C26.6408 6.25324 26.2821 6.32095 25.9205 6.39161C25.9323 6.49759 25.9382 6.60651 25.9382 6.71544V8.19918C25.9382 9.32965 25.3473 10.3306 24.3595 10.8782C23.3717 11.4257 22.2105 11.3933 21.255 10.7898L9.20752 3.19744C7.90811 2.37903 6.33235 2.33487 4.99178 3.07969C3.65121 3.82156 2.84863 5.18165 2.84863 6.71838V15.4766C2.84863 15.6709 2.86039 15.8622 2.88685 16.0477C3.25139 15.9918 3.61005 15.9388 3.97165 15.8858C3.95402 15.7504 3.9452 15.6149 3.9452 15.4766V13.9928C3.9452 12.8624 4.5361 11.8614 5.52389 11.3139C6.51168 10.7663 7.67292 10.7987 8.62837 11.4022L13.9789 14.77L20.6759 18.9946C21.3579 19.4244 22.1193 19.6393 22.8807 19.6393C23.5687 19.6393 24.2566 19.4656 24.8916 19.1123C26.2322 18.3705 27.0347 17.0104 27.0347 15.4736V6.71544C27.0318 6.53586 27.02 6.35922 26.9995 6.18259ZM25.9382 15.4736C25.9382 16.6041 25.3473 17.605 24.3595 18.1526C23.3717 18.7002 22.2105 18.6678 21.255 18.0643L9.20752 10.4748C7.90811 9.65643 6.33235 9.61227 4.99178 10.3571C4.59196 10.5779 4.24212 10.8546 3.9452 11.1755V10.3689C3.9452 9.23839 4.5361 8.23745 5.52389 7.68988C6.51168 7.14231 7.67292 7.17469 8.62837 7.7782L20.6759 15.3441C21.3579 15.7739 22.1193 15.9888 22.8807 15.9888C23.5687 15.9888 24.2566 15.8151 24.8916 15.4619C25.2914 15.2411 25.6413 14.9643 25.9382 14.6434V15.4736ZM25.9382 11.8232C25.9382 12.9536 25.3473 13.9546 24.3595 14.5021C23.3717 15.0497 22.2105 15.0173 21.255 14.4138L14.558 10.2158L9.20752 6.85086C7.90811 6.03245 6.33235 5.98829 4.99178 6.7331C4.59196 6.9539 4.24212 7.23063 3.9452 7.55152V6.72133C3.9452 5.59086 4.5361 4.58992 5.52389 4.04235C6.51168 3.49478 7.67292 3.52716 8.62837 4.13067L13.9789 7.49853L20.6729 11.7172C21.355 12.147 22.1164 12.3619 22.8778 12.3619C23.5657 12.3619 24.2537 12.1882 24.8887 11.8349C25.2885 11.6141 25.6383 11.3374 25.9352 11.0165V11.8232H25.9382Z"
        fill={bodyColor}
      />
      <defs>
        <linearGradient
          id="paint0_linear_2630_19426"
          x1="10.8873"
          y1="16.9754"
          x2="28.0628"
          y2="-0.0283965"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.4393" stopColor={bodyColor} stopOpacity="0" />
          <stop offset="0.5834" stopColor={bodyColor} />
        </linearGradient>
        <linearGradient
          id="paint1_linear_2630_19426"
          x1="18.9947"
          y1="5.21689"
          x2="1.81918"
          y2="22.2207"
          gradientUnits="userSpaceOnUse">
          <stop offset="0.4393" stopColor={bodyColor} stopOpacity="0" />
          <stop offset="0.5834" stopColor={bodyColor} />
        </linearGradient>
      </defs>
    </svg>
  )
}

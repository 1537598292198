import React, {FC} from 'react'
import {Flex, Text, Highlight, Image, Link} from '@chakra-ui/react'
import instructionImg from 'assets/instructions/jetbrains/instruction.png'
import linkImg from 'assets/instructions/jetbrains/link.png'
import doneImg from 'assets/instructions/jetbrains/done.png'
import trayImg from 'assets/instructions/jetbrains/tray.png'
import {useTranslation} from 'react-i18next'

const highlightStyle = {px: '2', py: '1', borderRadius: '6px', bg: 'accent.1-30', color: 'white.2'}
const highlightTextStyle = {color: 'white.1'}

export const JetBrains: FC = () => {
  const {t} = useTranslation()

  return (
    <Flex minHeight={'180px'}>
      <Flex flexDirection={'column'}>
        <Text mb={'20px'} textStyle={'lg'} color={'white.1'}>
          {t('jetBrains.title')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          {t('jetBrains.subtitle')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query={['Settings', 'Plugin']} styles={highlightStyle}>
            1. Open Settings and navigate to Plugin sections
          </Highlight>
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Nau" styles={highlightTextStyle}>
            2. Type Nau in search input
          </Highlight>
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Install" styles={highlightStyle}>
            3. Press Install
          </Highlight>
        </Text>
        <Image w={'100%'} src={instructionImg} />
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Link plugin" styles={highlightStyle}>
            4. Press Link plugin action in a popup, it will appear in a few moments after plugin's install
          </Highlight>
        </Text>
        <Flex w={'100%'} justify={'center'}>
          <Image maxW={['300px', '300px', '500px']} src={linkImg} />
        </Flex>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          Don't worry if you miss a popup, you can click on Nau plugin icon in a tray in right bottom corner (if you
          don't see it, please restart IDE)
        </Text>
        <Flex w={'100%'} justify={'center'}>
          <Image maxW={['300px', '300px', '400px']} src={trayImg} />
        </Flex>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          5. You will be redirected to our site and we automatically link your Nau plugin with your account
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          6. Done! When you return back to IDE you will see popup with congratulations
        </Text>
        <Flex w={'100%'} justify={'center'}>
          <Image maxW={['300px', '300px', '500px']} src={doneImg} />
        </Flex>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          If you have any problems, you can reach us via email{' '}
          <Link href={'mailto:support@nautime.io'}>
            <Text display={'inline'} color={'accent.1'}>
              support@nautime.io
            </Text>
          </Link>
        </Text>
      </Flex>
    </Flex>
  )
}

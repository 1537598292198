import {FC, useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import WelcomeScene from './scenes/Welcome/Welcome.scene'
import SignInScene from './scenes/SignIn/SignIn.scene'
import SignUpScene from './scenes/SignUp/SignUp.scene'
import ForgotPasswordScene from './scenes/ForgotPassword/ForgotPassword.scene'
import ResetPasswordScene from './scenes/ResetPassword/ResetPassword.scene'
import {Modal} from 'components'
import {paths} from 'shared/constants/paths'
import {useAuthStore} from 'stores'

const AuthorizationScene: FC = () => {
  const navigate = useNavigate()
  const location = useLocation()
  const {pathname, state} = location

  const isAuth = useAuthStore((state) => state.isAuth)

  useEffect(() => {
    if (isAuth) {
      navigate(paths.dashboard.path)
    }
  }, [isAuth])

  const onClose = () => {
    navigate(state?.backgroundLocation?.pathname || paths.authorization.backgroundPath)
  }

  const navToSignIn = () => {
    navigate(paths.signIn.path, {state})
  }
  const navToSignUp = () => {
    navigate(paths.signUp.path, {state})
  }
  const navToForgotPassword = () => {
    navigate(paths.forgotPassword.path, {state})
  }
  const navToWelcome = () => {
    navigate(paths.authorization.path, {state})
  }

  const navToOnboarding = () => {
    navigate(paths.onboarding.path, {state})
  }

  return (
    <Modal onClose={onClose}>
      {pathname === paths.authorization.path && <WelcomeScene navToSignIn={navToSignIn} />}
      {pathname === paths.signIn.path && (
        <SignInScene navToWelcome={navToWelcome} navToForgotPassword={navToForgotPassword} navToSignUp={navToSignUp} />
      )}
      {pathname === paths.signUp.path && (
        <SignUpScene navToOnboarding={navToOnboarding} navToWelcome={navToWelcome} navToSignIn={navToSignIn} />
      )}
      {pathname === paths.forgotPassword.path && <ForgotPasswordScene navToSignIn={navToSignIn} />}
      {pathname === paths.resetPassword.path && <ResetPasswordScene navToSignIn={navToSignIn} />}
    </Modal>
  )
}

export default AuthorizationScene

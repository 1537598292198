import {FC, useEffect, useMemo, useState} from 'react'
import {Button, IconButton, Modal} from '../../UI'
import {useNavigate, useParams} from 'react-router-dom'
import {api} from 'services/api'
import {Center, CircularProgress, Flex, Text, Image, Box} from '@chakra-ui/react'
import {INotification, INotificationButton} from '@kaef/common/types/models/domain/notifications.interface'
import {useQueryClient} from '@tanstack/react-query'
import {notificationsKeys} from '@kaef/common/api/notifications'
import {useTranslation} from 'react-i18next'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'

interface IProps {

}

export const NotificationsModal: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const goBack = useGoBack()

  const queryClient = useQueryClient()
  const {id} = useParams()

  const [index, setIndex] = useState(0)

  const notificationsQuery = api.useNotificationsQuery()
  const markNotificationAsReadMutation = api.useMarkNotificationAsReadMutation()

  const notificationsList: INotification[] = useMemo(() => {
    if (!id) {
      return notificationsQuery.data?.filter(item => !item.isRead && item.showAlert) || []
    }
    const findNotification = notificationsQuery.data?.find(notification => notification.id === id)
    if (findNotification) {
      return [findNotification]
    }
    return []
  }, [notificationsQuery.data, id])

  const onClose = () => {
    queryClient.invalidateQueries(notificationsKeys.all())
    goBack(paths.notifications.backgroundPath)
  }

  const isPaging = notificationsList.length > 1

  const onClick = (button: INotificationButton) => {
    if (button.link == 'close') {
      onClose()
      return
    }
    if (button.isNavigationLink) {
      navigate(button.link)
      return
    }
    window.open(button.link)
  }

  const renderButtons = (notification: INotification) => {
    if (!notification.params?.buttons) {
      return <Button w={'260px'} variant={'accent'} onClick={onClose}>{t('notifications.close')}</Button>
    }
    return notification.params?.buttons.map((button, index) => (
      <Button key={index} w={'auto'} minW={'260px'} variant={button.type} onClick={() => {
        onClick(button)
      }}>{button.text}</Button>
    ))
  }

  useEffect(() => {
    if (notificationsList.length > 0 && !notificationsList[index].isRead) {
      markNotificationAsReadMutation.mutate([notificationsList[index].id])
    }
  }, [index])

  useEffect(() => {
    if (!notificationsQuery.isLoading && notificationsList.length === 0) {
      onClose()
    }
  }, [notificationsList, notificationsQuery.isLoading])

  return (
    <Modal size={'xl'} onClose={onClose}>
      <Box pointerEvents={'none'} top={0} left={0} right={0} bottom={0} position={'absolute'}
           background={'radial-gradient(50% 50% at 50% 50%, #2E76721f 0%, #060D211f 130%)'} />
      {notificationsQuery.isLoading && <Center><CircularProgress isIndeterminate color={'accent.1'} /></Center>}

      {!notificationsQuery.isLoading && notificationsList.length !== 0 &&
        <Flex flexDirection={'column'} mt={'24px'} position={'relative'}>
          {isPaging &&
            <Flex justify={'flex-start'} align={'center'} position={'absolute'} top={'-38px'} left={'-18px'}>
              <IconButton aria-label={'prev'} variant={'ghost'} icon={'leftArrow'}
                          onClick={() => setIndex(index === 0 ? notificationsList.length - 1 : index - 1)} />
              <Text ml={'4px'} mr={'4px'} textStyle={'lg'}
                    color={'white.3'}>{index + 1}/{notificationsList.length}</Text>
              <IconButton aria-label={'next'} variant={'ghost'} icon={'rightArrow'}
                          onClick={() => setIndex(index === notificationsList.length - 1 ? 0 : index + 1)} />
            </Flex>}
          {notificationsList[index].imageUrl &&
            <Flex justify={'center'} w={'100%'}><Image src={notificationsList[index].imageUrl} mb={'24px'}
                                                       maxH={'240px'} maxW={'calc(100%-24px)'} /></Flex>}
          <Text mb={'10px'} textStyle={'xl'} textAlign={'center'}>{notificationsList[index].title}</Text>
          <Text textStyle={'lg'} color={'white.3'} textAlign={'center'}>{notificationsList[index].description}</Text>
          <Flex flexDirection={'column'} mt={'56px'} gap={'16px'} alignItems={'center'}>
            {renderButtons(notificationsList[index])}
          </Flex>
        </Flex>
      }
    </Modal>
  )
}

export default NotificationsModal

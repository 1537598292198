import {IItem, ILinkParams} from '@kaef/common/types'
import React, {FC, useMemo, useState} from 'react'
import {Flex, Skeleton} from '@chakra-ui/react'
import {SliceDataRow} from './SliceDataRow'
import {HiddenDivider} from 'components'

interface IProps {
  onClick: (linkParams: ILinkParams, isLocked?: boolean) => void
  data?: IItem[]
  isLoading: boolean
  withEmoji: boolean
  total?: number
  withToolkit?: boolean
  onEditProject?: (data: IItem) => void
  onToggleVisibility?: (data: IItem) => void
  disabled?: boolean
}

export const SliceDataList: FC<IProps> = ({
  disabled,
  onEditProject,
  onToggleVisibility,
  withToolkit,
  data,
  onClick,
  isLoading,
  total,
  withEmoji
}) => {
  const [isShowHidden, setIsShowHidden] = useState(false)

  const visibleData = useMemo(() => {
    return data?.filter((item) => item.show === true || item.show === undefined)
  }, [data])

  const hiddenData = useMemo(() => {
    return data?.filter((item) => item.show === false)
  }, [data])

  return (
    <Flex flexDirection={'column'} mt={'10px'}>
      {isLoading && (
        <>
          <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} mb={'10px'} />
          <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} mb={'10px'} />
        </>
      )}
      {!isLoading &&
        visibleData?.map((item, index) => (
          <SliceDataRow
            disabled={disabled || !item.linkParams}
            withToolkit={withToolkit}
            onToggleVisibility={onToggleVisibility}
            onEditProject={onEditProject}
            withEmoji={withEmoji}
            total={total}
            key={index}
            sliceData={item}
            onClick={() => item.linkParams && onClick(item.linkParams, item.isLocked)}
          />
        ))}
      {!isLoading && (hiddenData?.length || 0) > 0 && (
        <>
          <HiddenDivider onToggle={setIsShowHidden} isShow={isShowHidden} length={hiddenData?.length} />
          {isShowHidden &&
            hiddenData?.map((item, index) => (
              <SliceDataRow
                withOpacity
                disabled={disabled || !item.linkParams}
                withToolkit={withToolkit}
                onToggleVisibility={onToggleVisibility}
                onEditProject={onEditProject}
                withEmoji={withEmoji}
                total={total}
                key={index}
                sliceData={item}
                onClick={() => item.linkParams && onClick(item.linkParams, item.isLocked)}
              />
            ))}
        </>
      )}
    </Flex>
  )
}

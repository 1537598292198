import React, {FC, useEffect, useRef, useState} from 'react'
import {useLocation, useNavigate} from 'react-router-dom'
import {Button, Icon, Modal} from 'components'
import {useTranslation} from 'react-i18next'
import {
  Center,
  CircularProgress,
  Flex,
  Heading,
  List,
  ListItem,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  useToast
} from '@chakra-ui/react'
import {subscriptions} from '@kaef/common/ui/constants/subscriptions'
import {api} from 'services/api'
import {paths} from 'shared/constants/paths'
import {useQueryClient} from '@tanstack/react-query'
import {userKeys} from '@kaef/common/api/user/user.keys'
import {useGoBack} from 'shared/utils/navigation.helper'
import {logCancelSubscription, logOpenPaywall} from 'services/analytics/analytics'

const SubscriptionModal: FC = () => {
  const queryClient = useQueryClient()
  const toast = useToast()
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const goBack = useGoBack()
  const userQuery = api.useUserInfoQuery()
  const cancelMutation = api.useCancelSubscriptionMutation()
  const refreshMutation = api.useRefreshSubscriptionMutation()

  const [isOpen, setIsOpen] = useState(false)

  const isRefreshedRef = useRef(false)

  const {data} = userQuery
  const onClose = () => {
    goBack(paths.subscription.backgroundPath)
  }

  const onCancel = async () => {
    if (!data?.subscription.active?.id) {
      return
    }
    try {
      logCancelSubscription(data?.subscription?.active?.productId)
      await cancelMutation.mutateAsync(data.subscription.active.id)
      await queryClient.invalidateQueries(userKeys.info())
      if (location.state?.backgroundLocation) {
        navigate(`${paths.paywall.path}?fromCancel=true`, {
          state: {backgroundLocation: location.state?.backgroundLocation}
        })
      } else {
        navigate(`${paths.paywall.path}?fromCancel=true`)
      }
    } catch (e) {
      toast({
        title: t('subscription.unknownErrorTitle'),
        description: t('subscription.unknownError'),
        status: 'error',
        duration: 10000,
        isClosable: true
      })
    }
  }

  const subscription = subscriptions.find((item) => item.id === data?.subscription?.active?.productId)

  const isLoading = refreshMutation.isLoading || userQuery.isLoading

  const onUpgrade = () => {
    logOpenPaywall('upgradeTrial')
    navigate(paths.paywall.path)
  }

  useEffect(() => {
    const checkSubscription = async () => {
      if (!data?.subscription?.pro && !data?.subscription.trial && !isRefreshedRef.current) {
        await refreshMutation.mutateAsync()
        isRefreshedRef.current = true
        await queryClient.invalidateQueries(userKeys.info())
        return
      }
      if (!data?.subscription?.pro && !data?.subscription.trial && isRefreshedRef.current) {
        navigate(paths.dashboard.path)
      }
    }
    checkSubscription()
  }, [userQuery.dataUpdatedAt])


  return (
    <Modal size={'2xl'} onClose={onClose}>
      {isLoading && (
        <Center>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      )}
      {!isLoading && (
        <Flex flexDirection={['column', 'column', 'row']}>
          {subscription && (
            <Flex
              mt={'10px'}
              width={'305px'}
              flexDirection={'column'}
              padding={'24px'}
              position={'relative'}
              bg={'white.1-05'}
              borderRadius={'8px'}>
              <Flex position={'absolute'}
                    top={'-10px'}
                    left={'12px'}>
                <Text
                  borderRadius={'8px'}
                  padding={'0 12px'}
                  bg={'black.5'}>
                  {t('subscription.currentPlan')}
                </Text>
                {data?.subscription?.trial &&
                  <Text
                    ml={'12px'}
                    borderRadius={'8px'}
                    padding={'0 12px'}
                    bg={'black.5'}>
                    {t('subscription.daysLeft', {count: data.subscription.daysUntilEnd || 0}) as unknown as string}
                  </Text>}
              </Flex>

              <Flex borderBottomWidth={'1px'} borderBottomColor={'white.1-10'} flexDirection={'column'}>
                <Heading color={'white.1'} as={'h3'} fontWeight={'400'} margin={'0 0 10px'} fontSize={'20px'}>
                  {t(subscription.titleKey)}
                </Heading>
              </Flex>
              <Flex margin={'0 0 8px'} gridGap={'0 8px'} alignItems={'baseline'}>
                <Text fontSize={'40px'} fontWeight={'500'}>
                  {subscription.price}
                </Text>
                {subscription.periodKey ? (
                  <Text textStyle={'md'} color={'white.3'}>
                    {t(subscription.periodKey)}
                  </Text>
                ) : null}
              </Flex>
              {subscription.id === 'trial' &&
                <Button margin={'0 0 20px'} onClick={onUpgrade} variant={'accent'}>{t('subscription.upgrade').toUpperCase()}</Button>
              }
              {subscription.id !== 'trial' &&
              <Flex position={'relative'}>
                <Popover placement={'bottom'} isOpen={isOpen} onClose={() => setIsOpen(false)}>
                  <PopoverTrigger>
                    <Button
                      position={'relative'}
                      onClick={() => setIsOpen(true)}
                      variant={'negative'}
                      backgroundColor={'state.positive-10'}
                      margin={'0 0 20px'}
                      border={'none'}>
                      <Center>
                        <Text mr={'6px'} textStyle={'md'} color={'state.positive'}>
                          {t('subscription.subscribed').toUpperCase()}
                        </Text>
                        <Icon type={'arrowDown'} color={'state.positive'} />
                      </Center>
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent width={'237px'} bgColor={'black.3'}>
                    <Button isLoading={cancelMutation.isLoading} onClick={() => onCancel()} variant={'negative'}>
                      {t('subscription.cancel').toUpperCase()}
                    </Button>
                  </PopoverContent>
                </Popover>
              </Flex>}
              <List spacing={'8px'}>
                {subscription.featuresKeys.map((key, idx) => (
                  <ListItem display={'flex'} alignItems={'center'} color={'white.3'} key={idx}>
                    <Icon height={14} width={16} type={'check'} color={'white.3'} />
                    <Text ml={'8px'} textStyle={'md'}>
                      {t(key)}
                    </Text>
                  </ListItem>
                ))}
              </List>
            </Flex>
          )}

          <Flex flexDirection={'column'} ml={'32px'} pt={'30px'}>
            <Icon type={'flash'} color={'accent.1'} width={40} height={40} />
            <Text mt={'8px'} textStyle={'xl'}>
              {t('subscription.title')}
            </Text>
            <Text mt={'10px'} textStyle={'md'} color={'white.3'}>
              {t('subscription.subtitle')}
            </Text>
          </Flex>
        </Flex>
      )}
    </Modal>
  )
}

export default SubscriptionModal

import { IUser } from "../../types";

export const user: IUser = {
  id: "f90e7ad1-3fd8-4c76-9b13-c10cd1debf9b",
  partId: "",
  picture: "",
  name: "Ilya Travnikov",
  email: "ilia.travnikov@gmail.com",
  locale: "en-US",
  timezone: "Europe/Moscow",
  nextDayTime: 0,
  onboarding: {
    isOnboarded: true,
    onboardStep: "NAME",
  },
  subscription: {
    active: null,
    daysUntilEnd: null,
    pro: true,
    trial: false,
    abilities: []
  },
  plugins: [],
  registerDate: '',
  features: [],
  isNew: false,
};

import React from 'react'
import {LogoIcon} from 'components/index'
import {Flex, Link, Text} from '@chakra-ui/react'
import {Link as DomLink} from 'react-router-dom'

interface ILogo {
  path?: string
}

export const Logo = ({path}: ILogo) => {
  return (
    <Link
      flexShrink={0}
      as={DomLink}
      _hover={{textDecoration: 'none'}}
      to={path}
      display={'flex'}
      alignItems={'center'}
      justifyContent={'flex-start'}
      textTransform={'none'}>
      <LogoIcon height={22} />
    </Link>
  )
}

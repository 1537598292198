import {Flex, IconButton, Menu, MenuButton, MenuItem, MenuList, Text} from '@chakra-ui/react'
import {Icon} from 'components'
import UserMenu from '../UserMenu/UserMenu'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'
import {paths} from 'shared/constants/paths'
import {useNavigate} from 'react-router-dom'
import {UpgradeButton} from '../UpgradeButton/UpgradeButton'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'

interface IProps {}

export const MobileMenu: FC<IProps> = () => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const {isSubscriptionsEnabled} = useRemoteConfig()

  return (
    <Menu>
      <MenuButton
        border={'none'}
        as={IconButton}
        marginLeft={'auto'}
        aria-label="Options"
        icon={<Icon type={'hamburger'} />}
        variant="outline"
      />
      <MenuList bg={'black.1'} width={'90vw'} padding={'0'}>
        {isSubscriptionsEnabled && <UpgradeButton />}
        <MenuItem padding={'0 12px'} bg={'black.1'}>
          <Text
            padding={'10px 0'}
            width="100%"
            borderBottom={'1px solid'}
            borderColor={'black.4'}
            onClick={() => navigate(paths.overview.path)}>
            {t('menu.overview')}
          </Text>
        </MenuItem>
        <MenuItem padding={'0 12px'} bg={'black.1'}>
          <Text
            padding={'10px 0'}
            width="100%"
            borderBottom={'1px solid'}
            borderColor={'black.4'}
            onClick={() => navigate(paths.projects.path)}>
            {t('menu.projects')}
          </Text>
        </MenuItem>
        <MenuItem padding={'0 12px'} bg={'black.1'}>
          <Text
            padding={'10px 0'}
            width="100%"
            borderBottom={'1px solid'}
            borderColor={'black.4'}
            onClick={() => navigate(paths.plugins.path)}>
            {t('menu.plugins')}
          </Text>
        </MenuItem>
        <MenuItem padding={'0 12px'} bg={'black.1'}>
          <Text
            padding={'10px 0'}
            width="100%"
            borderBottom={'1px solid'}
            borderColor={'black.4'}
            onClick={() => navigate(paths.trends.path)}>
            {t('menu.trends')}
          </Text>
        </MenuItem>
        <MenuItem padding={'0 12px'} bg={'black.1'}>
          <Text
            padding={'10px 0'}
            width="100%"
            borderBottom={'1px solid'}
            borderColor={'black.4'}
            onClick={() => navigate(paths.friends.path)}>
            {t('menu.friends')}
          </Text>
        </MenuItem>
        <Flex padding={'10px 12px'}>
          <UserMenu />
        </Flex>
      </MenuList>
    </Menu>
  )
}

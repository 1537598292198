import { AxiosInstance } from "axios";
import {useMutation, useQuery} from '@tanstack/react-query'
import {subscriptionKeys} from './subscription.keys';
import {getSubscriptions, cancelSubscription, refreshSubscriptionStatus, checkoutSubscription} from './subscription.api'

export const useSubscriptionsListQuery = (
  options: any,
  api: AxiosInstance,
) => {
  return useQuery(subscriptionKeys.all(), () => getSubscriptions(api), options);
};


export const useCancelSubscriptionMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any, string>((subscriptionId) => cancelSubscription(subscriptionId, api), options)
};

export const useRefreshSubscriptionMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any>(() => refreshSubscriptionStatus(api), options)
};

export const useCheckoutSubscriptionMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<string, any, string>((productKey: string) => checkoutSubscription(productKey, api), options)
};
import React, {FC} from 'react'
import {Flex, Link, Text} from '@chakra-ui/react'
import {paths} from 'shared/constants/paths'
import {Link as DomLink, useLocation} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {api} from 'services/api'

interface IProps {}

export const DesktopMenu: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const friendQuery = api.useFriendsRequestsQuery()
  const incomingRequests = friendQuery?.data || []

  return (
    <>
      <MenuItem
        path={paths.overview.path}
        label={t('menu.overview')}
        isActive={location.pathname.includes(paths.overview.path)}
      />

      <MenuItem
        path={paths.projects.path}
        label={t('menu.projects')}
        isActive={location.pathname.includes(paths.projects.path)}
      />

      <MenuItem
        path={paths.plugins.path}
        label={t('menu.plugins')}
        isActive={location.pathname.includes(paths.plugins.path)}
      />

      <MenuItem
        path={paths.trends.path}
        label={t('menu.trends')}
        isActive={location.pathname.includes(paths.trends.path)}
      />

      <MenuItem
        path={paths.friends.path}
        label={t('menu.friends')}
        isNotify={incomingRequests.length > 0}
        notifyCount={incomingRequests.length}
        isActive={location.pathname.includes(paths.friends.path)}
      />
    </>
  )
}

interface IMenuItem {
  path: string
  label: string
  isActive: boolean
  isNotify?: boolean
  notifyCount?: number
}

const MenuItem: FC<IMenuItem> = ({path, label, isActive, isNotify, notifyCount}) => {
  return (
    <Link
      _hover={{
        color: 'white.1'
      }}
      _after={
        isActive
          ? {
              content: '""',
              position: 'absolute',
              height: '1px',
              display: 'block',
              width: '100%',
              bottom: '-17px',
              backgroundColor: 'white.1'
            }
          : {}
      }
      position={'relative'}
      color={isActive ? 'white.1' : 'white.2'}
      display={'flex'}
      gap={'6px'}
      alignItems="center"
      mr={'32px'}
      as={DomLink}
      to={isActive ? '#' : path}>
      <Text textStyle={'md'}>{label}</Text>
      {isNotify && (
        <Flex
          alignItems={'center'}
          justifyContent={'center'}
          pl={'6px'}
          pr={'6px'}
          pt={'2px'}
          pb={'2px'}
          borderRadius={'4px'}
          bgColor={'accent.1'}>
          {notifyCount && (
            <Text color={'white.1'} textStyle={'sm'}>
              {notifyCount}
            </Text>
          )}
        </Flex>
      )}
    </Link>
  )
}

import {FC, memo} from 'react'
import {Flex, useBreakpointValue} from '@chakra-ui/react'
import {IDayGranularity, IItem} from '@kaef/common/types'
import {findMinMax, generateMonthAxisData, generateBarData} from '@kaef/common/utils/helpers'
import {BarChart} from 'components/Charts/Echarts/BarChart'
import {addDays, getDaysInMonth} from 'date-fns'

interface IProps {
  onDayClick: (value: Date, isLocked?: boolean) => void
  data?: IDayGranularity[]
  sliceData?: IItem[]
  month: Date
}

const MonthBarChartRaw: FC<IProps> = ({onDayClick, data, sliceData, month}) => {
  const barWidth = useBreakpointValue(
    {
      xs: 8,
      sm: 8,
      md: 8,
      lg: 8,
      xl: 14,
      '2xl': 14
    },
    {
      fallback: 'md'
    }
  )
  if (!data || !sliceData) {
    return null
  }

  const handleDayClick = (index: number, dataId: string) => {
    const findSlice = sliceData.find((slice) => slice.uuid === dataId)
    onDayClick(addDays(month, index), findSlice?.isLocked)
  }

  const {min, max} = findMinMax(data)
  return (
    <Flex flexDirection={'column'} w={'100%'} mb={'34px'}>
      <BarChart
        onClick={handleDayClick}
        date={month}
        min={min}
        max={max}
        height={244}
        granularity={'hour'}
        axisData={generateMonthAxisData(month)}
        totalData={data.map((day) => day.total)}
        data={generateBarData(data, sliceData, getDaysInMonth(month), true)}
        chartOptions={{
          showLabel: false,
          xAxis: {},
          yAxis: {
            show: true
          },
          seriesOptions: {
            barWidth
          }
        }}
      />
    </Flex>
  )
}

export const MonthBarChart = memo(MonthBarChartRaw)

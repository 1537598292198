import {AxiosInstance} from 'axios'
import {
  useLinkPluginMutation,
  useSaveWeekPlanMutation,
  useSendWakatimeUrlMutation,
  useUpdateUserMutation,
  useUserInfoQuery,
  useUserQuery,
  useWeekPlanQuery
} from './user'
import {useGoalsQuery, useSaveGoalMutation} from './goals/goals.query'

import {QueryClient, QueryOptions, UseQueryOptions} from '@tanstack/react-query'
// @ts-ignore
import {QueryObserverOptions} from '@tanstack/query-core/src/types'
import {
  useOverviewDayQuery,
  useOverviewMonthQuery,
  useOverviewWeekQuery,
  useOverviewYearQuery,
  useOverviewGoalsQuery,
  useChangeDayTypeMutation
} from './overview'
import {IFriendOverviewRequest, IShareDataRequest, ITestUser, TOverviewRequest, TWsMessage} from '../types'
import {useCreateTestUserMutation, useTestUsersQuery} from './test'
import {useOnboardingMutation} from './onboarding'
import {useProjectQuery, useProjectsQuery, useSaveProjectMutation} from './projects/projects.query'
import {
  useCancelSubscriptionMutation,
  useRefreshSubscriptionMutation,
  useCheckoutSubscriptionMutation,
  useSubscriptionsListQuery
} from './subscription/subscription.query'
import {
  useMarkAllNotificationAsReadMutation,
  useMarkNotificationAsReadMutation,
  useNotificationsQuery
} from './notifications/notifications.query'
import {useWsLastMessageQuery, useWsMessagesQuery} from './ws/ws.query'
import {wsKeys} from './ws'
import {wsUrl} from './ws/ws.api'
import {useShareDataQuery, useShareToTwitterMutation} from './share/share.query'
import {
  useAcceptFriendRequestMutation,
  useDenyFriendRequestMutation,
  useFriendsQuery,
  useFriendsRequestsQuery,
  useFriendsStatsQuery,
  useFriendStatsQuery,
  useRemoveFriendMutation,
  useSendFriendRequestMutation
} from './friends'

const MAX_RECONNECT_ATTEMPTS = 5
const RECONNECT_TIMEOUT = 1000

export class NetworkInstance {
  ws: WebSocket | null = null
  reconnectAttempt: number = 0
  withoutReconnect: boolean = false
  queryClient: QueryClient | null = null
  token: string | null = null
  timer: ReturnType<typeof setTimeout> | null = null

  api: AxiosInstance
  isReady: boolean
  isDemo: boolean
  instance: AxiosInstance

  constructor(api: AxiosInstance) {
    this.api = api
    this.instance = this.api
    this.isReady = false
    this.isDemo = false
  }

  isEnabled(options: QueryObserverOptions) {
    return Boolean((this.isReady && (options.enabled || options.enabled === undefined)) || this.isDemo)
  }

  invalidateAll(queryClient: QueryClient) {
    queryClient.invalidateQueries()
  }

  ////PUBLIC SECTION

  ////PROTECTED SECTION

  //USER SECTION
  useUserQuery(options: QueryObserverOptions = {}) {
    return useUserQuery(
      {
        cacheTime: Number.POSITIVE_INFINITY,
        staleTime: Number.POSITIVE_INFINITY,
        retry: 0,
        ...options,
        enabled: this.isEnabled(options)
      },
      this.api,
      this.isDemo
    )
  }

  useUserInfoQuery(options: QueryObserverOptions = {}) {
    return useUserInfoQuery(
      {
        ...options,
        enabled: this.isEnabled(options)
      },
      this.api
    )
  }

  useUpdateUserMutation(options: any = {}) {
    return useUpdateUserMutation({...options, enabled: this.isEnabled(options)}, this.api)
  }

  useGoalsQuery(options: any = {}) {
    return useGoalsQuery({...options, enabled: this.isEnabled(options)}, this.api, this.isDemo)
  }

  useLinkPluginMutation(options: any = {}) {
    return useLinkPluginMutation({...options, enabled: this.isEnabled(options)}, this.api)
  }

  useSaveGoalMutation(options: QueryObserverOptions = {}) {
    return useSaveGoalMutation(options, this.api)
  }

  useSaveWeekPlanMutation(options: QueryObserverOptions = {}) {
    return useSaveWeekPlanMutation(options, this.api)
  }

  useWeekPlanQuery(options: QueryObserverOptions = {}) {
    return useWeekPlanQuery({...options, enabled: this.isEnabled(options)}, this.api)
  }

  useSendWakatimeUrlMutation(options: QueryObserverOptions = {}) {
    return useSendWakatimeUrlMutation(options, this.api)
  }

  //OVERVIEW SECTION
  useOverviewDayQuery(data: TOverviewRequest, options: QueryObserverOptions = {}) {
    return useOverviewDayQuery(data, {...options, enabled: this.isEnabled(options)}, this.api)
  }

  useOverviewWeekQuery(data: TOverviewRequest, options: QueryObserverOptions = {}) {
    return useOverviewWeekQuery(data, {...options, enabled: this.isEnabled(options)}, this.api)
  }

  useOverviewMonthQuery(data: TOverviewRequest, options: QueryObserverOptions = {}) {
    return useOverviewMonthQuery(data, {...options, enabled: this.isEnabled(options)}, this.api)
  }

  useOverviewYearQuery(data: TOverviewRequest, options: QueryObserverOptions = {}) {
    return useOverviewYearQuery(data, {...options, enabled: this.isEnabled(options)}, this.api)
  }

  useOverviewGoalsQuery(data: TOverviewRequest, options: QueryObserverOptions = {}) {
    return useOverviewGoalsQuery(data, {...options, enabled: this.isEnabled(options)}, this.api)
  }

  useChangeDayTypeMutation(options: QueryObserverOptions = {}) {
    return useChangeDayTypeMutation(options, this.api)
  }

  //AUTH SECTION

  //TEST SECTION
  useCreateTestUserMutation(options: QueryOptions = {}) {
    return useCreateTestUserMutation(options, this.api)
  }

  useTestUsersQuery(options: UseQueryOptions<ITestUser[]> = {}) {
    return useTestUsersQuery(options, this.api)
  }

  // ONBOARDING SECTION

  useOnboardingMutation(options: QueryObserverOptions = {}) {
    return useOnboardingMutation(options, this.api)
  }

  // PROJECTS SECTION
  useSaveProjectMutation(options: QueryObserverOptions = {}) {
    return useSaveProjectMutation(options, this.api)
  }

  useProjectsQuery(options: QueryObserverOptions = {}) {
    return useProjectsQuery(options, this.api)
  }

  useProjectQuery(id?: string | null, options: QueryObserverOptions = {}) {
    return useProjectQuery(id, options, this.api)
  }

  //SUBSCRIPTION SECTION
  useCheckoutSubscriptionMutation(options: QueryObserverOptions = {}) {
    return useCheckoutSubscriptionMutation(options, this.api)
  }

  useSubscriptionsListQuery(options: QueryObserverOptions = {}) {
    return useSubscriptionsListQuery(options, this.api)
  }

  useRefreshSubscriptionMutation(options: QueryObserverOptions = {}) {
    return useRefreshSubscriptionMutation(options, this.api)
  }

  useCancelSubscriptionMutation(options: QueryObserverOptions = {}) {
    return useCancelSubscriptionMutation(options, this.api)
  }

  //NOTIFICATIONS SECTION
  useNotificationsQuery(options: QueryObserverOptions = {}) {
    return useNotificationsQuery(options, this.api)
  }

  useMarkNotificationAsReadMutation(options: QueryObserverOptions = {}) {
    return useMarkNotificationAsReadMutation(options, this.api)
  }

  useMarkAllNotificationAsReadMutation(options: QueryObserverOptions = {}) {
    return useMarkAllNotificationAsReadMutation(options, this.api)
  }

  //PREVIEW SECTION
  useShareDataQuery(data: IShareDataRequest | null, options: QueryObserverOptions = {}) {
    return useShareDataQuery(data, options, this.api)
  }

  useShareToTwitterMutation(options: QueryObserverOptions = {}) {
    return useShareToTwitterMutation(options, this.api)
  }

  //FRIENDS SECTION

  useFriendsQuery(options: QueryObserverOptions = {}) {
    return useFriendsQuery(options, this.api)
  }

  useFriendsStatsQuery(data: IFriendOverviewRequest, options: QueryObserverOptions = {}) {
    return useFriendsStatsQuery(data, options, this.api)
  }

  useFriendStatsQuery(data: IFriendOverviewRequest, options: QueryObserverOptions = {}) {
    return useFriendStatsQuery(data, options, this.api)
  }

  useFriendsRequestsQuery(options: QueryObserverOptions = {}) {
    return useFriendsRequestsQuery(options, this.api)
  }

  useSendFriendRequestMutation(options: QueryObserverOptions = {}) {
    return useSendFriendRequestMutation(options, this.api)
  }

  useAcceptFriendRequestMutation(options: QueryObserverOptions = {}) {
    return useAcceptFriendRequestMutation(options, this.api)
  }

  useDenyFriendRequestMutation(options: QueryObserverOptions = {}) {
    return useDenyFriendRequestMutation(options, this.api)
  }

  useRemoveFriendMutation(options: QueryObserverOptions = {}) {
    return useRemoveFriendMutation(options, this.api)
  }

  //WS SECTION

  useWsMessagesQuery() {
    return useWsMessagesQuery()
  }

  useWsLastMessageQuery() {
    return useWsLastMessageQuery()
  }

  initWs(queryClient: QueryClient, token: string) {
    this.queryClient = queryClient
    this.token = token
    this.openWs()
  }

  openWs() {
    if (!this.queryClient) {
      console.log('queryClient is not defined')
      return
    }
    if (!this.token) {
      console.log('token is not defined')
      return
    }
    try {
      const {host, protocol} = window.location
      const connectionString = `${protocol.replace('http', 'ws')}//${host}${this.api.defaults.baseURL}${wsUrl}?token=${
        this.token
      }`
      this.ws = new WebSocket(connectionString)
      this.ws.onopen = () => {
        console.log('ws connected')
        this.reconnectAttempt = 0
        this.withoutReconnect = false
      }
      this.ws.onmessage = (event) => {
        const data = JSON.parse(event.data)
        this.queryClient?.setQueryData(wsKeys.last(), data)
        this.queryClient?.setQueryData(wsKeys.all(), (oldData) => {
          if (!oldData) {
            return [data]
          }
          return [...(oldData as TWsMessage[]), data]
        })
      }

      this.ws.onerror = (error) => {
        console.log('ws on error', error)
        this.ws?.close()
      }

      this.ws.onclose = () => {
        console.log('ws closed')
        this.timer && clearTimeout(this.timer)
        if (this.reconnectAttempt < MAX_RECONNECT_ATTEMPTS && !this.withoutReconnect) {
          console.log('try reconnect', this.reconnectAttempt)
          this.reconnectAttempt++
          this.timer = setTimeout(() => {
            this.openWs()
          }, RECONNECT_TIMEOUT)
        }
      }
    } catch (error) {
      console.log('ws error', error)
    }
  }

  closeWs() {
    console.log('ws close')
    this.withoutReconnect = true
    this.ws?.close()
  }
}

import {
  changeDayType,
  getOverviewDay,
  getOverviewGoals,
  getOverviewMonth,
  getOverviewWeek,
  getOverviewYear
} from './overview.api'
import {AxiosInstance} from 'axios'
import {useMutation, useQuery} from '@tanstack/react-query'
import {
  IOverviewDay,
  IOverviewMonth,
  IOverviewWeek,
  IOverviewYear,
  TChangeDayTypeRequest,
  TOverviewRequest
} from '../../types'
import {overviewKeys} from './overview.keys'

export const useOverviewDayQuery = (data: TOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery<IOverviewDay>({
    queryKey: overviewKeys.day(data),
    queryFn: () => getOverviewDay(data, api),
    ...options
  })
}

export const useOverviewWeekQuery = (data: TOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery<IOverviewWeek>({
    queryKey: overviewKeys.week(data),
    queryFn: () => getOverviewWeek(data, api),
    ...options
  })
}

export const useOverviewMonthQuery = (data: TOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery<IOverviewMonth>(overviewKeys.month(data), () => getOverviewMonth(data, api), options)
}

export const useOverviewYearQuery = (data: TOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery<IOverviewYear>(overviewKeys.year(data), () => getOverviewYear(data, api), options)
}

export const useOverviewGoalsQuery = (data: TOverviewRequest, options: any, api: AxiosInstance) => {
  return useQuery(overviewKeys.goalsByRange(data), () => getOverviewGoals(data, api), options)
}

export const useChangeDayTypeMutation = (options: any, api: AxiosInstance) => {
  return useMutation<void, any, TChangeDayTypeRequest>(
    (data: TChangeDayTypeRequest) => changeDayType(data, api),
    options
  )
}

import {FC, useEffect, useState} from 'react'
import {FormControl, FormErrorMessage, FormLabel, Link, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Button, IconButton, Input} from 'components'
import {useForm} from 'react-hook-form'
import {browserLocalPersistence, signInWithEmailAndPassword, setPersistence} from 'firebase/auth'
import {auth} from 'services/firebase/firebase'
import {emailRegex} from '@kaef/common/utils/helpers'
import {useAuthStore} from 'stores'
import {useLocation} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {generatePathFromLocation} from 'shared/utils/navigation.helper'

interface IProps {
  navToForgotPassword: () => void
  navToSignUp: () => void
  navToWelcome: () => void
}

interface ISignInForm {
  email: string
  password: string
}

const SignInScene: FC<IProps> = ({navToWelcome, navToForgotPassword, navToSignUp}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const [setAfterAuthRedirect] = useAuthStore((state) => [state.setAfterAuthRedirect])

  const [isLoading, setIsLoading] = useState(false)
  const [emailError, setEmailError] = useState<string | null>(null)
  const [passwordError, setPasswordError] = useState<string | null>(null)

  const {watch, formState, register, handleSubmit} = useForm<ISignInForm>({
    mode: 'onBlur'
  })

  const onSignIn = async (data: ISignInForm) => {
    setIsLoading(true)
    setEmailError(null)
    setPasswordError(null)
    const {email, password} = data
    try {
      if (location.state?.from) {
        setAfterAuthRedirect(generatePathFromLocation(location.state.from))
      } else {
        setAfterAuthRedirect(paths.dashboard.path)
      }
      await setPersistence(auth, browserLocalPersistence)
      await signInWithEmailAndPassword(auth, email, password)
    } catch (error) {
      if (error) {
        setEmailError(t('authorization.errors.incorrectPassword') as string)
        setPasswordError(t('authorization.errors.incorrectPassword') as string)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (emailError) {
      setEmailError(null)
    }
  }, [watch('email')])

  useEffect(() => {
    if (emailError) {
      setPasswordError(null)
    }
  }, [watch('password')])

  const isEmailError = !!formState.errors?.email || emailError
  const isPasswordError = !!formState.errors?.password || passwordError

  return (
    <>
      <IconButton
        top={'-20px'}
        left={'-10px'}
        icon={'arrowBack'}
        variant={'ghost'}
        onClick={navToWelcome}
        aria-label={'navToWelcome'}
      />
      <Text mb={'8px'} textStyle={'lg'} color={'white.1'}>
        {t('authorization.signIn.title')}
      </Text>
      <Text mb={'32px'} textStyle={'md'} color={'white.3'}>
        {t('authorization.signIn.subtitle')}
      </Text>

      <FormControl isInvalid={!!isEmailError}>
        <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.signIn.email')}
        </FormLabel>
        <Input
          isInvalid={!!isEmailError}
          {...register('email', {required: t('authorization.errors.emailRequired') as string, pattern: emailRegex})}
          type={'email'}
          placeholder={String(t('authorization.signIn.emailPlaceholder'))}
          size="md"
        />
        {isEmailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
      </FormControl>

      <FormControl mb={'40px'} isInvalid={!!isPasswordError}>
        <FormLabel mt={'32px'} mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.signIn.password')}
        </FormLabel>
        <Input
          isInvalid={!!isPasswordError}
          {...register('password', {
            required: t('authorization.errors.passwordRequired') as string,
            minLength: {value: 6, message: t('authorization.errors.passwordMinLength') as string}
          })}
          type={'password'}
          placeholder={String(t('authorization.signIn.passwordPlaceholder'))}
          size="md"
        />
        {isPasswordError && <FormErrorMessage>{formState.errors?.password?.message || passwordError}</FormErrorMessage>}
      </FormControl>

      <Text mb={'24px'} textStyle={'md'} color="white.2">
        <Link onClick={navToForgotPassword}>{t('authorization.signIn.forgotPassword')}</Link>
      </Text>

      <Button isLoading={isLoading} mb={'26px'} onClick={handleSubmit(onSignIn)} variant={'accent'}>
        {t('authorization.signIn.signIn')}
      </Button>

      <Text textStyle={'md'} color="white.3">
        {t('authorization.signIn.signUpTitle')}&nbsp;
        <Link onClick={navToSignUp} color="white.2">
          {t('authorization.signIn.signUp')}
        </Link>
      </Text>
    </>
  )
}

export default SignInScene

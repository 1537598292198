import React, {FC, useState} from 'react'
import {Flex, Popover, PopoverContent, PopoverContentProps, PopoverProps, PopoverTrigger, Text} from '@chakra-ui/react'
import {Button} from '../Button/Button'
import {Icon} from '../Icons/Icon'

type TOption<T> = {
  value: T
  label: string
}

interface IProps<T> {
  width?: string
  value?: T | null
  options: TOption<T>[]
  placeholder?: string
  onChange: (value: T) => void
  isLoading?: boolean
  popoverProps?: PopoverProps
  popoverContentProps?: PopoverContentProps
  Button?: FC
}

export const Dropdown = <T,>({value, placeholder = '', Button: CustomButton, width, onChange, options, isLoading, popoverProps, popoverContentProps}: IProps<T>) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleSelect = (value: T) => {
    onChange(value)
    setIsOpen(false)
  }

  return (
    <Popover placement={'bottom'} {...popoverProps} isOpen={isOpen} onClose={() => setIsOpen(false)} >
      <PopoverTrigger>
        {CustomButton ? <CustomButton /> :
          <Button
            isLoading={isLoading}
            onClick={() => setIsOpen(true)}
            w={width}
            bgColor={'white.1-10'}
            variant={'secondary'}
            border={'none'}>
            <Flex align={'center'}>
              <Text color={'white.1'} mr={'6px'} textStyle={'md'}>
                {options.find(item => item.value === value)?.label || placeholder}
              </Text>
              <Icon type={'arrowDown'} color={'white.1'} />
            </Flex>
          </Button>}
      </PopoverTrigger>
      <PopoverContent width={width} bgColor={'black.3'} flexDirection={'column'} {...popoverContentProps}>
        {options.map((item) => (
          <Button key={String(item.value)}
                  onClick={() => handleSelect(item.value)}
                  variant={'secondary'}>
            {item.label}
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  )
}
import React, {FC, useState} from 'react'
import {Box, Divider, Flex, Skeleton, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {api} from 'services/api'
import {HiddenDivider, PressableBox, Toolkit} from 'components'
import {IProject} from '@kaef/common/types'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useQueryClient} from '@tanstack/react-query'
import {emojiPalette} from 'services/chart'
import {format, parseISO} from 'date-fns'
import {colorFuse} from '@kaef/common/utils/helpers'
import {logProjectOpenDetails, logProjectToggleVisibility} from 'services/analytics/analytics'

const Projects: FC = () => {
  const {t} = useTranslation()
  const queryClient = useQueryClient()
  const navigate = useNavigate()
  const location = useLocation()
  const [isShowHidden, setIsShowHidden] = useState(false)

  const {data, isLoading} = api.useProjectsQuery()
  const saveProjectMutation = api.useSaveProjectMutation()

  const isEmpty = !isLoading && (!data || data.length === 0)

  const hiddenProjects = data?.filter((item) => !item.show).sort((a, b) => a.name.localeCompare(b.name)) || []
  const visibleProjects = data?.filter((item) => item.show).sort((a, b) => a.name.localeCompare(b.name)) || []

  const onEditProject = (project: IProject) => {
    navigate(`${paths.editProject.path}/${project.id}`, {state: {backgroundLocation: location}})
  }

  const onNavToProjectOverview = (project: IProject) => {
    logProjectOpenDetails()
    navigate(`${paths.overview.path}/day/${project.id}`)
  }

  const onToggleVisibility = (project: IProject) => {
    logProjectToggleVisibility()
    saveProjectMutation.mutate(
      {
        ...project,
        show: !project.show
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(overviewKeys.all())
          queryClient.invalidateQueries(projectsKeys.all())
        }
      }
    )
  }

  return (
    <Flex w={'100%'} justifyContent={'center'} pt={'40px'} pb={'82px'}>
      <Flex
        p={'32px 24px'}
        w={'100%'}
        maxW={'953px'}
        minH={'100%'}
        flexDirection={'column'}
        bgColor={'white.1-05'}
        borderWidth={'1px'}
        borderColor={'black.3-40'}
        borderRadius={'8px'}>
        <Text textStyle={'lg'}>{t('projects.title')}</Text>
        <Divider m={'32px 0'} w={'100%'} orientation={'horizontal'} bgColor={'black.4'} />
        <Flex w={'100%'} justifyContent={'center'}>
          <Flex flexDirection={'column'} alignItems={'center'} w={'100%'} maxW={'650px'}>
            {isEmpty && <Text textStyle={'md'}>{t('projects.empty')}</Text>}
            {isLoading && (
              <>
                <Skeleton borderRadius={'8px'} w={'100%'} h={'40px'} mb={'8px'} />
                <Skeleton borderRadius={'8px'} w={'100%'} h={'40px'} mb={'8px'} />
                <Skeleton borderRadius={'8px'} w={'100%'} h={'40px'} mb={'8px'} />
                <Skeleton borderRadius={'8px'} w={'100%'} h={'40px'} mb={'8px'} />
              </>
            )}
            {!isLoading &&
              visibleProjects.map((item) => {
                return (
                  <PressableBox
                    onClick={() => onNavToProjectOverview(item)}
                    key={item.id}
                    mb={'8px'}
                    withoutPadding
                    toolkitWidth={64}
                    toolkit={
                      <Toolkit
                        isVisible={item.show}
                        onEdit={() => onEditProject(item)}
                        onToggleVisibility={() => onToggleVisibility(item)}
                      />
                    }>
                    <Flex
                      w={'100%'}
                      h={'40px'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      position={'relative'}>
                      <Flex alignItems={'center'} pl={'10px'}>
                        <Box
                          mr={'4px'}
                          filter={'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5))'}
                          w={'14px'}
                          h={'14px'}
                          bgColor={colorFuse(item.color)}
                          borderRadius={'16px'}
                        />
                        <Flex
                          ml={'6px'}
                          w={'24px'}
                          h={'24px'}
                          mr={'4px'}
                          alignItems={'center'}
                          justifyContent={'center'}>
                          <Text textStyle={'sm'}>{item.icon || emojiPalette.getProjectEmoji(item.id)}</Text>
                        </Flex>
                        <Text
                          overflow={'hidden'}
                          maxWidth={['170px', '170px', '400px']}
                          textOverflow={'ellipsis'}
                          whiteSpace={'nowrap'}
                          mr={'6px'}
                          textStyle={'md'}
                          color={'white.1'}>
                          {item.name}
                        </Text>
                      </Flex>
                      <Flex pr={'10px'}>
                        <Text textStyle={'md'} color={'white.3'}>
                          {format(parseISO(item.createdAt), 'dd-MM-yyyy')}
                        </Text>
                      </Flex>
                    </Flex>
                  </PressableBox>
                )
              })}

            {hiddenProjects.length > 0 && (
              <HiddenDivider onToggle={setIsShowHidden} isShow={isShowHidden} length={hiddenProjects?.length} />

            )}

            {!isLoading && isShowHidden &&
              hiddenProjects.map((item) => {
                return (
                  <PressableBox
                    onClick={() => onNavToProjectOverview(item)}
                    mb={'8px'}
                    key={item.id}
                    withoutPadding
                    toolkitWidth={64}
                    toolkit={
                      <Toolkit
                        isVisible={item.show}
                        onEdit={() => onEditProject(item)}
                        onToggleVisibility={() => onToggleVisibility(item)}
                      />
                    }>
                    <Flex
                      w={'100%'}
                      h={'40px'}
                      alignItems={'center'}
                      justifyContent={'space-between'}
                      position={'relative'}>
                      <Flex alignItems={'center'} pl={'10px'}>
                        <Box
                          mr={'4px'}
                          filter={'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5))'}
                          w={'14px'}
                          h={'14px'}
                          bgColor={colorFuse(item.color)}
                          borderRadius={'16px'}
                        />
                        <Flex
                          ml={'6px'}
                          w={'24px'}
                          h={'24px'}
                          mr={'4px'}
                          alignItems={'center'}
                          justifyContent={'center'}>
                          <Text textStyle={'sm'}>{item.icon || emojiPalette.getProjectEmoji(item.id)}</Text>
                        </Flex>
                        <Text
                          overflow={'hidden'}
                          maxWidth={['170px', '170px', '400px']}
                          textOverflow={'ellipsis'}
                          whiteSpace={'nowrap'}
                          mr={'6px'}
                          textStyle={'md'}
                          color={'white.1'}>
                          {item.name}
                        </Text>
                      </Flex>
                      <Flex pr={'10px'}>
                        <Text textStyle={'md'} color={'white.3'}>
                          {format(parseISO(item.createdAt), 'dd-MM-yyyy')}
                        </Text>
                      </Flex>
                    </Flex>
                  </PressableBox>
                )
              })}
          </Flex>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default Projects

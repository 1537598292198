import React from 'react'
import {Link as DomLink} from 'react-router-dom'
import {Box, Link, List, ListItem, Text} from '@chakra-ui/react'

interface IFooterBlock {
  name: string
  list: IFooterItem[]
}

interface IFooterItem {
  title: string
  link: string
  isInternal?: boolean
}

const FooterBlock = ({name, list}: IFooterBlock) => {
  return (
    <Box minWidth={'120px'}>
      <Text fontSize={'13px'} color={'white.3'} margin={'0 0 4px'}>
        {name}
      </Text>
      <List spacing={'4px'}>
        {list.map((item, idx) => (
          <ListItem key={idx} fontSize={'16px'} color={'white.2'}>
            {item.isInternal && (
              <Link as={DomLink} to={item.link ? item.link : '#'}>
                {item.title}
              </Link>
            )}
            {!item.isInternal && <Link href={item.link ? item.link : '#'}>{item.title}</Link>}
          </ListItem>
        ))}
      </List>
    </Box>
  )
}

export default FooterBlock

import React, {FC} from 'react'
import {EGoalResult, ERange, IOverviewGoal, TDayType, TGoalResultMap} from '@kaef/common/types'
import {Box, Flex, Text} from '@chakra-ui/react'
import {Icon, ProgressBox, ProgressLine} from 'components'
import {GoalCard} from '../GoalCard/GoalCard'
import { SuccessIcon } from '../SuccessIcon/SuccessIcon'
import {FailIcon} from '../FailIcon/FailIcon'
import {useTranslation} from 'react-i18next'
import {
  getGoalStatus,
  progressLineColorMap,
  secondsToString,
  secondsToTime,
  textColorMap,
} from '@kaef/common/utils/helpers'

interface IProps {
  range: ERange
  goal: IOverviewGoal
  onEdit: () => void
  onClickDay: (day: string, result: EGoalResult, dayType: TDayType | null) => void
}

const textMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'sidebar.todayGoals.start.startWorkEarlier',
  [EGoalResult.FAIL]: 'sidebar.todayGoals.start.startWorkLater',
  [EGoalResult.NO_GOAL]: '',
  [EGoalResult.INDETERMINATE]: 'sidebar.todayGoals.start.startWorkExact',
  [EGoalResult.IN_PROGRESS]: 'sidebar.todayGoals.start.notStarted',
  [EGoalResult.NOT_STARTED]: 'sidebar.todayGoals.start.notStarted',
  [EGoalResult.LOCKED]: '',
}


export const StartGoalCard: FC<IProps> = ({range, goal, onEdit, onClickDay}) => {
  const {t} = useTranslation()
  const {total, average, maxStreak, success, failed} = goal!.stats
  const status = getGoalStatus(range, goal)
  const totalGoalDays = total

  const renderLeftPart = () => {
    switch (range) {
      case ERange.DAY:
        return (
          <Flex flexDirection={'column'} alignItems={'flex-start'}>
            <Box position={'relative'}>
              <Icon height={20} width={20} type={'start'} color={textColorMap[status]} />
              {status === 'SUCCESS' && <SuccessIcon />}
              {status === 'FAIL' && <FailIcon />}
            </Box>
            <Text mt={'6px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.title')}</Text>
            {status === 'IN_PROGRESS' && <Text color={'white.1'} textStyle={'md'}>--:--</Text>}
            {status !== 'IN_PROGRESS' && <Text color={'white.1'} textStyle={'md'}>{secondsToTime(goal?.history[0]?.value as number)}</Text>}
          </Flex>
        )
      case ERange.WEEK:
      case ERange.MONTH:
      case ERange.YEAR:
        return (
          <Flex flexDirection={'column'} alignItems={'flex-start'}>
            <Box position={'relative'}>
              <Icon height={20} width={20} type={'start'} color={textColorMap[status]} />
              {status === 'SUCCESS' && <SuccessIcon />}
              {status === 'FAIL' && <FailIcon />}
            </Box>
            <Text mt={'6px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.averageStart')}</Text>
            <Text color={'white.1'} textStyle={'md'}>{average === 0 ? '--:--' : secondsToTime(average)}</Text>
          </Flex>
        )
    }
  }

  const renderRightPart = () => {
    switch (range) {
      case ERange.DAY:
        const difference = Math.abs(Number(goal?.history[0]?.value) - goal?.value)
        const startGoalPercent = goal?.value / 86400 * 100
        const resultGoalPercent = Number(goal?.history[0]?.value) / 86400 * 100

        const start = startGoalPercent > resultGoalPercent ? resultGoalPercent : startGoalPercent
        const end = startGoalPercent > resultGoalPercent ? startGoalPercent : resultGoalPercent

        return (
          <Flex flexDirection={'column'} h={'100%'} justifyContent={'center'}  w={'100%'}>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t(textMap[status], {value: secondsToString(difference, {withWhitespace: true})})}</Text>
            <ProgressLine isEmpty={status === 'NO_GOAL'}
                          trackColor={'white.3-10'}
                          shortMark={{color: 'white.3', position: startGoalPercent}}
                          fillBar={{color: progressLineColorMap.fillColor[status], start, end}}
                          longMark={{color: progressLineColorMap.longMarkColor[status], position: resultGoalPercent}} />
            {status === 'NO_GOAL' && <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.noGoal')}</Text>}
            {status !== 'NO_GOAL' && <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.startWorkGoal', {value: secondsToTime(goal?.value)})}</Text>}
          </Flex>
        )
      case ERange.WEEK:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <ProgressBox mode={'week'} data={goal?.history} onClick={onClickDay} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.startWorkGoal', {value: secondsToTime(goal?.value)})}</Text>
          </Flex>
        )
      case ERange.MONTH:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <ProgressBox mode={'month'} data={goal?.history} onClick={onClickDay} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.startWorkGoal', {value: secondsToTime(goal?.value)})}</Text>
          </Flex>
        )
      case ERange.YEAR:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.longestStreak', {value: maxStreak})}</Text>
            <ProgressLine trackColor={'white.3-10'}
                          fillBar={{color: 'state.positive-50', start: 0, end: success/(success + failed) * 100}}
                          secondFillBar={{color: 'state.negative-50', start: success/(success + failed) * 100, end: 100}} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.start.startWorkGoal', {value: secondsToTime(goal?.value)})}</Text>
          </Flex>
        )
    }
  }


  return (
    <GoalCard status={status}
              isWide={range === ERange.MONTH}
              LeftElement={() => renderLeftPart()}
              RightElement={() => renderRightPart()}
              onEdit={onEdit} />
  )
}
import React, {FC, useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {Button, ColorPicker, EmojiPicker, Input, Modal} from '../../UI'
import {InputGroup, InputLeftElement, Flex, Text, CircularProgress} from '@chakra-ui/react'
import {api} from 'services/api'
import {emojiPalette} from 'services/chart'
import {useTranslation} from 'react-i18next'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {useQueryClient} from '@tanstack/react-query'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'
import {logProjectEdit} from 'services/analytics/analytics'

const EditProjectModal: FC = () => {
  const {t} = useTranslation()
  const goBack = useGoBack()
  const queryClient = useQueryClient()
  const [name, setName] = useState('')
  const [color, setColor] = useState('')
  const [icon, setIcon] = useState('')

  const {id} = useParams()
  const {isLoading, data} = api.useProjectQuery(id!)
  const saveProjectMutation = api.useSaveProjectMutation()

  const onSave = () => {
    if (!name || !name.trim()) {
      return
    }
    logProjectEdit({
      name: name !== data?.name,
      icon: icon !== data?.icon,
      color: color !== data?.color
    })
    if (data) {
      saveProjectMutation.mutate(
        {
          ...data,
          name: name.trim(),
          color,
          icon
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.all())
            queryClient.invalidateQueries(projectsKeys.all())
          },
          onSettled: () => {
            goBack(paths.editProject.backgroundPath)
          }
        }
      )
    }
  }

  const onChange = (event: any) => {
    setName(event.target.value)
  }

  useEffect(() => {
    if (data) {
      setName(data?.name)
      setColor(data?.color)
      setIcon(data?.icon || emojiPalette.getProjectEmoji(data.id))
    }
  }, [data])

  return (
    <Modal onClose={() => goBack(paths.editProject.backgroundPath)} withoutClose blockScrollOnMount={false}>
      <Text mb={'24px'} textStyle={'lg'} color={'white.1'}>
        {t('project.editModal.title')}
      </Text>
      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Flex>
      )}
      {!isLoading && data && (
        <InputGroup>
          <InputLeftElement w={'auto'} pl={'6px'}>
            <Flex>
              <ColorPicker color={color} onChange={setColor} />
              <EmojiPicker icon={icon} onChange={setIcon} />
            </Flex>
          </InputLeftElement>
          <Input
            pl={'76px'}
            value={name}
            onChange={onChange}
            placeholder={t('project.editModal.placeholder') as string}
          />
        </InputGroup>
      )}
      <Flex gap={'20px'} mt={'32px'} justifyContent={'flex-end'}>
        <Button mb={'16px'} onClick={() => goBack(paths.editProject.backgroundPath)} variant={'secondary'} disabled={saveProjectMutation.isLoading}>
          {t('project.editModal.cancel')}
        </Button>
        <Button onClick={onSave} variant={'accent'} isLoading={saveProjectMutation.isLoading}>
          {t('project.editModal.save')}
        </Button>
      </Flex>
    </Modal>
  )
}
export default EditProjectModal

import {Center, CircularProgress, Flex, Image, Skeleton, Text} from '@chakra-ui/react'
import {FC, useCallback, useEffect} from 'react'
import {useOverviewStore, useYearSliceStore} from 'stores'
import {api} from 'services/api'
import {ButtonGroup, Stat} from 'components'
import {SliceDataList} from '../SliceDataList/SliceDataList'
import {TotalYearChart} from './components/TotalYearChart'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {linkParamsToUrl, prepareOverviewRequestData, secondsToString} from '@kaef/common/utils/helpers'
import {useTranslation} from 'react-i18next'
import {secondaryGroupContainerProps} from '../utils'
import {
  logChangeOverview,
  logChangeSlice,
  logOpenPaywall,
  logProjectOpenDetails,
  logProjectToggleVisibility
} from 'services/analytics/analytics'
import {useInitFromUrlParams} from 'shared/hooks/useInitFromUrlParams'
import {ERange, ILinkParams, ISliceData} from '@kaef/common/types'
import {useQueryClient} from '@tanstack/react-query'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import Monitor from 'assets/monitor.png'

interface IProps {
  projectId: string | null
}

const YearLayout: FC<IProps> = ({projectId}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()

  const year = useOverviewStore((state) => state.year)
  const setMonth = useOverviewStore((state) => state.setMonth)

  const [slice, setSlice, chart, setChart] = useYearSliceStore((state) => [
    state.slice,
    state.setSlice,
    state.chart,
    state.setChart
  ])

  const {isLoading, isError, data} = api.useOverviewYearQuery(prepareOverviewRequestData(year, ERange.YEAR, projectId))
  const projectsQuery = api.useProjectsQuery()
  const saveProjectMutation = api.useSaveProjectMutation()
  const userQuery = api.useUserInfoQuery()

  const sliceKey = useInitFromUrlParams(ERange.MONTH, () => {})

  const isNoPlugins = !isLoading && userQuery.data?.plugins.length === 0

  const onChangeSlice = (value: string) => {
    logChangeSlice(value, 'year')
    logChangeOverview('year', chart, value)
    const findSlice = data?.slices.find((slice) => slice.type === value)
    if (findSlice) {
      setSlice(findSlice)
    }
  }

  const onMonthClick = useCallback(
    (month: Date, isLocked?: boolean) => {
      if (isLocked) {
        logOpenPaywall('year_month_click')
        navigate(paths.paywall.path, {state: {backgroundLocation: location}})
        return
      }
      setMonth(month)
      navigate(`${paths.overview.path}/month/${projectId || ''}`)
    },
    [navigate, projectId, setMonth]
  )

  const onEditProject = (data: ISliceData) => {
    navigate(`${paths.editProject.path}/${data.uuid}`, {state: {backgroundLocation: location}})
  }

  const onNavToSliceDetails = (linkParams: ILinkParams, isLocked?: boolean) => {
    if (isLocked) {
      logOpenPaywall('year_slice_details')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    logProjectOpenDetails()
    navigate(`${paths.overview.path}/year?${linkParamsToUrl(linkParams)}`)
  }

  //TODO move to toggle visibility component, remove from other ranges
  const onToggleVisibility = (data: ISliceData) => {
    if (!projectsQuery.data) {
      return
    }
    const findProject = projectsQuery.data.find((item) => item.id === data.uuid)
    if (findProject) {
      logProjectToggleVisibility()
      saveProjectMutation.mutate(
        {
          ...findProject,
          show: !findProject.show
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.all())
            queryClient.invalidateQueries(projectsKeys.all())
          }
        }
      )
    }
  }

  useEffect(() => {
    if (!data?.slices) {
      return
    }
    if (slice && data?.slices.find((item) => item.type === slice.type)) {
      return
    }
    if (!sliceKey) {
      setSlice(data?.slices[0])
    }
    const findSlice = data?.slices.find((slice) => slice.type === sliceKey)
    if (findSlice) {
      setSlice(findSlice)
    } else {
      setSlice(data?.slices[0])
    }
  }, [data])

  const currentSlice = data?.slices?.find((_slice) => _slice.type === slice?.type)

  const slices = data?.slices?.map((slice) => ({
    label: slice.title,
    value: slice.type
  }))

  const {total, average} = data || {total: 0, average: 0}

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {isError && <Flex>Request Error</Flex>}
      {!isError && isNoPlugins && (
        <Flex alignItems={'center'} flexDirection={'column'}>
          <Image w={'210px'} mt={'10px'} mb={'10px'} src={Monitor} />
          <Text mt={'20px'} color={'white.1'} textStyle={'md'}>
            {t('overview.noPlugin')}
          </Text>
        </Flex>
      )}
      {!isError && !isNoPlugins && (
        <>
          {isLoading && (
            <Center pb={'42px'} h={'335px'} w={'100%'}>
              <CircularProgress isIndeterminate color={'accent.1'} />
            </Center>
          )}
          {!isLoading && (
            <Flex w={'100%'} flexDirection={['column-reverse', 'column-reverse', 'row']}>
              <Flex mt={'15px'} w={'100%'} mr={'40px'}>
                <TotalYearChart
                  onMonthClick={onMonthClick}
                  year={year}
                  data={currentSlice?.byMonth}
                  sliceData={currentSlice?.items}
                />
              </Flex>
              <Flex
                flexDirection={['row', 'row', 'column']}
                justifyContent={['space-between', 'space-between', 'flex-start']}
                w={['100%', '100%', '160px']}
                flexShrink={0}
                mt={'16px'}>
                <Flex flexDirection={['row', 'row', 'column']} gap={'12px'}>
                  <Stat value={secondsToString(Math.round(total))} title={t('overview.year.total')} />
                  <Stat value={secondsToString(Math.round(average))} title={t('overview.year.avgMonth')} />
                  {/*<Stat value={secondsToString(Math.round(average))} title={t('overview.year.avgDays')} />*/}
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex justifyContent={'center'}>
            {isLoading ? (
              <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} />
            ) : (
              <ButtonGroup
                containerProps={secondaryGroupContainerProps}
                variant="secondary"
                options={slices}
                value={slice?.type}
                onChange={onChangeSlice}
              />
            )}
          </Flex>
          {!isLoading && !isError && total === 0 && (
            <Flex mt={'40px'} mb={'40px'} w={'100%'} flexDirection={'column'} alignItems={'center'}>
              <Text textStyle={'md'} mb={'4px'}>
                {t('overview.noActivityTitle')}
              </Text>
              <Text textAlign={'center'} maxW={'280px'} textStyle={'sm'} color={'white.3'}>
                {t('overview.noActivityDescription')}
              </Text>
            </Flex>
          )}
          <SliceDataList
            onToggleVisibility={onToggleVisibility}
            onEditProject={onEditProject}
            withToolkit={currentSlice?.type === 'PROJECT'}
            withEmoji={currentSlice?.type === 'PROJECT'}
            data={currentSlice?.items}
            isLoading={isLoading}
            onClick={onNavToSliceDetails}
          />
        </>
      )}
    </Flex>
  )
}

export default YearLayout

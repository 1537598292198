import {FC, memo} from 'react'
import {Flex, useBreakpointValue} from '@chakra-ui/react'
import {IItem, IOverviewDayInWeek} from '@kaef/common/types'
import {generateBarData, generateWeekAxisData, findMinMax} from '@kaef/common/utils/helpers'
import {BarChart} from 'components/Charts/Echarts/BarChart'
import {addDays} from 'date-fns'

interface IProps {
  onDayClick: (value: Date, isLocked?: boolean) => void
  data?: IOverviewDayInWeek[]
  sliceData?: IItem[]
  week: Date
}

const TotalWeekChartRaw: FC<IProps> = ({onDayClick, data, sliceData, week}) => {
  const barWidth = useBreakpointValue(
    {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 38,
      xl: 58,
      '2xl': 58
    },
    {
      fallback: 'md'
    }
  )
  if (!data || !sliceData) {
    return null
  }

  const handleDayClick = (index: number, dataId: string) => {
    const findSlice = sliceData.find((slice) => slice.uuid === dataId)
    onDayClick(addDays(week, index), findSlice?.isLocked)
  }

  const {min, max} = findMinMax(data)

  return (
    <Flex flexDirection={'column'} w={'100%'} mb={'34px'}>
      <BarChart
        onClick={handleDayClick}
        date={week}
        min={min}
        max={max}
        height={307}
        granularity={'hour'}
        axisData={generateWeekAxisData(week)}
        totalData={data.map((day) => day.total)}
        data={generateBarData(data, sliceData, 7)}
        chartOptions={{
          seriesOptions: {
            barWidth,
            symbolSize: 18
          }
        }}
      />
    </Flex>
  )
}

export const TotalWeekChart = memo(TotalWeekChartRaw)

import React, {FC} from 'react'
import {Modal} from '../../UI'
import {JetBrains} from './JetBrains'
import {useParams} from 'react-router-dom'
import {VsCode} from './VsCode'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'
import {WakatimeImport} from './WakatimeImport'

const InstructionsModal: FC = () => {
  const goBack = useGoBack()
  const params = useParams()
  const {type} = params

  return (
    <Modal isOpen={true} onClose={() => goBack(paths.instructions.backgroundPath)} size={'2xl'}>
      {type === 'jetbrains' && <JetBrains />}
      {type === 'vscode' && <VsCode />}
      {type === 'wakatime-import' && <WakatimeImport source={'plugins'} />}
    </Modal>
  )
}

export default InstructionsModal

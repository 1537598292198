import React, {FC, useMemo} from 'react'
import {Center, CircularProgress, Divider, Flex, Text} from '@chakra-ui/react'
import {ERange, IFriend} from '@kaef/common/types'
import {ButtonGroup, IconButton} from 'components'
import {useTranslation} from 'react-i18next'
import {FriendsRangeSwitcher} from './components/FriendsRangeSwitcher'
import {logAddFriend, logChangeFriendsRange} from 'services/analytics/analytics'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {FriendsChart} from 'components/FriendsChart/FriendsChart'
import {overviewDateByRangeSelector, useFriendsStore} from 'stores'
import {api} from 'services/api'
import {prepareFriendsRequestData, prepareOverviewRequestData} from '@kaef/common/utils/helpers'

interface IProps {}

export const FriendsMain: FC<IProps> = ({}) => {
  const {t} = useTranslation()
  const location = useLocation()
  const navigate = useNavigate()
  const [range, setRange] = React.useState<ERange>(ERange.DAY)

  const friendsState = useFriendsStore((state) => state)
  const date = overviewDateByRangeSelector(range, friendsState)

  const friendsQuery = api.useFriendsStatsQuery(prepareFriendsRequestData({day: date, range}), {enabled: !!range})
  const userQuery = api.useUserInfoQuery()

  const dayOverview = api.useOverviewDayQuery(prepareOverviewRequestData(date, ERange.DAY), {
    enabled: !!range && range === ERange.DAY
  })
  const weekOverview = api.useOverviewWeekQuery(prepareOverviewRequestData(date, ERange.WEEK), {
    enabled: !!range && range === ERange.WEEK
  })
  const monthOverview = api.useOverviewMonthQuery(prepareOverviewRequestData(date, ERange.MONTH), {
    enabled: !!range && range === ERange.MONTH
  })
  const yearOverview = api.useOverviewYearQuery(prepareOverviewRequestData(date, ERange.YEAR), {
    enabled: !!range && range === ERange.YEAR
  })

  const selectTotalByRange = () => {
    switch (range) {
      case ERange.DAY:
        return dayOverview.data?.total
      case ERange.WEEK:
        return weekOverview.data?.total
      case ERange.MONTH:
        return monthOverview.data?.total
      case ERange.YEAR:
        return yearOverview.data?.total
      default:
        return dayOverview.data?.total
    }
  }

  const me = useMemo((): IFriend | null => {
    const total = selectTotalByRange()
    if (!userQuery.data || !total) {
      return null
    }

    return {
      id: userQuery.data.id,
      name: userQuery.data.name,
      email: userQuery.data.email,
      avatar: userQuery.data.picture,
      stats: {
        totalSeconds: total || 0,
        totalDurationGoal: total || 0
      }
    }
  }, [dayOverview.data, userQuery.data, weekOverview.data, monthOverview.data, yearOverview.data])

  const rangeOptions = [
    {
      label: t('overview.ranges.d'),
      value: ERange.DAY
    },
    {
      label: t('overview.ranges.w'),
      value: ERange.WEEK
    },
    {
      label: t('overview.ranges.m'),
      value: ERange.MONTH
    },
    {
      label: t('overview.ranges.y'),
      value: ERange.YEAR
    }
  ]

  const onAdd = () => {
    logAddFriend('friends')
    navigate(paths.addFriend.path, {state: {backgroundLocation: location}})
  }

  const changeRange = (value: ERange) => {
    logChangeFriendsRange(value)
    setRange(value)
  }

  return (
    <Flex
      width={'100%'}
      flexDirection={'column'}
      bgColor={'white.1-05'}
      borderWidth={'1px'}
      borderColor={'black.5-40'}
      p={'24px'}
      gap={'32px'}
      borderRadius={'8px'}>
      <Flex mt={'8px'} justifyContent={'space-between'} alignItems={'center'}>
        <Flex alignItems={'center'} gap={'12px'}>
          <Text textStyle={'lg'}>{t('friends.title')}</Text>
          <Center w={'38px'} h={'32px'} borderRadius={'4px'} bgColor={'white.1-05'}>
            <Text textStyle={'md'}>{friendsQuery.data?.length || 0}</Text>
          </Center>
        </Flex>
        <IconButton
          minW={'32px'}
          w={'32px'}
          h={'32px'}
          p={0}
          borderColor={'white.1-05'}
          bgColor={'black.3-40'}
          variant={'outline'}
          onClick={onAdd}
          icon={'plus'}
          color={'white.3'}
          aria-label={'add-friend'}
        />
      </Flex>
      <Divider />
      <Flex
        w={'100%'}
        justifyContent={'space-between'}
        flexDirection={['column-reverse', 'column-reverse', 'column-reverse', 'column-reverse', 'row']}>
        <FriendsRangeSwitcher range={range} />
        <ButtonGroup
          options={rangeOptions}
          value={range}
          onChange={changeRange}
          containerProps={{
            w: ['100%', '100%', '100%', '100%', '368px'],
            mb: ['12px', '12px', '12px', '12px', 0],
            flexShrink: 0,
            bg: 'black.4'
          }}
        />
      </Flex>
      {friendsQuery.isLoading && (
        <Center>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Center>
      )}
      {friendsQuery.data && <FriendsChart friends={friendsQuery.data || []} me={me} />}
    </Flex>
  )
}

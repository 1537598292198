import {FC, useEffect, useState} from 'react'
import {useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {api} from 'services/api'
import {Text, Center, useToast, CircularProgress} from '@chakra-ui/react'
import {paths} from 'shared/constants/paths'
import {useTranslation} from 'react-i18next'
import {logLinkPlugin} from 'services/analytics/analytics'

const LinkPluginConfirm: FC = () => {
  const toast = useToast()
  const {t} = useTranslation()
  const [searchParams, _] = useSearchParams()
  const [error, setError] = useState<string>('')
  let {id} = useParams()
  const navigate = useNavigate()

  const linkPluginMutation = api.useLinkPluginMutation()

  useEffect(() => {
    if (!id) {
      setError(t('linkPlugin.error.pluginId') as string)
      return
    }
    linkPluginMutation.mutate(id, {
      onSuccess: () => {
        const pluginType = searchParams.get('utm_source')
        logLinkPlugin(pluginType)
        toast({
          title: t('linkPlugin.successTitle'),
          description: t('linkPlugin.successDescription'),
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        navigate(paths.dashboard.path)
      },
      onError: () => {
        setError(t('linkPlugin.error.server') as string)
      }
    })
  }, [id])

  return (
    <Center height={'100vh'}>
      {linkPluginMutation.isLoading && <CircularProgress isIndeterminate color={'accent.1'} />}
      {error && (
        <Text textStyle={'lg'} color={'state.negative'}>
          {error}
        </Text>
      )}
    </Center>
  )
}

export default LinkPluginConfirm

export const extractInitials = (name?: string) => {
  if (!name) {
    return "";
  }
  const arr = name.trim().split(" ");
  if (arr.length === 1) {
    return arr[0].charAt(0).toUpperCase();
  }
  return `${arr[0].charAt(0).toUpperCase()}${arr[arr.length - 1]
    .charAt(0)
    .toUpperCase()}`;
};

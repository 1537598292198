import {useEffect, useRef, useState} from 'react'
import {useAppStore, useAuthStore} from 'stores'
import {paths} from 'shared/constants/paths'
import {auth, remoteConfig} from '../firebase/firebase'
import {api} from '../api'
import {useNavigate} from 'react-router-dom'
import {useQueryClient} from '@tanstack/react-query'
import {initUserForAnalytics, logLogin, logRegister} from '../analytics/analytics'
import {fetchAndActivate} from 'firebase/remote-config'

export const useAuth = () => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const [isReady, setIsReady] = useState(false)
  const isReadyRef = useRef<boolean>(false)

  const isAuth = useAuthStore((state) => state.isAuth)
  const setIsAuth = useAuthStore((state) => state.setIsAuth)
  const afterAuthRedirect = useAuthStore((state) => state.afterAuthRedirect)
  const setAfterAuthRedirect = useAuthStore((state) => state.setAfterAuthRedirect)
  const isForceSignOut = useAuthStore((state) => state.isForceSignOut)
  const setIsForceSignOut = useAuthStore((state) => state.setIsForceSignOut)
  const setIsDemo = useAppStore((state) => state.setIsDemo)

  const {data: userData, isError} = api.useUserQuery({enabled: isAuth})

  const providerRef = useRef<string | null>(null)

  useEffect(() => {
    const signOut = async () => {
      setIsAuth(false)
      setIsDemo(false)
      api.closeWs()
      api.isReady = false
      api.isDemo = false
      await queryClient.invalidateQueries({refetchType: 'none'})

      if (afterAuthRedirect) {
        navigate(afterAuthRedirect, {state: null})
        setAfterAuthRedirect(null)
      } else {
        navigate(paths.authorization.path, {state: {backgroundLocation: {pathname: paths.home.path}}})
      }
      queryClient.clear()
      queryClient.removeQueries()
      setIsForceSignOut(false)
    }
    if (isForceSignOut) {
      signOut()
    }
  }, [isForceSignOut])

  useEffect(() => {
    auth.onAuthStateChanged(async (user) => {
      if (user) {
        providerRef.current = user.providerId
        api.isReady = true
        setIsAuth(true)
        return
      }
      if (!user && !isReadyRef.current) {
        isReadyRef.current = true
        setIsReady(true)
        return
      }
      setIsForceSignOut(true)
    })
    auth.onIdTokenChanged(async (user) => {
      if (user) {
        const token = await user.getIdToken()
        api.initWs(queryClient, token)
      }
    })
  }, [auth])

  useEffect(() => {
    const check = async () => {
      if (!isReady && (isError || userData)) {
        await fetchAndActivate(remoteConfig)
        isReadyRef.current = true
        setIsReady(true)
      }
      if (!userData || isError) {
        return
      }
      if (userData) {
        initUserForAnalytics(userData)
        if (userData.isNew) {
          logRegister({provider: providerRef.current}, afterAuthRedirect)
        }
        logLogin(providerRef.current)
      }
      if (afterAuthRedirect) {
        navigate(afterAuthRedirect, {state: null})
        setAfterAuthRedirect(null)
        return
      }
    }
    check()
  }, [userData, isError])

  return isReady
}

import {ERange, TChart, TDayChart} from '@kaef/common/types'
import {useState, useEffect} from 'react'
import {useSearchParams} from 'react-router-dom'

const DAY_CHARTS: TChart[] = ['DAY_TOTAL', 'DAY_DETAILED']
const WEEK_CHARTS: TChart[] = ['WEEK_TOTAL', 'WEEK_AVERAGE']
const MONTH_CHARTS: TChart[] = ['MONTH_CALENDAR_COMPARE', 'MONTH_CALENDAR_TOTAL', 'MONTH_TOTAL']
const YEAR_CHARTS: TChart[] = ['YEAR_TOTAL']

const validateChartKey = (chart: string | null, range: ERange): TChart | null => {
  if (!chart) {
    return null
  }
  switch (range) {
    case ERange.DAY:
      if (DAY_CHARTS.includes(chart as TChart)) {
        return chart as TChart
      }
      break;
    case ERange.WEEK:
      if (WEEK_CHARTS.includes(chart as TDayChart)) {
        return chart as TChart
      }
      break;
    case ERange.MONTH:
      if (MONTH_CHARTS.includes(chart as TDayChart)) {
        return chart as TChart
      }
      break;
    case ERange.YEAR:
      if (YEAR_CHARTS.includes(chart as TDayChart)) {
        return chart as TChart
      }
      break;
  }
  return null
}

export const useInitFromUrlParams = (range: ERange, setChart: (chart: TChart) => void) => {
  let [searchParams, setSearchParams] = useSearchParams()
  const [slice, setSlice] = useState<string | null>(null)
  const [uuid, setUuid] = useState<string | null>(null)

  useEffect(() => {
    if (searchParams.has('chart')) {
      const chart = validateChartKey(searchParams.get('chart'), range)
      if (chart) {
        setChart(chart)
      }
      searchParams.delete('chart')
      setSearchParams(searchParams)
    }

    if (searchParams.has('slice')) {
      const sliceKey = searchParams.get('slice')
      if (sliceKey) {
        setSlice(sliceKey)
      }
      searchParams.delete('slice')
      setSearchParams(searchParams)
    }
  }, [])

  return slice
}
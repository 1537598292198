import {FC, useMemo} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {addDays, format, getDay, getDaysInMonth} from 'date-fns'
import {useTranslation} from 'react-i18next'

interface IProps {
  month: Date
  daysArray?: Date[]
  renderDay?: (date: Date, index: number) => JSX.Element
  sideColumnData?: string[]
}

type TDay = {
  isEmpty: boolean
  date: Date
}

type TGenerateDays = (month: Date, daysArray?: Date[]) => {offset: number; days: TDay[]}

const generateDaysArray: TGenerateDays = (month, daysArray) => {
  if (daysArray) {
    return {
      offset: 0, days: daysArray.map(day => {
        return {
          isEmpty: false,
          date: day
        }
      })
    }
  }
  const totalDays = getDaysInMonth(month)
  const startWeekDay = getDay(month)
  const offset = startWeekDay === 0 ? 6 : startWeekDay - 1
  const days = []
  for (let i = 0; i < offset; i++) {
    days.push({
      isEmpty: true,
      date: addDays(month, -i - 1)
    })
  }
  for (let i = 0; i < totalDays; i++) {
    days.push({
      isEmpty: false,
      date: addDays(month, i)
    })
  }
  return {offset, days}
}

//TODO add locale
export const CalendarLayout: FC<IProps> = ({sideColumnData, daysArray, month, renderDay}) => {
  const {t} = useTranslation()
  const weekTitles = useMemo(
    () => [
      t('common.calendar.mon'),
      t('common.calendar.tue'),
      t('common.calendar.wed'),
      t('common.calendar.thu'),
      t('common.calendar.fri'),
      t('common.calendar.sat'),
      t('common.calendar.sun')
    ],
    [t]
  )
  const {offset, days} = generateDaysArray(month, daysArray)

  const renderDayTitles = () => {
    return weekTitles.map((title) => (
      <Flex key={title} w={`${100 / 7}%`} height={'40px'} alignItems={'center'} justifyContent={'center'}>
        <Text textStyle={'sm'} color={'white.3'}>
          {title}
        </Text>
      </Flex>
    ))
  }
  const renderDayWrapper = (day: TDay, index: number) => {
    return (
      <Flex
        key={index}
        flexDirection={'column'}
        w={`${100 / 7}%`}
        height={'62px'}
        mb={'15px'}
        alignItems={'center'}
        justifyContent={'flex-end'}>
        {!day.isEmpty && (
          <>
            <Flex transition={'transform .2s ease'} _hover={{transform: 'scale(1.15)'}}>
              {renderDay && renderDay(day.date, index - offset)}
            </Flex>
            <Text textStyle={'sm'} color={'white.3'}>
              {format(day.date, 'd')}
            </Text>
          </>
        )}
      </Flex>
    )
  }
  return (
    <Flex w={'100%'}>
      <Flex flexDirection={'column'} w={'100%'}>
        <Flex justifyContent={'space-between'} w={'100%'}>
          {renderDayTitles()}
        </Flex>
        <Flex w={'100%'} flexWrap={'wrap'}>
          {days.map(renderDayWrapper)}
        </Flex>
      </Flex>
      {sideColumnData &&
        <Flex flexDirection={'column'} mt={'40px'} borderLeftWidth={'1px'} borderLeftStyle={'solid'}
              borderLeftColor={'white.3-20'}>
          {sideColumnData.map((item, index) => {
            return (<Flex paddingBottom={'7px'} key={index} w={'80px'} justifyContent={'center'} h={'62px'} mb={'15px'}
                          align={'center'}>
              <Text textStyle={'sm'} color={'white.3'}>
                {item}
              </Text>
            </Flex>)
          })}
        </Flex>}
    </Flex>
  )
}

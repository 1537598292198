import { create } from "zustand";

export const ONBOARDING_CONFIG = {
  TIME_STEP: 5 * 60,
  FULL_DAY: 24 * 60 * 60,
  THREE_HOURS: 3 * 60 * 60,
  SIX_HOURS: 6 * 60 * 60,
  NINE_HOURS: 9 * 60 * 60,
  TWELVE_HOURS: 12 * 60 * 60,
  EIGHTEEN_HOURS: 18 * 60 * 60,
};

export const RECOMMEND_CONFIG = {
  DURATION: 4 * 60 * 60,
  START: 9 * 60 * 60,
  END: 20 * 60 * 60,
  WORK_DAYS: [1, 2, 3, 4, 5],
}

interface IOnboardingState {
  name: string;
  setName: (name: string) => void;
  start: number;
  setStart: (start: number) => void;
  end: number;
  setEnd: (finish: number) => void;
  duration: number;
  setDuration: (duration: number) => void;
  days: number[];
  setDays: (days: number[]) => void;
}

export const useOnboardingStore = create<IOnboardingState>()((set) => ({
  name: '',
  setName: (name) => set({ name }),
  start: RECOMMEND_CONFIG.START,
  setStart:(start) => set({ start }),
  end: ONBOARDING_CONFIG.EIGHTEEN_HOURS,
  setEnd: (end) => set({ end }),
  duration: RECOMMEND_CONFIG.DURATION,
  setDuration: (duration) => set({ duration }),
  days: RECOMMEND_CONFIG.WORK_DAYS,
  setDays: (days) => set({ days }),
}));

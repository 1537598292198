import i18n from 'i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {initReactI18next} from 'react-i18next'

import {en} from '@kaef/common/locales/web/en'


enum ELang {
  En = 'en'
}

const langDictionary = new Map<ELang, string>([[ELang.En, 'english']])
i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: ELang.En,
    interpolation: {
      escapeValue: false
    },
    resources: {
      en: en
    }
  })

export default i18n

import {AxiosInstance} from 'axios'
import {useMutation, useQuery} from '@tanstack/react-query'
import {shareKeys} from './share.keys'
import {getShareData, shareToTwitter} from './share.api'
import {IShareDataRequest, IShareLink, IShareLinkRequest} from '../../types'

export const useShareDataQuery = (
  data: IShareDataRequest | null,
  options: any,
  api: AxiosInstance
) => {
  return useQuery(shareKeys.data(), () => getShareData(data, api), options)
}

export const useShareToTwitterMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<IShareLink, any, IShareLinkRequest | null>((data) => shareToTwitter(data, api), options)
}


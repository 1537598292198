import React, {FC, MouseEvent} from 'react'
import {Flex} from '@chakra-ui/react'
import {IconButton} from '../IconButton/IconButton'

interface IToolkitProps {
  onEdit?: () => void
  onToggleVisibility?: () => void
  isVisible?: boolean
}

export const Toolkit: FC<IToolkitProps> = ({onToggleVisibility, onEdit, isVisible}) => {
  const onEditClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onEdit && onEdit()
  }

  const onVisibleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
    onToggleVisibility && onToggleVisibility()
  }

  return (
    <Flex
      alignItems={'center'}
      borderWidth={'1px'}
      borderColor={'white.1-05'}
      height={'100%'}
      backgroundColor={'black.3'}
      w={'64px'}
      marginLeft={'4px'}
      borderTopLeftRadius={'4px'}
      borderBottomLeftRadius={'4px'}
      borderTopRightRadius={'12px'}
      borderBottomRightRadius={'12px'}>
      <IconButton
        color={'white.3'}
        onClick={onEditClick}
        size="sm"
        iconProps={{width: 20, height: 20}}
        w={'32px'}
        h={'32px'}
        icon={'edit'}
        aria-label={'editProject'}
        variant={'ghost'}
      />
      <IconButton
        color={'white.3'}
        onClick={onVisibleClick}
        size="sm"
        iconProps={{width: 20, height: 20}}
        w={'32px'}
        h={'32px'}
        icon={isVisible ? 'eyeShow' : 'eyeHide'}
        aria-label={'editProject'}
        variant={'ghost'}
      />
    </Flex>
  )
}

import { AxiosInstance } from "axios";
import {IProject} from '../../types'

export const getProjects = async (api: AxiosInstance): Promise<IProject[]> => {
  const response = await api.get("/user/projects");
  return response.data?.projects || [];
};

export const getProject = async (id: string, api: AxiosInstance): Promise<IProject> => {
  const response = await api.get(`/user/projects/${id}`);
  return response.data;
};

export const saveProject = async (data: IProject, api: AxiosInstance): Promise<IProject[]>  => {
  const response = await api.post("/user/projects", data);
  return response.data?.projects || []
};

import {FC} from 'react'
import {Divider, Flex} from '@chakra-ui/react'
import {IconButton} from 'components'
import './GoalCard.css'
import {EGoalResult, TGoalResultMap} from '@kaef/common/types'

interface IProps {
  status: EGoalResult
  LeftElement: FC
  RightElement: FC
  onEdit: () => void
  isWide: boolean
}

const bgSideColorMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'state.positive',
  [EGoalResult.FAIL]: 'state.negative',
  [EGoalResult.NO_GOAL]: 'transparent',
  [EGoalResult.INDETERMINATE]: 'transparent',
  [EGoalResult.IN_PROGRESS]: 'transparent',
  [EGoalResult.NOT_STARTED]: 'transparent',
  [EGoalResult.LOCKED]: 'transparent'
}

const bgColorMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'state.positive-05',
  [EGoalResult.FAIL]: 'state.negative-05',
  [EGoalResult.NO_GOAL]: 'white.1-05',
  [EGoalResult.INDETERMINATE]: 'white.1-05',
  [EGoalResult.IN_PROGRESS]: 'white.1-05',
  [EGoalResult.NOT_STARTED]: 'white.1-05',
  [EGoalResult.LOCKED]: 'white.1-05'
}

export const GoalCard: FC<IProps> = ({onEdit, status, LeftElement, RightElement, isWide}) => {
  return (
    <Flex className={'today-goal-hover-trigger'}
          position={'relative'}
          alignItems={'center'}
          bgColor={bgColorMap[status]}
          p={'1px'}
          w={'100%'}
          h={isWide ? '140px' : '112px'}
          minH={'100%'}
          borderRadius={'8px'}
          borderWidth={'1px'}
          mb={'12px'}
          borderColor={'black.5-40'}>
      <Flex bgColor={bgSideColorMap[status]}
            bgSize={status === EGoalResult.NO_GOAL ? '4px 4px' : undefined}
            bgImage={status === EGoalResult.NO_GOAL? 'linear-gradient(135deg, transparent 25%, white.1-10 25%, white.1-10 50%, transparent 50%, transparent 75%, white.1-10 75%, white.1-10 100%)' : undefined}
            w={'6px'}
            h={'100%'}
            flexShrink={0}
            flexGrow={0}
            borderRadius={'12px 1px 1px 12px'} />
      <Flex h={'100%'} w={'100%'} alignItems={'center'} pt={'16px'} pb={'16px'}>
        <Flex flexBasis={112} h={'100%'} pl={'24px'} alignItems={'center'}>
          <LeftElement />
        </Flex>
        <Divider orientation={'vertical'} minH={'inherit'} />
        <Flex flexBasis={260} h={'100%'} pl={'16px'} pr={'24px'} alignItems={'center'}>
          <RightElement />
        </Flex>
      </Flex>
      <Flex
        className={'today-goal-actions-container'}
        position={'absolute'}
        w={'48px'}
        pl={'8px'}
        flexDirection={'column'}
        right={['4px', '-48px']}
        top={['4px', '-1px']}>
        <IconButton onClick={onEdit} mb={'8px'} icon={'edit'} aria-label={'edit-goal'} />
        {/*<IconButton icon={'remove'} aria-label={'remove-goal'} />*/}
      </Flex>
    </Flex>
  )
}
import {FC} from 'react'
import {Box, Flex, Slider, SliderFilledTrack, SliderMark, SliderThumb, SliderTrack, Text} from '@chakra-ui/react'
import {ONBOARDING_CONFIG, RECOMMEND_CONFIG} from 'stores'
import {secondsToTime} from '@kaef/common/utils/helpers'
import {LABEL_STYLES, startFinishMarksConfig} from 'scenes/Onboarding/Onboarding.utils'
import {useTranslation} from 'react-i18next'
import {useRemoteConfig} from 'services/firebase/useRemoteConfig'

interface IProps {
  startValue: number
  setStart: (value: number) => void
}

export const StartModal: FC<IProps> = ({startValue, setStart}) => {
  const {t} = useTranslation()
  const {isGoalRecommendationEnabled} = useRemoteConfig()

  return (
    <>
      <Flex minHeight={'140px'}>
        <Flex flexDirection={'column'}>
          <Text mb={'6px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.start.title')}
          </Text>
          {isGoalRecommendationEnabled && startValue > RECOMMEND_CONFIG.START ? (
            <>
              <Text textStyle={'md'} color={'state.neutral'}>
                {t('onboarding.start.subtitleRecommend', {value: secondsToTime(RECOMMEND_CONFIG.START)})}
              </Text>
              <Text color={'white.3'} cursor={'pointer'} onClick={() => setStart(RECOMMEND_CONFIG.START)}>
                {t('onboarding.start.setRecommend')}
              </Text>
            </>
          ) : null}
        </Flex>
      </Flex>
      <Box pt={'15px'} pb={'15px'}>
        <Slider
          aria-label="slider-start"
          value={startValue}
          step={ONBOARDING_CONFIG.TIME_STEP}
          max={ONBOARDING_CONFIG.FULL_DAY}
          onChange={(val) => setStart(val)}>
          {startFinishMarksConfig.map((item, idx) => (
            <SliderMark key={`${idx}${item.value}`} value={item.value} {...LABEL_STYLES}>
              {item.label}
            </SliderMark>
          ))}
          <SliderMark
            value={startValue}
            textAlign="center"
            borderColor={'black.4'}
            bg={'black.2'}
            borderRadius={'8px'}
            borderWidth={'1px'}
            p={'10px'}
            color="white"
            m={'-85px 0 0 -40px'}>
            <Text>{secondsToTime(startValue)}</Text>
            <Text textStyle={'sm'} color={'white.3'} whiteSpace={'nowrap'}>
              {t('onboarding.start.sliderLabel')}
            </Text>
          </SliderMark>
          <SliderTrack height={'8px'}>
            <SliderFilledTrack bg={'black.4'} />
          </SliderTrack>
          <SliderThumb width={'24px'} height={'24px'} bg={'accent.1'} />
        </Slider>
      </Box>
    </>
  )
}

import {FC, useEffect} from 'react'
import {Outlet, useLocation, useNavigate} from 'react-router-dom'
import {Grid, GridItem} from '@chakra-ui/react'
import NavBar from './components/NavBar/NavBar'
import {paths} from 'shared/constants/paths'

const DashboardRouter: FC = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    if (location.pathname === paths.dashboard.path) {
      navigate(paths.overview.path, {replace: true})
    }
  }, [location])

  return (
    <Grid
      templateAreas={`"navbar" 
                      "main"`}
      gridTemplateRows={'auto 1fr'}
      gridTemplateColumns={'auto'}
      h="100vh"
      fontWeight="bold">
      <GridItem area={'navbar'}>
        <NavBar />
      </GridItem>
      <GridItem id={'main-container'} area={'main'} overflowY={'scroll'}>
        <Outlet />
      </GridItem>
    </Grid>
  )
}

export default DashboardRouter

import {FC, ComponentType} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {useAppStore, useAuthStore} from 'stores'

/**
 * Controls whether a route can be activated
 */

type IProps = {
  component: ComponentType
}

const ProtectedRoute: FC<IProps> = (props) => {
  const isAuth = useAuthStore((state) => state.isAuth)
  const isDemo = useAppStore((state) => state.isDemo)

  const location = useLocation()

  const {component: RouteComponent} = props
  if (isAuth || isDemo) {
    return <RouteComponent />
  }

  return (
    <Navigate
      to={paths.authorization.path}
      state={{backgroundLocation: {pathname: paths.home.path}, from: location}}
      replace
    />
  )
}

export default ProtectedRoute

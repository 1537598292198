import React, {FC, useEffect} from 'react'
import {Button, IconButton, Input, Modal} from 'components'
import {CircularProgress, Flex, FormControl, FormLabel, InputGroup, InputRightElement, Select} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {useForm} from 'react-hook-form'
import {api} from 'services/api'
import {nodatimeZones} from '@kaef/common/services/i18n/timezones'
import {useQueryClient} from '@tanstack/react-query'
import {userKeys} from '@kaef/common/api/user/user.keys'
import {useGoBack} from 'shared/utils/navigation.helper'
import {paths} from 'shared/constants/paths'

interface IProfile {
  name: string
  email: string
  timezone: string
  locale: string
}

const ProfileModal: FC = () => {
  const queryClient = useQueryClient()
  const {t} = useTranslation()
  const goBack = useGoBack()
  const userQuery = api.useUserInfoQuery()
  const {data, isLoading} = userQuery
  const updateUserMutation = api.useUpdateUserMutation()

  const {watch, reset, register, handleSubmit, setValue} = useForm<IProfile>({
    mode: 'onBlur'
  })

  const onSubmit = (form: IProfile) => {
    if (form) {
      updateUserMutation.mutate(
        {
          name: form.name,
          timezone: form.timezone,
          locale: form.locale
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(userKeys.all())
          },
          onSettled: () => {
            goBack(paths.profile.backgroundPath)
          }
        }
      )
    }
  }

  useEffect(() => {
    if (data) {
      reset({
        name: data.name,
        email: data.email,
        timezone: data.timezone,
        locale: data.locale
      })
    }
  }, [data])

  return (
    <Modal onClose={() => goBack(paths.profile.backgroundPath)}>
      {isLoading && (
        <Flex justifyContent={'center'}>
          <CircularProgress isIndeterminate color={'accent.1'} />
        </Flex>
      )}
      {!isLoading && (
        <>
          <FormControl mb={'24px'} mt={'16px'}>
            <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
              {t('profile.name')}
            </FormLabel>
            <InputGroup>
              <Input {...register('name')} defaultValue={data?.name} type={'text'} />
              {watch('name') ? (
                <InputRightElement>
                  <IconButton
                    icon={'close'}
                    variant={'ghost'}
                    onClick={() => {
                      setValue('name', '')
                    }}
                    aria-label={'resetName'}
                  />
                </InputRightElement>
              ) : null}
            </InputGroup>
          </FormControl>
          <FormControl mb="24px">
            <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
              {t('profile.email')}
            </FormLabel>
            <Input {...register('email')} type={'text'} isDisabled />
          </FormControl>
          <FormControl mb="24px">
            <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
              {t('profile.timezone')}
            </FormLabel>
            <Select {...register('timezone')}>
              {nodatimeZones.map((item) => (
                <option key={item} value={item}>
                  {item}
                </option>
              ))}
            </Select>
          </FormControl>
          {/*<FormControl mb='24px'>
          <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
            {t('profile.language')}
          </FormLabel>
          <Select
            {...register('locale')}
            isDisabled
          >
            <option value={'en'}>English</option>
          </Select>
        </FormControl>*/}
        </>
      )}
      <Button onClick={() => goBack(paths.profile.backgroundPath)} variant={'text'} isDisabled w={'auto'}>
        {t('profile.deleteMyAccount')}
      </Button>
      <Flex gap={'20px'} mt={'32px'} justifyContent={'flex-end'}>
        <Button mb={'16px'} onClick={() => goBack(paths.profile.backgroundPath)} variant={'secondary'} disabled={updateUserMutation.isLoading}>
          {t('profile.cancel')}
        </Button>
        <Button onClick={handleSubmit(onSubmit)} variant={'accent'} isLoading={updateUserMutation.isLoading}>
          {t('profile.save')}
        </Button>
      </Flex>
    </Modal>
  )
}

export default ProfileModal

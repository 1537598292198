import {AxiosInstance} from 'axios'
import {IBaseOverview, IFriend, IFriendActionRequest, IFriendOverviewRequest, IFriendResponse} from '../../types'

export const getFriends = async (api: AxiosInstance): Promise<IFriend[]> => {
  const response = await api.get<IFriendResponse>(`/user/friends?date=${new Date().toISOString()}`)
  return response.data?.friends || []
}

export const getFriendsStats = async (data: IFriendOverviewRequest, api: AxiosInstance): Promise<IFriend[]> => {
  // return friendsMock

  const {range, date} = data
  const query = `?date=${date}&range=${range}${data.limit ? `&limit=${data.limit}` : ''}`
  const response = await api.get<IFriendResponse>(`/user/friends/stats${query}`)

  return response.data?.friends || []
}

export const getFriendStats = async (data: IFriendOverviewRequest, api: AxiosInstance): Promise<IBaseOverview> => {
  const {range, date} = data
  const query = `?date=${date}&range=${range}&slice=FRIEND&type=FRIEND&friendId=${data.id}`
  const response = await api.get(`/summary/${range}${query}`)
  return response.data
}

export const getFriendRequests = async (api: AxiosInstance): Promise<IFriend[]> => {
  // return friendsRequestsMock

  const response = await api.get<IFriendResponse>('/user/friends/requests')

  return response.data?.friends || []
}

export const sendFriendRequest = async (email: string, api: AxiosInstance): Promise<void> => {
  const response = await api.post('/user/friends/requests', {email})
  return response.data
}

export const acceptActionFriendRequest = async (data: IFriendActionRequest, api: AxiosInstance): Promise<void> => {
  const response = await api.post(`/user/friends/action`, data)
  return response.data
}

import {FC} from 'react'
import {Flex} from '@chakra-ui/react'
import {IDayGranularity, IGoal, IMap, ISliceData} from '@kaef/common/types'
import {CalendarLayout, SimplePie} from 'components'
import {colorFuse} from '@kaef/common/utils/helpers'
import {parse} from 'date-fns'

interface IProps {
  onDayClick: (date: Date, isLocked: boolean) => void
  data?: IDayGranularity[]
  sliceMap?: IMap<ISliceData>
  month: Date
  goalDuration?: IGoal
}

export const SlicedMonthChart: FC<IProps> = ({onDayClick, goalDuration, month, data, sliceMap}) => {
  if (!data || !sliceMap || !goalDuration) {
    return null
  }

  const renderDay = (date: Date, index: number) => {
    if (data[index]) {
      const pieDate = data[index].totalList.map((item) => ({
        value: item.seconds,
        color: colorFuse(sliceMap[item.id].color)
      }))
      return <SimplePie withOpacity={!data[index].isCurMonth} isLocked={data[index].isLocked}
                        onClick={() => onDayClick(date, data[index].isLocked)} data={pieDate} />
    } else {
      return <></>
    }
  }

  return (
    <Flex flexDirection={'column'} w={'100%'} mb={'34px'}>
      <CalendarLayout daysArray={data.map(item => parse(item.day, 'yyyy-MM-dd', new Date()))} month={month}
                      renderDay={renderDay} />
    </Flex>
  )
}

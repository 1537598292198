import {FC} from 'react'
import {Box, Center, Flex} from '@chakra-ui/react'
import {EGoalResult, IGoalHistory, TDayType, TGoalResultMap} from '@kaef/common/types'
import {getDay, parse} from 'date-fns'
import {Icon} from '../../UI'

interface IProps {
  onClick: (day: string, result: EGoalResult, dayType: TDayType | null) => void
  mode: 'week' | 'month'
  data?: IGoalHistory[]
}

const getDayMonth = (date: string) => {
  const arr = date.split('-')
  return `${arr[2]}.${arr[1]}`
}

const colorMap: TGoalResultMap = {
  [EGoalResult.SUCCESS]: 'state.positive-50',
  [EGoalResult.FAIL]: 'state.negative-50',
  [EGoalResult.NO_GOAL]: 'transparent',
  [EGoalResult.NOT_STARTED]: 'transparent',
  [EGoalResult.INDETERMINATE]: 'white.3-50',
  [EGoalResult.IN_PROGRESS]: 'accent.1-50',
  [EGoalResult.LOCKED]: 'white.3-50'
}

export const ProgressBox: FC<IProps> = ({data, onClick, mode}) => {
  if (!data) {
    return null
  }

  const renderWeek = () => {
    return data.map((goalHistory, index) =>
      <Center
        _hover={{
          transform: 'scaleY(1.5)',
          transformOrigin: 'center center',
          borderWidth: '0.5px',
          borderColor: 'black.2'
        }}
        title={getDayMonth(goalHistory.day)}
        bgSize={goalHistory.result === EGoalResult.NO_GOAL ? '4px 4px' : undefined}
        bgImage={goalHistory.result === EGoalResult.NO_GOAL ? 'linear-gradient(135deg, transparent 25%, white.1-10 25%, white.1-10 50%, transparent 50%, transparent 75%, white.1-10 75%, white.1-10 100%)' : undefined}
        transition={'transform 0.15s ease'}
        borderColor={'black.5'}
        borderWidth={(goalHistory.result === EGoalResult.NOT_STARTED || goalHistory.result === EGoalResult.LOCKED) ? '1px' : 0}
        cursor={'pointer'}
        onClick={() => onClick(goalHistory.day, goalHistory.result, goalHistory.dayType)}
        key={index}
        borderRadius={'1px'}
        w={'22px'}
        h={'8px'}
        bgColor={colorMap[goalHistory.result]}
      >
        {goalHistory.result === EGoalResult.LOCKED && <Icon type={'lock'} color={'white.3'} width={14} height={14} />}
      </Center>
    )
  }

  const renderMonth = () => {
    const startDayOfMonth = getDay(parse(data[0].day, 'yyyy-MM-dd', new Date()))
    const daysToPrepend = startDayOfMonth === 0 ? 6 : startDayOfMonth - 1
    const endDayOfMonth = getDay(parse(data[data.length - 1].day, 'yyyy-MM-dd', new Date()))
    const daysToAppend = endDayOfMonth === 0 ? 0 : 7 - endDayOfMonth

    return (
      <>
        {Array.from({length: daysToPrepend}).map((_, index) =>
          <Box key={index} w={'22px'} h={'8px'} bgColor={'transparent'} />
        )}
        {data.map((goalHistory, index) => (
          <Center
            _hover={{
              transform: 'scaleY(1.5)',
              transformOrigin: 'center center',
              borderWidth: '0.5px',
              borderColor: 'black.2'
            }}
            opacity={goalHistory.isCurMonth ? 1 : 0.4}
            title={getDayMonth(goalHistory.day)}
            bgSize={goalHistory.result === EGoalResult.NO_GOAL ? '4px 4px' : undefined}
            bgImage={goalHistory.result === EGoalResult.NO_GOAL ? 'linear-gradient(135deg, transparent 25%, white.1-10 25%, white.1-10 50%, transparent 50%, transparent 75%, white.1-10 75%, white.1-10 100%)' : undefined}
            borderColor={'black.5'}
            borderWidth={(goalHistory.result === EGoalResult.NOT_STARTED || goalHistory.result === EGoalResult.LOCKED) ? '1px' : 0}
            transition={'transform 0.15s ease'}
            cursor={'pointer'}
            onClick={() => onClick(goalHistory.day, goalHistory.result, goalHistory.dayType)}
            key={index}
            borderRadius={'1px'}
            w={'22px'}
            h={'8px'}
            bgColor={colorMap[goalHistory.result]}
          >
            {goalHistory.result === EGoalResult.LOCKED &&
              <Icon type={'lock'} color={'white.3'} width={14} height={14} />}
          </Center>))}
        {Array.from({length: daysToAppend}).map((_, index) =>
          <Box key={index} w={'22px'} h={'8px'} />
        )}
      </>
    )
  }

  return (
    <Flex w={'100%'} maxW={'182px'} gap={'4px'} justifyContent={'flex-start'} alignItems={'center'}
          flexWrap={mode === 'month' ? 'wrap' : 'nowrap'}>
      {mode === 'week' && renderWeek()}
      {mode === 'month' && renderMonth()}
    </Flex>
  )
}

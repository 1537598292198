import {Divider, Flex, Text} from '@chakra-ui/react'
import {Icon} from '../UI'
import React, {FC} from 'react'
import {useTranslation} from 'react-i18next'


interface IProps {
  length?: number
  onToggle: (value: boolean) => void
  isShow: boolean
}

export const HiddenDivider: FC<IProps> = ({onToggle, isShow, length}) => {
  const {t} = useTranslation()
  return (
    <Flex w={'100%'} mb={'8px'} h={'36px'} alignItems={'center'} zIndex={100}>
      <Divider />
      <Flex gap={'8px'} cursor={'pointer'} alignItems={'center'} ml={'16px'} mr={'16px'} flexShrink={0} onClick={() => onToggle(!isShow)}>
        <Text textStyle={'md'} color={'white.3'}>
          {t('projects.hidden', {value: length})}
        </Text>
        <Flex transform={isShow ? 'rotate(180deg)' : 'rotate(0)'} transition={'transform 0.1s linear'}>
          <Icon type={'arrowDown'} color={'white.3'} />
        </Flex>
      </Flex>
      <Divider />
    </Flex>
  )
}
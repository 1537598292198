import {FC, memo} from 'react'
import {Flex, useBreakpointValue} from '@chakra-ui/react'
import {IItem, IMonthInYear} from '@kaef/common/types'
import {findMinMax, generateYearAxisData, generateYearBarData} from '@kaef/common/utils/helpers'
import {BarChart} from 'components/Charts/Echarts/BarChart'
import {setMonth} from 'date-fns'

interface IProps {
  onMonthClick: (date: Date, isLocked?: boolean) => void
  data?: IMonthInYear[]
  sliceData?: IItem[]
  year: Date
}

const TotalYearChartRaw: FC<IProps> = ({onMonthClick, data, sliceData, year}) => {
  const barWidth = useBreakpointValue(
    {
      xs: 24,
      sm: 24,
      md: 24,
      lg: 24,
      xl: 46,
      '2xl': 46
    },
    {
      fallback: 'md'
    }
  )

  if (!data || !sliceData) {
    return null
  }

  const handleMonthClick = (index: number, dataId: string) => {
    const findSlice = sliceData.find((slice) => slice.uuid === dataId)
    onMonthClick(setMonth(year, index), findSlice?.isLocked)
  }

  const {min, max} = findMinMax(data)
  return (
    <Flex flexDirection={'column'} w={'100%'} mb={'34px'}>
      <BarChart
        onClick={handleMonthClick}
        date={year}
        min={min}
        max={max}
        height={244}
        granularity={'day'}
        axisData={generateYearAxisData(year)}
        totalData={data.map((day) => day.total)}
        data={generateYearBarData(data, sliceData)}
        chartOptions={{
          seriesOptions: {
            barWidth,
            symbolSize: 18
          }
        }}
      />
    </Flex>
  )
}

export const TotalYearChart = memo(TotalYearChartRaw)

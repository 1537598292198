import {FC, useEffect, useState} from 'react'
import {FormControl, FormErrorMessage, FormLabel, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Button, IconButton, Input} from 'components'
import {verifyPasswordResetCode, confirmPasswordReset} from 'firebase/auth'
import {auth} from 'services/firebase/firebase'
import {useForm} from 'react-hook-form'
import {useNavigate, useSearchParams} from 'react-router-dom'
import {paths} from 'shared/constants/paths'

interface IProps {
  navToSignIn: () => void
}

interface IResetPasswordForm {
  password: string
  confirmPassword: string
}

const ResetPasswordScene: FC<IProps> = ({navToSignIn}) => {
  const navigate = useNavigate()
  const [searchParams, _] = useSearchParams()
  const {t} = useTranslation()
  const [email, setEmail] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const {getValues, formState, register, handleSubmit} = useForm<IResetPasswordForm>({
    mode: 'onBlur'
  })

  const validatePassword = (value: string) => {
    const password = getValues('password')
    if (password !== value) {
      return t('authorization.errors.passwordMissmatch')
    }
  }

  const onResetPassword = async (data: IResetPasswordForm) => {
    setIsLoading(true)
    const {password} = data
    try {
      const actionCode = searchParams.get('oobCode')
      if (!actionCode) {
        return
      }
      await confirmPasswordReset(auth, actionCode, password)
      navigate(paths.signIn.path)
    } catch (error) {
      console.log('error', error)
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const verifyCode = async () => {
      const actionCode = searchParams.get('oobCode')
      if (!actionCode) {
        return
      }
      try {
        const email = await verifyPasswordResetCode(auth, actionCode)
        setEmail(email)
      } catch (error) {
        console.log('error ', error)
      }
    }
    verifyCode()
  }, [])

  return (
    <>
      <IconButton
        top={'-20px'}
        left={'-10px'}
        icon={'arrowBack'}
        variant={'ghost'}
        onClick={navToSignIn}
        aria-label={'navToSignIn'}
      />
      <Text mb={'8px'} textStyle={'lg'} color={'white.1'}>
        {t('authorization.resetPassword.title')}
      </Text>
      <Text mb={'32px'} textStyle={'md'} color={'white.3'}>
        {t('authorization.resetPassword.subtitle', {value: email})}
      </Text>

      <FormControl mb={'40px'} isInvalid={!!formState.errors?.password}>
        <FormLabel mt={'32px'} mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.resetPassword.password')}
        </FormLabel>
        <Input
          isInvalid={!!formState.errors?.password}
          {...register('password', {
            required: t('authorization.errors.passwordRequired') as string,
            minLength: {value: 6, message: t('authorization.errors.passwordMinLength') as string}
          })}
          type={'password'}
          placeholder={String(t('authorization.resetPassword.passwordPlaceholder'))}
          size="md"
        />
        {!!formState.errors?.password && <FormErrorMessage>{formState.errors?.password?.message}</FormErrorMessage>}
      </FormControl>

      <FormControl mb={'40px'} isInvalid={!!formState.errors?.confirmPassword}>
        <FormLabel mt={'32px'} mb={'8px'} textStyle={'lg'} color={'white.1'}>
          {t('authorization.resetPassword.confirmPassword')}
        </FormLabel>
        <Input
          isInvalid={!!formState.errors?.confirmPassword}
          {...register('confirmPassword', {
            required: t('authorization.errors.passwordRequired') as string,
            validate: validatePassword,
            minLength: {value: 6, message: t('authorization.errors.passwordMinLength') as string}
          })}
          type={'password'}
          placeholder={String(t('authorization.resetPassword.confirmPasswordPlaceholder'))}
          size="md"
        />
        {!!formState.errors?.confirmPassword && (
          <FormErrorMessage>{formState.errors?.confirmPassword?.message}</FormErrorMessage>
        )}
      </FormControl>

      <Button isLoading={isLoading} onClick={handleSubmit(onResetPassword)} variant={'accent'}>
        {t('authorization.resetPassword.save')}
      </Button>
    </>
  )
}

export default ResetPasswordScene

import {FC} from 'react'
import {Box, Flex} from '@chakra-ui/react'

interface IProps {
  stepsAmount: number
  stepIndex: number
  onClick?: (stepIndex: number) => void
}
export const Stepper: FC<IProps> = ({stepsAmount, stepIndex, onClick}) => {
  const steps = Array(stepsAmount).fill(0)
  return (
    <Flex justifyContent={'space-between'} gap={'6px'}>
      {steps.map((_, index) => {
        return (
          <Box
            key={index}
            cursor={onClick && 'pointer'}
            onClick={() => onClick && onClick(index)}
            w={'40px'}
            h={'12px'}
            borderRadius={'30px'}
            bgColor={stepIndex === index ? 'white.3' : 'black.3'}
          />
        )
      })}
    </Flex>
  )
}

import React from 'react'
import {Box, Heading, Text} from '@chakra-ui/react'

interface IHeadingGroup {
  title: string
  subtitle: string
}

const HeadingGroup = ({title, subtitle}: IHeadingGroup) => {
  return (
    <Box textAlign={'center'} margin={'0 0 30px'}>
      <Text id={title} color={'accent.1'} fontSize={['16px', '16px', '20px']} margin={'0 0 10px'}>
        {title}
      </Text>
      <Heading as={'h2'} fontSize={['20px', '20px', '40px']} maxWidth={'640px'}>
        {subtitle}
      </Heading>
    </Box>
  )
}

export default HeadingGroup

import {useQuery} from '@tanstack/react-query'
import {wsKeys} from './ws.keys'
import {TWsMessage} from '../../types'

export const useWsMessagesQuery = (
) => {
  return useQuery<TWsMessage[]>(wsKeys.all(), () => [], {staleTime: Number.POSITIVE_INFINITY});
}

export const useWsLastMessageQuery = (
) => {
  return useQuery<TWsMessage | undefined>(wsKeys.last(), () => undefined , {staleTime: Number.POSITIVE_INFINITY});
}

import {Flex} from '@chakra-ui/react'
import {Icon} from 'components'


export const FailIcon = () => {
  return (
    <Flex
      position={'absolute'}
      top={'-8px'}
      right={'-10px'}
      bgColor={'transparent'}
      w={'12px'}
      h={'12px'}
      borderRadius={'18px'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Icon type={'close'} color={'state.negative'} />
    </Flex>
  )
}
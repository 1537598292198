import {Flex} from '@chakra-ui/react'
import {Icon} from 'components'

export const SuccessIcon = () => {
  return (
    <Flex
      position={'absolute'}
      top={'-8px'}
      right={'-10px'}
      bgColor={'transparent'}
      w={'12px'}
      h={'12px'}
      borderRadius={'14px'}
      justifyContent={'center'}
      alignItems={'center'}>
      <Icon type={'check'} color={'state.positive'} />
    </Flex>
  )
}

import {createUser, getUsers} from './test.api';
import {AxiosError, AxiosInstance} from 'axios';
import {useMutation, useQuery, useQueryClient, UseQueryOptions} from '@tanstack/react-query';
import {ITestUser} from '../../types';
import {UseMutationOptions} from '@tanstack/react-query/src/types';

export const useTestUsersQuery = (
  options: UseQueryOptions<ITestUser[]>,
  api: AxiosInstance
) => {
  return useQuery<ITestUser[]>({queryKey: ['test', 'users'], queryFn: () => getUsers(api), ...options});
};

export const useCreateTestUserMutation = (
  options: UseMutationOptions<ITestUser, AxiosError, string>,
  api: AxiosInstance
) => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (name: string) => createUser(name, api),
    onSuccess: () => {
      queryClient.invalidateQueries(['test', 'users']);
    },
    ...options
  });
};

// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app"
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, GithubAuthProvider, OAuthProvider } from 'firebase/auth'
import { getAnalytics } from "firebase/analytics"
import { getRemoteConfig } from "firebase/remote-config"

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCx2WN7Or03nyKlddANc6drs89wS9VmwoI",
  authDomain: "app.nautime.io",
  projectId: "kaef-cb3a6",
  storageBucket: "kaef-cb3a6.appspot.com",
  messagingSenderId: "669363753033",
  appId: "1:669363753033:web:8813556a87219181576f98",
  measurementId: "G-ZNFMQG39FE"
}

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const analytics = getAnalytics(app);
export const auth =  getAuth(app);
export const googleProvider = new GoogleAuthProvider();
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.email');
googleProvider.addScope('https://www.googleapis.com/auth/userinfo.profile');
export const facebookProvider = new FacebookAuthProvider();
export const githubProvider = new GithubAuthProvider();

export const appleProvider = new OAuthProvider('apple.com');
appleProvider.addScope('email');
appleProvider.addScope('name');

export const remoteConfig = getRemoteConfig(app);

remoteConfig.defaultConfig = {
  "subscriptions": false
};
import {FC, memo} from 'react'
import {Center, Flex, useToken} from '@chakra-ui/react'
import {Icon} from '../../UI'

type TSector = {
  value: number
  color: string
}

interface IProps {
  withOpacity?: boolean
  isLocked: boolean
  onClick: () => void
  data: TSector[]
}

const size = 100
const radCircumference = Math.PI * 2
const center = size / 2
const radius = center - 1 // padding to prevent clipping

function renderPaths(data: TSector[], borderColor: string) {
  const total = data.reduce((totalValue, {value}) => totalValue + value, 0)

  let radSegment = 0
  let lastX = radius
  let lastY = 0

  return data.map(({color, value}, index) => {
    // Should we just draw a circle?
    if (value === total) {
      return <circle r={radius} cx={center} cy={center} fill={color} key={index} />
    }

    if (value === 0) {
      return
    }

    const valuePercentage = value / total

    // Should the arc go the long way round?
    const longArc = valuePercentage <= 0.5 ? 0 : 1

    radSegment += valuePercentage * radCircumference
    const nextX = Math.cos(radSegment) * radius
    const nextY = Math.sin(radSegment) * radius

    // d is a string that describes the path of the slice.
    // The weirdly placed minus signs [eg, (-(lastY))] are due to the fact
    // that our calculations are for a graph with positive Y values going up,
    // but on the screen positive Y values go down.
    const d = [
      `M ${center},${center}`,
      `l ${lastX},${-lastY}`,
      `a${radius},${radius}`,
      '0',
      `${longArc},0`,
      `${nextX - lastX},${-(nextY - lastY)}`,
      'z'
    ].join(' ')

    lastX = nextX
    lastY = nextY

    return <path strokeWidth={5} stroke={borderColor} d={d} fill={color} key={index} />
  })
}

const RawSimplePie: FC<IProps> = ({withOpacity, isLocked, data, onClick}) => {
  const [borderColor] = useToken('colors', ['black.2'])
  const lockedProps = {
    borderWidth: '1px',
    borderColor: 'white.3',
    borderStyle: 'dotted',
    bg: 'linear-gradient(0deg, #FBFBFB00 0%, #FBFBFB1A 100%)'
  }
  return (
    <Flex
      onClick={onClick}
      cursor={'pointer'}
      alignItems={'center'}
      justifyContent={'center'}
      w={'52px'}
      h={'52px'}
      borderRadius={'52px'}
      p={'5px'}
      opacity={withOpacity ? 0.3 : 1}
      backgroundColor={'black.2'}>
      {isLocked && (
        <Center borderRadius={'100%'} w={'100%'} h={'100%'} {...(isLocked && lockedProps)}>
          <Icon type={'lock'} color={'white.3'} />
        </Center>
      )}
      {!isLocked &&
        <svg viewBox={`0 0 ${size} ${size}`}>
          <g transform={`rotate(-90 ${center} ${center})`}>{renderPaths(data, borderColor)}</g>
        </svg>}
    </Flex>
  )
}

export const SimplePie = memo(RawSimplePie)

import { AxiosInstance } from "axios";
import {ITestUser} from '../../types';

export const getUsers = async (
  api: AxiosInstance
): Promise<ITestUser[]> => {
  const response = await api.get("/test/users");
  return response.data;
};

export const createUser = async (
  name: string,
  api: AxiosInstance
): Promise<ITestUser> => {
  const response = await api.post("/test/users", { name });
  return response.data;
};

import { extendTheme, ThemeConfig } from '@chakra-ui/react'
import { colors as baseColors } from '@kaef/common'

const config: ThemeConfig = {
  initialColorMode: 'dark', // 'dark' | 'light'
  useSystemColorMode: false
}

const styles = {
  global: {
    // styles for the `body`
    body: {
      bg: 'black.1',
      color: 'white'
    }
  }
}

const colors = {
  ...baseColors
}

const fonts = {
  body: 'SF Compact Display, system-ui, sans-serif',
  heading: 'SF Compact Display, Georgia, serif',
  mono: 'Menlo, monospace'
}

const textStyles = {
  xl: {
    fontSize: '40px',
    fontWeight: '500'
  },
  lg: {
    fontSize: '20px',
    fontWeight: '400'
  },
  md: {
    fontSize: '16px',
    fontWeight: '400'
  },
  sm: {
    fontSize: '13px',
    fontWeight: '400'
  }
}

const modalStyles = {
  lg: {},
  md: {},
  sm: {}
}

const breakpoints = {
  sm: '440px',
  md: '730px',
  lg: '960px',
  xl: '1200px',
  '2xl': '1536px'
}

const theme = extendTheme({
  fonts,
  colors,
  config,
  styles,
  textStyles,
  breakpoints,
  components: {
    Button: {
      baseStyle: {
        fontWeight: 400,
        fontSize: 16,
        color: 'white.1',
        opacity: 0.8,
        _hover: { opacity: 1 }
      },
      variants: {
        accent: () => ({
          bg: 'accent.1',
        }),
        secondary: () => ({
          bg: 'black.5',
        }),
      },
    }
  },
})

export default theme

import { AxiosInstance } from "axios";
import {useMutation} from '@tanstack/react-query';
import {IOnboardingData} from '../../types';
import {onboarding} from './onboarding.api';

export const useOnboardingMutation = (
  options: any,
  api: AxiosInstance,
  isDemo?: boolean
) => {
  return useMutation((data: IOnboardingData) => onboarding(data, api, isDemo), options)
};


import {Box} from '@chakra-ui/react'
import {FC} from 'react'

interface IProps {
  value: number
  color: string
  withBlur?: boolean
}

export const MicroPie: FC<IProps> = ({value, color, withBlur = true}) => {
  return (
    <Box
      filter={withBlur ? 'drop-shadow(0px 0px 4px rgba(255, 255, 255, 0.5))' : ''}
      w={'16px'}
      h={'16px'}
      p={'2px'}
      borderWidth={'1px'}
      borderColor={color}
      borderRadius={'16px'}>
      <Box
        w={'10px'}
        h={'10px'}
        borderRadius={'10px'}
        bgGradient={`conic(from 0deg at 50% 50%, ${color} 0%, ${color} ${value}%, transparent ${value}%)`}
      />
    </Box>
  )
}

import {TOverviewRequest} from './overview.interface'
import {ERange} from '../../enums'

export interface IFriendResponse {
  friends: IFriend[]
}

export interface IFriendStats {
  totalSeconds: number
  totalDurationGoal: number
}

export interface IFriend {
  id: string
  name: string
  email: string
  avatar?: string
  stats?: IFriendStats
}

export interface IFriendOverviewRequest extends TOverviewRequest {
  id?: string
  limit?: number
}

export interface IFriendStatRequest extends TOverviewRequest {
  date: string
  range?: ERange
  id?: string
  limit?: number
}

export enum EFriendRequestAction {
  ACCEPT = 'ACCEPT',
  DENY = 'DENY',
  REMOVE = 'REMOVE'
}

export interface IFriendActionRequest {
  friendId: string
  action: EFriendRequestAction
}

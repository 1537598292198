import { create } from "zustand";

interface IAppState {
  isDemo: boolean;
  setIsDemo: (isDemo: boolean) => void;
}

export const useAppStore = create<IAppState>()((set) => ({
  isDemo: false,
  setIsDemo: (isDemo) => set({ isDemo }),
}));

export type TRangePriority = "GOAL" | "DAY" | "RANGE";

export interface IRangeConfig {
  startOffsetInSeconds: number;
  endOffsetInSeconds: number;
  startPriority: TRangePriority[];
  endPriority: TRangePriority[];
}

interface IConfigState {
  rangeConfig: IRangeConfig;
  setRangeConfig: (rangeConfig: IRangeConfig) => void;
}

export const useConfigStore = create<IConfigState>()((set) => ({
  rangeConfig: {
    startOffsetInSeconds: -3600,
    endOffsetInSeconds: 3600,
    startPriority: ["GOAL", "RANGE", "DAY"],
    endPriority: ["GOAL", "RANGE", "DAY"],
  },
  setRangeConfig: (rangeConfig) => set({ rangeConfig }),
}));

import ReactECharts from 'echarts-for-react'
import {FC} from 'react'
import {useToken} from '@chakra-ui/react'
import {ANIMATION_ECHART_DURATION, secondsToString} from '@kaef/common/utils/helpers'
import {TBarData} from '@kaef/common/types'
import './tooltip.css'
import {addDays, addMonths, format} from 'date-fns'

const lockIcon = 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAJ2SURBVHgB7VaxbhNBEJ3ZtRuMhCtajg9AcSraWPTIlFSkSYEQsq5IJHd0lpJiI6EoDZJJldaih5AvsJGokWmpTiIOEvbOMJecfcbs5faOIFL4Nd69nZ15u555swAr/GcglMDu7kEDtG0wcRDPlcIICIfb2y8/QkEUImDMQWNqyTDwRoa3EVj9ZGfnxRA84U3AmMNgSpMBM9RznTI2fW+jAp6Y0OQEkuCIEDGrPrP9ejlXaxK1IetBPGfFPfm57+PX6wb29l5vMPJJMo2stuudMBwt2nSNCSpWDxgSkp63oMALcrqUcn85eIz4m+TG0XwHUgs84EVATh/MxwSfMg0RF5IP78B1EQBU92ZDjTjKNKN0DQED8IBXEqIkHydjQq53u4eB0w6nczuA/Gq52HPVojG9+pTO2vLfbs4yvAAi8R5Wle2HYRhBUQJx3SelF8DfQMSpqqrNMHw+ci87g/fqE/o+cAT/Brngmrit/UnCrrtuwpkDEryVBucxAx6L8LyXDBhDLuIzqRoqeIhsty7IiK+fU70pC/vL1s4qEBF5NhsTqDciv+/8gs93jZnog3g6Tmm5+0dWGdYXyHyBkiDAz2kkXANfAiI8cwLE9hxKQsr3LM/GU4r/HVYEcgko1LegJBjh9mwsyRwVIJB2NUR6ACWhF7ooxNLsSwCJTxemTxXyI9CqBt6RVU32PGbmrdknqawjl6lTCSsV+3ZCup2oYSytbUXUlnsUMcJ8QSK6+5vKixSfS1NymWY2o/iJpUlfSzOyyjZdr6jL5StgjJGmpFuxNGc+xV1O5dEqdz4kptMfFdp/VaYdu5D1EFlGp+NuvSvcSPwC3cn51WfNNw8AAAAASUVORK5CYII='

const generateSeries = (data: TBarData[], seriesOptions?: any) => {
  return data.map((item) => ({
    id: item.id,
    name: item.name,
    type: 'bar',
    stack: 'total',
    animationDuration: ANIMATION_ECHART_DURATION,
    animationUpdateDuration: ANIMATION_ECHART_DURATION,
    color: item.color,
    barWidth: 58,

    itemStyle: {
      color: item.isLocked && {
        type: 'linear',
        x: 0,
        y: 0,
        x2: 0,
        y2: 1,
        colorStops: [{
          offset: 0, color: '#FBFBFB1A' // color at 0%
        }, {
          offset: 1, color: '#FBFBFB00' // color at 100%
        }]
      },
      borderType: item.isLocked && 'dotted',
      borderWidth: 1,
      borderRadius: item.isLocked ? 0 : 1,
      borderColor: item.isLocked ? '#8A8C92' : 'transparent'
    },
    label: {
      show: false
    },
    emphasis: {
      focus: 'none'
    },
    data: item.data,
    ...seriesOptions
  }))
}

const generateTotalLine = (
  data: TBarData[],
  totalData: number[] | undefined,
  granularity: TGranularity,
  max: number,
  color: string,
  showLabel: boolean = true,
  symbolSize: number = 14
) => {
  let resultData: {value: number | null, symbol?: string, symbolOffset?: number[], symbolSize?: number}[] | undefined
  data.forEach((item) => {
    if (!resultData) {
      resultData = item.data.map(value => ({
        value,
        ...(item.isLocked && value > 0 && {symbol: lockIcon, symbolOffset: [0, -10]})
      }))
    } else {
      item.data.forEach((itemValue, index) => {
        resultData![index].value! += itemValue
        if (item.isLocked && itemValue > 0 && resultData![index].value === itemValue) {
          resultData![index].symbol = lockIcon
          resultData![index].symbolOffset = [0, -10]
        }
        if (!item.isLocked && itemValue > 0) {
          delete resultData![index].symbol
          delete resultData![index].symbolOffset
        }
      })
    }
  })
  resultData = resultData?.map(item => ({
    ...item,
    value: item.value === 0 ? null : item.value,
    symbolSize: item.value! > 0 ? symbolSize : 0
  }))
  let markLineValues: number[] = []
  const hoursInMax = Math.floor(max / 3600)
  const maxHoursRounded = hoursInMax + 1
  let hoursStep = 1
  if (maxHoursRounded > 9) {
    hoursStep = Math.floor(maxHoursRounded / 3)
  } else if (maxHoursRounded > 4) {
    hoursStep = 2
  }
  for (let i = maxHoursRounded; i > 0; i = i - hoursStep) {
    markLineValues.push(i * 3600)
  }
  resultData && resultData.push({value: maxHoursRounded * 3600})

  return {
    name: '_total_chart_bar',
    type: 'line',
    lineStyle: {
      opacity: 0
    },
    itemStyle: {
      color: 'transparent'
    },
    emphasis: {
      focus: 'none'
    },
    label: {
      show: showLabel,
      fontSize: 13,
      width: 60,
      overflow: 'break',
      align: 'center',
      distance: 0,
      color,
      formatter: (data: {data: {symbol: string}, dataIndex: number}) => {
        if (!totalData || !totalData[data.dataIndex] || data.data.symbol) {
          return ''
        }
        return `${totalData && secondsToString(totalData[data.dataIndex], {withMinutes: granularity !== 'day'})}`
      }
    },
    markLine: {
      animationDuration: 0,
      lineStyle: {
        type: 'dotted',
        color,
        opacity: 0.2
      },
      symbol: 'none',
      label: {
        show: true,
        color,
        formatter: (line: any) => `${secondsToString(line.value, {withMinutes: false})}`,
        opacity: 1
      },
      emphasis: {
        disabled: true
      },
      data: markLineValues.map((value) => ({yAxis: value}))
    },
    data: resultData
  }
}

const generateChartOption = (
  data: TBarData[],
  axisData: string[],
  totalData: number[] | undefined,
  granularity: TGranularity,
  max: number,
  color: string,
  date: Date,
  chartOptions?: {
    showLabel?: boolean
    yAxis?: any
    xAxis?: any
    seriesOptions?: any
  }
) => {
  const series = generateSeries(data, chartOptions?.seriesOptions)
  const totalLine = generateTotalLine(data, totalData, granularity, max, color, chartOptions?.showLabel, chartOptions?.seriesOptions?.symbolSize)
  return {
    backgroundColor: 'transparent',
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow'
      },
      padding: 0,
      backgroundColor: 'transparent',
      borderColor: 'transparent',
      formatter: (rawSeries: any) => {
        const series = rawSeries.filter((item: any) => item.seriesName !== '_total_chart_bar' && item.value)
        if (series.length === 0) {
          return null
        }
        const seriesHtml = series.reverse().reduce((html: string, item: any) => {
          html += `<div class='tooltip-item-container'>
                    <div class='tooltip-item-title'>
                      <div class='tooltip-circle' style='background-color: ${item.color}'></div>
                      <span class='tooltip-text'>${item.seriesName}</span>
                    </div>
                    <span class='tooltip-text'>
                      ${secondsToString(item.value)}
                    </span>
                  </div>`
          return html
        }, '')
        const formatString = granularity === 'day' ? 'yyyy-MM' : 'yyyy-MM-dd'
        const addFunc = granularity === 'day' ? addMonths : addDays
        const dateHtml = `<div class='tooltip-item-container'><span class='tooltip-text'>
                      ${format(addFunc(date, Number(series[0].dataIndex)), formatString)}
                    </span></div>`
        return `<div class='tooltip-container'>${dateHtml}${seriesHtml}</div>`
      }
    },
    grid: {
      right: 36,
      left: 0,
      top: 35,
      bottom: 28
    },
    xAxis: {
      type: 'category',
      axisLabel: {
        color,
        margin: 12,
        ...chartOptions?.xAxis?.axisLabel
      },
      axisLine: {
        show: false
      },
      axisTick: {
        show: false
      },
      data: axisData,
      ...chartOptions?.xAxis
    },
    yAxis: {
      type: 'value',
      show: true,
      position: 'right',
      max: 'dataMax',
      axisLabel: {
        show: false
      },
      splitLine: {
        show: false
      },
      axisLine: {
        show: false
      },
      ...chartOptions?.yAxis
    },
    series: [...series, totalLine]
  }
}

type TGranularity = 'day' | 'hour'

interface IProps {
  date: Date
  height: number
  granularity: TGranularity
  totalData?: number[]
  axisData: string[]
  data: TBarData[]
  min: number
  max: number
  onClick?: (date: number, seriesId: string) => void
  chartOptions?: {
    showLabel?: boolean
    yAxis?: any
    xAxis?: any
    seriesOptions?: any
  }
}

export const BarChart: FC<IProps> = ({
                                       onClick,
                                       date,
                                       totalData,
                                       height,
                                       axisData,
                                       granularity,
                                       data,
                                       min,
                                       max,
                                       chartOptions
                                     }) => {
  const [textColor] = useToken('colors', ['white.3'])
  const _onClick = (event: any) => {
    if (event.componentType === 'series') {
      onClick && onClick(event.dataIndex, event.seriesId)
    }
  }
  const option = generateChartOption(data, axisData, totalData, granularity, max, textColor, date, chartOptions)
  return <ReactECharts option={option} theme={'dark'} style={{height, width: '100%'}} onEvents={{click: _onClick}} />
}

import React, {FC, useState} from 'react'
import {Flex, Text} from '@chakra-ui/react'
import {Button, PluginIcon} from 'components'
import {useTranslation} from 'react-i18next'
import {WakatimeImport} from 'components/Modals/Instructions/WakatimeImport'

interface IProps {
  onNextStep: () => void
}
export const ImportData: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [showInstruction, setShowInstruction] = useState(false)

  const onNext = () => {
    onNextStep()
  }

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {showInstruction && <WakatimeImport source={'onboarding'} />}
      {!showInstruction && (
        <>
          <Text mb={'20px'} textStyle={'lg'} color={'white.1'}>
            {t('onboarding.import.title')}
          </Text>
          <Text mb={'20px'} textStyle={'md'} color={'white.3'}>
            {t('onboarding.import.subtitle')}
          </Text>
          <Flex alignItems={'center'} justifyContent={'center'} w={'100%'} maxW={'650px'} gap={'12px'}>
            <Flex
              flexDirection={'column'}
              borderRadius={'8px'}
              borderWidth={'1px'}
              cursor={'pointer'}
              borderColor={'black.5-40'}
              backgroundColor={'white.1-05'}
              display={'flex'}
              p={'24px 0'}
              key={'Wakatime'}
              w={'calc(35% )'}
              onClick={() => setShowInstruction(true)}
              _hover={{
                borderColor: 'accent.1',
                backgroundColor: 'accent.1-05'
              }}
              justifyContent={'center'}
              alignItems={'center'}>
              <PluginIcon type={'wakatime'} width={'40px'} height={'40px'} />
              <Text textStyle={'md'} mt={'8px'} textAlign={'center'}>
                Wakatime Import Instructions
              </Text>
            </Flex>
          </Flex>
        </>
      )}
      <Flex w={'100%'} mt={'20px'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.import.next')}
        </Button>
      </Flex>
    </Flex>
  )
}

import { AxiosInstance } from "axios";
import { goals } from "./goals.mock";
import {IGoal, IOverviewGoal} from '../../types'

export const getGoals = async (api: AxiosInstance, isDemo?: boolean) => {
  if (isDemo) {
    return goals;
  }
  const response = await api.get("/user/goals");
  return response.data?.goals || [];
};

export const saveGoal = async (data: IGoal, api: AxiosInstance): Promise<IOverviewGoal[]> => {
  const response = await api.post("/user/goals", data);
  return response.data?.goals || []
};

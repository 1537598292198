import {FC} from 'react'
import {
  Modal as ChakraModal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  ModalProps
} from '@chakra-ui/react'

interface IProps extends Omit<ModalProps, 'isOpen' | 'onClose'> {
  isOpen?: boolean
  onClose?: () => void
  withoutClose?: boolean
  withoutBackdropClose?: boolean
  minHeight?: string
}

export const Modal: FC<IProps> = ({
                                    minHeight, children, isOpen = true, withoutClose, onClose = () => {
  }, ...props
                                  }) => {
  return (
    <ChakraModal motionPreset='slideInRight' size={'lg'} isOpen={isOpen} onClose={onClose} {...props}>
      <ModalOverlay />
      <ModalContent borderRadius={'4px'} borderWidth={'1px'} borderColor={'black.3'} bgColor={'black.2'} p={'24px'}>
        {!withoutClose && <ModalCloseButton color={'white.1'} />}
        <ModalBody flexDirection={'column'} p={'0px'} minHeight={minHeight}>
          {children}
        </ModalBody>
      </ModalContent>
    </ChakraModal>
  )
}

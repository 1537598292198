import { AxiosInstance } from "axios"
import {useMutation, useQuery} from '@tanstack/react-query'
import {notificationsKeys} from './notifications.keys'
import {getNotifications, markAsRead, markAsReadAll} from './notifications.api'

export const useNotificationsQuery = (
  options: any,
  api: AxiosInstance,
) => {
  return useQuery(notificationsKeys.all(), () => getNotifications(api), options);
}

export const useMarkNotificationAsReadMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any, string[]>((data: string[]) => markAsRead(data, api), options)
}

export const useMarkAllNotificationAsReadMutation = (
  options: any,
  api: AxiosInstance
) => {
  return useMutation<void, any, void>(() => markAsReadAll(api), options)
}
import {Center, CircularProgress, Flex, Image, Skeleton, Text} from '@chakra-ui/react'
import {FC, useCallback, useEffect} from 'react'
import {endOfDay, startOfDay} from 'date-fns'
import {ButtonGroup, IconButton, Stat} from 'components'
import {useOverviewStore, useWeekSliceStore} from 'stores'
import {api} from 'services/api'
import {SliceDataList} from '../SliceDataList/SliceDataList'
import {TotalWeekChart} from './components/TotalWeekChart'
import {AverageWeekChart} from './components/AverageWeekChart'
import {paths} from 'shared/constants/paths'
import {useLocation, useNavigate} from 'react-router-dom'
import {useTranslation} from 'react-i18next'
import {linkParamsToUrl, parseISOToUTC, prepareOverviewRequestData, secondsToString} from '@kaef/common/utils/helpers'
import {goalSelector} from '@kaef/common/api/goals/goals.selector'
import {secondaryGroupContainerProps} from '../utils'
import {
  logChangeOverview,
  logChangeSlice,
  logOpenChart,
  logOpenDay,
  logOpenPaywall,
  logProjectOpenDetails,
  logProjectToggleVisibility
} from 'services/analytics/analytics'
import {ERange, ILinkParams, ISliceData} from '@kaef/common/types'
import {useInitFromUrlParams} from 'shared/hooks/useInitFromUrlParams'
import {overviewKeys} from '@kaef/common/api/overview/overview.keys'
import {projectsKeys} from '@kaef/common/api/projects/projects.keys'
import {useQueryClient} from '@tanstack/react-query'
import Monitor from 'assets/monitor.png'

interface IProps {
  projectId: string | null
}

const WeekLayout: FC<IProps> = ({projectId}) => {
  const {t} = useTranslation()
  const navigate = useNavigate()
  const location = useLocation()
  const queryClient = useQueryClient()

  const week = useOverviewStore((state) => state.week)
  const setDay = useOverviewStore((state) => state.setDay)

  const [slice, setSlice, chart, setChart] = useWeekSliceStore((state) => [
    state.slice,
    state.setSlice,
    state.chart,
    state.setChart
  ])

  const {isLoading, isError, data} = api.useOverviewWeekQuery(
    prepareOverviewRequestData(week, ERange.WEEK, projectId),
    {}
  )
  const goalQuery = api.useGoalsQuery()
  const projectsQuery = api.useProjectsQuery()
  const saveProjectMutation = api.useSaveProjectMutation()
  const userQuery = api.useUserInfoQuery()

  const sliceKey = useInitFromUrlParams(ERange.WEEK, setChart)

  const isNoPlugins = !isLoading && userQuery.data?.plugins.length === 0

  const onChangeSlice = (value: string) => {
    logChangeSlice(value, 'week')
    logChangeOverview('week', chart, value)
    const findSlice = data?.slices.find((slice) => slice.type === value)
    if (findSlice) {
      setSlice(findSlice)
    }
  }

  const onDayClick = useCallback(
    (day: Date, isLocked?: boolean) => {
      if (isLocked) {
        logOpenPaywall('week_day_click')
        navigate(paths.paywall.path, {state: {backgroundLocation: location}})
        return
      }
      setDay(day)
      logOpenDay(chart)
      navigate(`${paths.overview.path}/day/${projectId || ''}`)
    },
    [navigate, setDay, projectId, chart]
  )

  const onOpenWeekTotal = () => {
    setChart('WEEK_TOTAL')
    logChangeOverview('week', 'WEEK_TOTAL', slice?.type)
    logOpenChart('WEEK_TOTAL')
  }

  const onOpenWeekAverage = () => {
    setChart('WEEK_AVERAGE')
    logChangeOverview('week', 'WEEK_AVERAGE', slice?.type)
    logOpenChart('WEEK_AVERAGE')
  }

  const onEditProject = (data: ISliceData) => {
    navigate(`${paths.editProject.path}/${data.uuid}`, {state: {backgroundLocation: location}})
  }

  const onNavToSliceDetails = (linkParams: ILinkParams, isLocked?: boolean) => {
    if (isLocked) {
      logOpenPaywall('week_slice_details')
      navigate(paths.paywall.path, {state: {backgroundLocation: location}})
      return
    }
    logProjectOpenDetails()
    navigate(`${paths.overview.path}/week?${linkParamsToUrl(linkParams)}`)
  }

  const onToggleVisibility = (data: ISliceData) => {
    if (!projectsQuery.data) {
      return
    }
    const findProject = projectsQuery.data.find((item) => item.id === data.uuid)
    if (findProject) {
      logProjectToggleVisibility()
      saveProjectMutation.mutate(
        {
          ...findProject,
          show: !findProject.show
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(overviewKeys.all())
            queryClient.invalidateQueries(projectsKeys.all())
          }
        }
      )
    }
  }

  useEffect(() => {
    if (!data?.slices) {
      return
    }
    if (slice && data?.slices.find((item) => item.type === slice.type)) {
      return
    }
    if (!sliceKey) {
      setSlice(data?.slices[0])
      return
    }
    const findSlice = data?.slices.find((slice) => slice.type === sliceKey)
    if (findSlice) {
      setSlice(findSlice)
    } else {
      setSlice(data?.slices[0])
    }
  }, [data])

  const currentSlice = data?.slices?.find((_slice) => _slice.type === slice?.type)

  const slices = data?.slices?.map((slice) => ({
    label: slice.title,
    value: slice.type
  }))

  const start = currentSlice ? parseISOToUTC(currentSlice.start) : startOfDay(week)
  const end = currentSlice ? parseISOToUTC(currentSlice.end) : endOfDay(week)

  const goalStart = goalSelector('START')(goalQuery.data)
  const goalEnd = goalSelector('END')(goalQuery.data)

  const {total, average} = data || {total: 0, average: 0}

  const max = currentSlice?.data.reduce((acc, item) => (item.seconds > acc ? item.seconds : acc), 0)

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      {isError && <Flex>Request Error</Flex>}
      {!isError && isNoPlugins && (
        <Flex alignItems={'center'} flexDirection={'column'}>
          <Image w={'210px'} mt={'10px'} mb={'10px'} src={Monitor} />
          <Text mt={'20px'} color={'white.1'} textStyle={'md'}>
            {t('overview.noPlugin')}
          </Text>
        </Flex>
      )}
      {!isError && !isNoPlugins && (
        <>
          {isLoading && (
            <Center pb={'42px'} h={'398px'} w={'100%'}>
              <CircularProgress isIndeterminate color={'accent.1'} />
            </Center>
          )}
          {!isLoading && (
            <Flex w={'100%'} flexDirection={['column-reverse', 'column-reverse', 'row']}>
              <Flex w={'100%'} mt={'15px'} mr={'40px'}>
                {chart === 'WEEK_AVERAGE' && (
                  <AverageWeekChart
                    onDayClick={onDayClick}
                    goalEnd={goalEnd}
                    goalStart={goalStart}
                    week={week}
                    rangeStart={start}
                    rangeEnd={end}
                    data={currentSlice?.byDays}
                    average={data?.averageList}
                    sliceData={currentSlice?.items}
                  />
                )}
                {chart === 'WEEK_TOTAL' && (
                  <TotalWeekChart
                    onDayClick={onDayClick}
                    week={week}
                    data={currentSlice?.byDays}
                    sliceData={currentSlice?.items}
                  />
                )}
              </Flex>
              <Flex
                flexDirection={['row', 'row', 'column']}
                justifyContent={['space-between', 'space-between', 'flex-start']}
                w={['100%', '100%', '160px']}
                flexShrink={0}
                mt={'16px'}>
                <Flex mb={'40px'}>
                  <IconButton
                    minWidth={'32px'}
                    w={'32px'}
                    h={'32px'}
                    mr={'6px'}
                    icon={'graph2'}
                    variant={chart === 'WEEK_AVERAGE' ? 'accent' : 'ghost'}
                    onClick={onOpenWeekAverage}
                    aria-label={'week-day-average'}
                  />
                  <IconButton
                    minWidth={'32px'}
                    w={'32px'}
                    h={'32px'}
                    icon={'graph1'}
                    variant={chart === 'WEEK_TOTAL' ? 'accent' : 'ghost'}
                    onClick={onOpenWeekTotal}
                    aria-label={'week-day-total'}
                  />
                </Flex>
                <Flex flexDirection={['row', 'row', 'column']} gap={'12px'}>
                  <Stat value={secondsToString(Math.round(total))} title={t('overview.week.total')} />
                  <Stat value={secondsToString(Math.round(average))} title={t('overview.week.avg')} />
                </Flex>
              </Flex>
            </Flex>
          )}
          <Flex justifyContent={'center'}>
            {isLoading ? (
              <Skeleton borderRadius={'8px'} w={'100%'} h={'48px'} />
            ) : (
              <ButtonGroup
                containerProps={secondaryGroupContainerProps}
                variant={'secondary'}
                options={slices}
                value={slice?.type}
                onChange={onChangeSlice}
              />
            )}
          </Flex>
          {!isLoading && !isError && total === 0 && (
            <Flex mt={'40px'} mb={'40px'} w={'100%'} flexDirection={'column'} alignItems={'center'}>
              <Text textStyle={'md'} mb={'4px'}>
                {t('overview.noActivityTitle')}
              </Text>
              <Text textAlign={'center'} maxW={'280px'} textStyle={'sm'} color={'white.3'}>
                {t('overview.noActivityDescription')}
              </Text>
            </Flex>
          )}
          <SliceDataList
            onToggleVisibility={onToggleVisibility}
            onEditProject={onEditProject}
            withToolkit={currentSlice?.type === 'PROJECT'}
            withEmoji={currentSlice?.type === 'PROJECT'}
            data={currentSlice?.items}
            isLoading={isLoading}
            onClick={onNavToSliceDetails}
          />
        </>
      )}
    </Flex>
  )
}

export default WeekLayout

import {ERange} from '@kaef/common/types'
import {differenceInCalendarDays, subDays} from 'date-fns'

export const useIsPrevRangeLocked = (range: ERange, date: Date, freeDaysPeriod: number) => {
  switch (range) {
    case ERange.DAY:
      return differenceInCalendarDays(new Date(), subDays(date, 1)) > freeDaysPeriod
    case ERange.WEEK:
      return differenceInCalendarDays(new Date(), subDays(date, 1)) > freeDaysPeriod
    case ERange.MONTH:
      return differenceInCalendarDays(new Date(), subDays(date, 1)) > freeDaysPeriod
    case ERange.YEAR:
      return differenceInCalendarDays(new Date(), subDays(date, 1)) > freeDaysPeriod
    default:
      return false
  }
}

import {EGoalResult, TDayType} from './overview.interface'

export enum EShareType {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum ESharePlatform {
  TWITTER = 'twitter',
  TELEGRAM = 'telegram',
  FACEBOOK = 'facebook',
  LINKEDIN = 'linkedin',
}

export interface IShareLinkRequest {
  projectUid?: string
  type: EShareType
  date: string
}

export interface IShareLink {
  text: string
  shareUrl: string
  imageUrl: string
}

export interface IShareDataRequest {
  userUid: string
  projectUid?: string
  type: EShareType
  date: string
}

export type IShareData = IDayShare | IWeekShare | IMonthShare | IYearShare

export interface IDayShare {
  data: {
    day: string
    total: number
    start: string
    end: string
    dayType: TDayType
  }
  type: EShareType.DAY
  durationGoal: number
}

export interface IDayInPreviewShare {
  day: string
  total: number
  result: EGoalResult
  dayType: TDayType
}

export interface IWeekShare {
  type: EShareType.WEEK
  data: {
    total: number
    average: number
    byDays: IDayInPreviewShare[]
  }
  durationGoal: number
}

export interface IMonthShare {
  type: EShareType.MONTH
  data: {
    total: number
    average: number
    byDays: IDayInPreviewShare[]
  }
  durationGoal: number
}

export interface IMonthInYearPreviewShare {
  month: string
  total: number
  average: number
}

export interface IYearShare {
  type: EShareType.YEAR
  data: {
    total: number
    average: number
    byMonth: IMonthInYearPreviewShare[]
  }
  durationGoal: number
}

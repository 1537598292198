import React, {FC} from 'react'
import {GridItem, Heading, ListItem, Text, UnorderedList} from '@chakra-ui/react'

interface IProps {}

export const TermsScene: FC<IProps> = ({}) => {
  return (
    <GridItem
      area={'main'}
      display={'flex'}
      flexDirection={'column'}
      gridGap={'25px'}
      justifyContent={'start'}
      alignItems={'flex-start'}>
      <Heading as={'h2'} fontSize={['20px', '20px', '40px']} maxWidth={'640px'}>
        Terms of Service Agreement
      </Heading>
      <Text textStyle={'lg'} color={'white.3'}>
        THIS TERMS OF SERVICE AGREEMENT ("Agreement") sets forth the terms and conditions for all users ("you" or
        "User") of the site and services provided by Nau Time Tracker ("we", "us", or "our"). By accessing or using our
        site and services, you acknowledge that you have read, understood, and agreed to be bound by this Agreement, as
        well as all applicable laws and regulations. If you do not accept these terms in full, you are not authorized to
        use our site and services. We reserve the right to modify, alter, supplement, or update this Agreement at any
        time. Continued use of our site and services after any such changes constitutes your consent to such changes. We
        encourage you to review this Agreement regularly for updates. The last modification date is noted at the top of
        this document.{' '}
      </Text>
      <Text textStyle={'lg'}>Use of Site and Services</Text>
      <Text textStyle={'md'} color={'white.3'}>
        The term "Site" refers to the Nau Time Tracker website located at https://nautime.io, including all plug-ins,
        extensions, downloadable software, mobile, and tablet applications. The term "Service" encompasses the access
        and use of web pages, mobile application pages, plug-ins, extensions, data, analyses, reports, documentation,
        and other information accessible through the Site, including all updates, enhancements, and modifications
        thereof, and the intellectual property contained therein. Under this Agreement, Nau Time Tracker grants each
        User a limited, revocable, non-exclusive, non-transferable license to access and use the Site and Service. We
        reserve the right to restrict, terminate, or modify your access to the Site or Service without notice at any
        time for any reason.
      </Text>
      <Text textStyle={'lg'}>Your Nau Time Tracker Account</Text>
      <Text textStyle={'md'} color={'white.3'}>
        This Agreement does not transfer any Nau Time Tracker or third-party intellectual property rights to you. All
        rights, titles, and interests in such properties remain solely with Nau Time Tracker. The Nau Time Tracker logo,
        and all other trademarks, service marks, graphics, and logos used in connection with Nau Time Tracker, are
        trademarks or registered trademarks of Nau Time Tracker or Nau Time Tracker's licensors. Your use of the Site or
        Service does not grant you any right or license to reproduce or otherwise use any Nau Time Tracker or
        third-party trademarks.
      </Text>
      <Text textStyle={'lg'}>User Responsibilities</Text>
      <Text textStyle={'md'} color={'white.3'}>
        You agree to use the Site and Service solely for lawful purposes. You must not use the Site and Service in a way
        that could damage, disable, or overburden our servers or networks or interfere with any other party's use and
        enjoyment of the Site and Service. You agree to immediately notify Nau Time Tracker of any unauthorized Service
        access or security breaches. Specifically, you agree not to:
        <UnorderedList>
          <ListItem>
            Misuse, copy, install, transfer, or distribute the Site and/or Service, except as permitted by this
            Agreement;
          </ListItem>
          <ListItem>
            Conduct unauthorized monitoring by installing the Service on someone else’s device without their knowledge;
          </ListItem>
          <ListItem>
            Alter or remove any proprietary rights notices on the Site or obtained through the Service;
          </ListItem>
          <ListItem>Create accounts through unauthorized means, including automated or under false pretenses;</ListItem>
          <ListItem>
            Misrepresent your affiliation with us or transmit illegal or harmful content through the Service.
          </ListItem>
        </UnorderedList>
      </Text>
      <Text textStyle={'lg'}>Disclaimer of Warranties</Text>
      <Text textStyle={'md'} color={'white.3'}>
        The Site is provided "as is". Nau Time Tracker and its suppliers and licensors disclaim all warranties,
        expressed or implied, including, without limitation, those of merchantability, fitness for a particular purpose,
        and non-infringement. You bear the entire risk as to the quality and performance of the Site, and Nau Time
        Tracker does not guarantee uninterrupted or error-free access to the Site.{' '}
      </Text>
      <Text textStyle={'lg'}>Limitation of Liability</Text>
      <Text textStyle={'md'} color={'white.3'}>
        Nau Time Tracker, nor its suppliers or licensors, will be liable for any indirect, special, incidental,
        consequential damages, or losses arising out of this Agreement or your use of the Service, beyond the amount
        paid by you to us under this Agreement.{' '}
      </Text>
      <Text textStyle={'lg'}>Indemnification</Text>
      <Text textStyle={'md'} color={'white.3'}>
        You agree to indemnify and hold harmless Nau Time Tracker, its contractors, licensors, and their respective
        directors, officers, employees, and agents from any claim or demand made by any third party due to or arising
        out of your use of the Service or violation of this Agreement.{' '}
      </Text>
      <Text textStyle={'lg'}>Termination</Text>
      <Text textStyle={'md'} color={'white.3'}>
        Nau Time Tracker may terminate your access to the Service at any time, with or without notice, for any reason.
        You may terminate this Agreement by discontinuing use of the Service. Upon termination, you must cease all use
        of the Site and Service.
      </Text>
    </GridItem>
  )
}

export default TermsScene

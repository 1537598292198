type TMap = {
  [key: string]: string;
};

export class EmojiPalette {
  private emojis: string[];

  private emojiProjectMap: TMap = {};

  constructor(emojis: string[]) {
    this.emojis = emojis;
  }

  public getProjectEmoji(id: string | undefined) {
    if (!id) {
      return ''
    }
    if (this.emojiProjectMap[id]) {
      return this.emojiProjectMap[id];
    }
    this.emojiProjectMap[id] = this.emojis.pop() || "💻";
    return this.emojiProjectMap[id];
  }
}

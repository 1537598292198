import {FC} from 'react'
import {Flex} from '@chakra-ui/react'
import {ConfigPanel} from './components/ConfigPanel/ConfigPanel'
import {TodayGoals} from './components/TodayGoals/TodayGoals'
import {api} from 'services/api'
import {WeekPlan} from './components/WeekPlan/WeekPlan'
import {FriendsLeaderboard} from 'components'
import {useParams} from 'react-router-dom'
import {ERange} from '@kaef/common/types'
import {overviewDateByRangeSelector, useOverviewStore} from 'stores'

interface IOverviewSidebarProps {}

const OverviewSidebar: FC<IOverviewSidebarProps> = () => {
  const {data: user} = api.useUserInfoQuery()

  const {range: rawRange} = useParams()

  const range = rawRange?.toLowerCase() as ERange
  const overviewState = useOverviewStore((state) => state)
  const date = overviewDateByRangeSelector(range, overviewState)

  return (
    <Flex flexDirection={'column'} w={'100%'} gap={'24px'}>
      <WeekPlan />
      <TodayGoals />
      <FriendsLeaderboard range={range} date={date} />
      {user?.features?.includes('DEV') && <ConfigPanel />}
    </Flex>
  )
}

export default OverviewSidebar

import {Flex, Text} from '@chakra-ui/react'
import {Button} from '../Button/Button'
import React, {FC} from 'react'
import {Modal} from '../Modal/Modal'

interface IProps {
  title: string
  description?: string
  isOpen: boolean
  onConfirm: () => void
  onCancel: () => void
  confirmText?: string
  cancelText?: string
  negativeConfirm?: boolean
}

export const Alert: FC<IProps> = ({
  negativeConfirm,
  title,
  isOpen,
  description,
  cancelText,
  onCancel,
  confirmText,
  onConfirm
}) => {
  return (
    <Modal size={'md'} isOpen={isOpen} onClose={onCancel}>
      <Flex direction={'column'} gap={'12px'}>
        <Text textStyle={'lg'} mt={'10px'}>
          {title}
        </Text>
        {description && <Text textStyle={'md'}>{description}</Text>}
        <Flex justifyContent={'flex-end'} gap={'12px'} mt={'20px'}>
          <Button w={'160px'} variant={'secondary'} onClick={onCancel}>
            {cancelText || 'Cancel'}
          </Button>
          <Button w={'160px'} variant={negativeConfirm ? 'negative' : 'secondary'} onClick={onConfirm}>
            {confirmText || 'Confirm'}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

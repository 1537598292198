import React from 'react'
import {Flex, Menu, MenuButton, MenuItem, MenuList, Text} from '@chakra-ui/react'
import {Avatar, Icon} from 'components'
import {paths} from 'shared/constants/paths'
import {signOut} from 'firebase/auth'
import {auth} from 'services/firebase/firebase'
import {useAppStore, useAuthStore} from 'stores'
import {useLocation, useNavigate} from 'react-router-dom'
import {api} from 'services/api'

const UserMenu = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const setAfterAuthRedirect = useAuthStore((state) => state.setAfterAuthRedirect)
  const setIsForceSignOut = useAuthStore((state) => state.setIsForceSignOut)
  const isDemo = useAppStore((state) => state.isDemo)
  const {data: userData} = api.useUserInfoQuery({})
  const onSignOut = async () => {
    setAfterAuthRedirect(paths.home.path)
    await signOut(auth)
    if (isDemo) {
      setIsForceSignOut(true)
    }
    navigate(paths.home.path, {state: {}})
  }

  return (
    <Menu size={'100%'}>
      <MenuButton
        as={Flex}
        alignItems={'center'}
        _hover={{
          bg: 'black.4',
          cursor: 'pointer'
        }}
        borderRadius="4px">
        <Flex alignItems={'center'}>
          <Avatar name={userData?.name} />
          <Icon width={16} height={16} type="arrowDown" />
        </Flex>
      </MenuButton>
      <MenuList bg={'black.2'} borderColor={'transparent'} borderRadius={'4px'}>
        <MenuItem
          padding={'8px 10px 0'}
          bg={'black.2'}
          _hover={{bg: 'black.3'}}
          gridGap={'0 5px'}
          onClick={() => {
            navigate(paths.profile.path, {state: {backgroundLocation: location}})
          }}>
          <Flex padding={'0 0 10px'} borderBottom={'1px solid'} borderColor={'black.4'} width={'100%'}>
            <Icon type="profile" />
            <Text padding={'0 0 0 4px'}>Profile</Text>
          </Flex>
        </MenuItem>
        <MenuItem padding={'8px 10px 0'} bg={'black.2'} _hover={{bg: 'black.3'}} gridGap={'0 5px'} onClick={onSignOut}>
          <Flex padding={'0 0 10px'} width={'100%'}>
            <Icon type="logout" />
            <Text padding={'0 0 0 4px'}>Sign out</Text>
          </Flex>
        </MenuItem>
      </MenuList>
    </Menu>
  )
}

export default UserMenu

import {Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Select, Spinner, Text} from '@chakra-ui/react'
import {api} from 'services/api'
import {useTestStore} from 'stores/test/test.store'
import {ChangeEvent, FC} from 'react'
import {useQueryClient} from '@tanstack/react-query'

interface IProps {
  isShow: boolean
  onClose: () => void
}

export const TestUsersModal: FC<IProps> = ({isShow, onClose}) => {
  const [userToken, setUserToken] = useTestStore((state) => [state.userToken, state.setUserToken] as const)

  const queryClient = useQueryClient()

  const testUsersQuery = api.useTestUsersQuery({
    enabled: isShow
  })

  const onChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setUserToken(event.target.value)
    api.api.defaults.headers['X-TEST-USER'] = event.target.value
    api.invalidateAll(queryClient)
  }

  return (
    <Modal motionPreset="slideInRight" size={'lg'} isOpen={isShow} onClose={onClose}>
      <ModalOverlay onClick={onClose} />
      <ModalContent borderRadius={'4px'} borderWidth={'1px'} borderColor={'black.3'} bgColor={'black.2'} p={'24px'}>
        <ModalCloseButton color={'white.1'} />
        <ModalBody flexDirection={'column'} p={'0px'}>
          {testUsersQuery.isLoading && <Spinner size={'md'} />}
          {!testUsersQuery.isLoading && (
            <>
              <Text>Current user</Text>
              <Select value={userToken || ''} onChange={onChange} placeholder="Select option">
                {testUsersQuery.data?.map((user) => (
                  <option key={user.id} value={user.id}>
                    {user.name} | {user.id}
                  </option>
                ))}
              </Select>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

import {ONBOARDING_CONFIG} from 'stores'

export interface IMarksConfig {
  value: number
  label: string
}

export const LABEL_STYLES = {
  mt: '-30px',
  ml: '-2.5',
  fontSize: 'sm',
  color: 'white.3'
}

export const startFinishMarksConfig: IMarksConfig[] = [
  {value: 0, label: '00'},
  {value: ONBOARDING_CONFIG.SIX_HOURS, label: '06'},
  {value: ONBOARDING_CONFIG.TWELVE_HOURS, label: '12'},
  {value: ONBOARDING_CONFIG.EIGHTEEN_HOURS, label: '18'},
  {value: ONBOARDING_CONFIG.FULL_DAY, label: '24'}
]

export const durationMarksConfig: IMarksConfig[] = [
  {value: 0, label: '0'},
  {value: ONBOARDING_CONFIG.THREE_HOURS, label: '3'},
  {value: ONBOARDING_CONFIG.SIX_HOURS, label: '6'},
  {value: ONBOARDING_CONFIG.NINE_HOURS, label: '9'},
  {value: ONBOARDING_CONFIG.TWELVE_HOURS, label: '12'}
]

export const daysButtonsConfig: IMarksConfig[] = [
  {value: 1, label: 'mon'},
  {value: 2, label: 'tue'},
  {value: 3, label: 'wed'},
  {value: 4, label: 'thu'},
  {value: 5, label: 'fri'},
  {value: 6, label: 'sat'},
  {value: 7, label: 'sun'}
]

import {FC, useState} from 'react'
import {Flex, Button} from '@chakra-ui/react'
import {useAppStore} from 'stores'
import {ButtonGroup} from 'components'
import {TestUsersModal} from './components/TestUsersModal'
import {api} from 'services/api'

export const ConfigPanel: FC = ({}) => {
  const [isDemo, setIsDemo] = useAppStore((state) => [state.isDemo, state.setIsDemo] as const)

  const [isShowTestUsersModal, setIsShowTestUsersModal] = useState(false)

  const onChange = (value: string) => {
    if (value === 'demo') {
      setIsDemo(true)
      api.isDemo = true
    } else {
      setIsDemo(false)
      api.isDemo = false
    }
  }

  const options = [
    {
      value: 'server',
      label: 'server'
    },
    {
      value: 'demo',
      label: 'demo'
    }
  ]

  return (
    <>
      <Flex flexDirection={'column'} w={'100%'} minHeight={'100px'} mt={'40px'}>
        <Flex
          backdropFilter={'blur(30px)'}
          p={'16px 16px 16px 24px'}
          bgColor={'white.1-05'}
          borderRadius={'8px'}
          borderWidth={'1px'}
          flexDirection={'column'}
          borderColor={'black.3-40'}>
          <Flex alignItems={'center'} h={'40px'} w={'120px'}>
            <ButtonGroup options={options} value={isDemo ? 'demo' : 'server'} onChange={onChange} />
          </Flex>
          <Flex flexDirection={'column'} mt={'10px'}>
            <Button onClick={() => setIsShowTestUsersModal(true)}>Test Users</Button>
          </Flex>
        </Flex>
      </Flex>
      <TestUsersModal isShow={isShowTestUsersModal} onClose={() => setIsShowTestUsersModal(false)} />
    </>
  )
}

import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';
import { getAnalytics, logEvent } from '@firebase/analytics';

export function useFirebaseRoutesAnalytics() {
  const location = useLocation();

  useEffect(() => {
    const analytics = getAnalytics();
    logEvent(analytics, 'page_view', {
      page_path: location.pathname, // <- In my case I do not want to include search params, so 'location.pathname' is just what I want
    });
  }, [location]);

  return null;
}
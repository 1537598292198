import { format, parseISO, secondsToHours, secondsToMinutes } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

export const toDateString = (date: Date) => {
  return format(date, "yyyy-MM-dd");
};

export const secondsToTime = (value: number) => {
  value = value % 86400; // 86400 - seconds in day
  const hoursAmount = secondsToHours(value);
  const minutesAmount = secondsToMinutes(value) % 60;
  const hours =
    hoursAmount === 24
      ? "00"
      : hoursAmount >= 10
      ? hoursAmount
      : `0${hoursAmount}`;
  const minutes = minutesAmount <= 9 ? `0${minutesAmount}` : minutesAmount;

  return `${hours}:${minutes}`;
};

type TOptions = {
  withSeconds?: boolean;
  withMinutes?: boolean;
  withWhitespace?: boolean;
  withFullLabel?: boolean;
  withZero?: boolean;
};

export const secondsToString = (
  value: number,
  options: TOptions = {
    withMinutes: true,
    withWhitespace: false,
    withFullLabel: false,
    withSeconds: false,
    withZero: false,
  }
) => {
  const {
    withSeconds = false,
    withMinutes = true,
    withWhitespace = false,
    withFullLabel = false,
    withZero = false,
  } = options;

  let hourLabel = withWhitespace ? " h" : "h";
  let minuteLabel = withWhitespace ? " m" : "m";
  let secondLabel = withWhitespace ? " s" : "s";

  if (withFullLabel) {
    hourLabel = withWhitespace ? " hours" : "hours";
    minuteLabel = withWhitespace ? " minutes" : "minutes";
    secondLabel = withWhitespace ? " seconds" : "seconds";
  }

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);
  const seconds = value % 60;
  const hoursStr = ((hours || withZero) && `${hours}${hourLabel} `) || "";
  const minutesRawStr =
    ((minutes || withZero) && `${minutes}${minuteLabel} `) || "";
  const secondsRawStr =
    ((seconds || withZero) && `${seconds.toFixed(0)}${secondLabel}`) || "";
  const isEmpty = `${hoursStr}${minutesRawStr}` === "";
  const secondsStr =
    withSeconds || (isEmpty && withMinutes) ? secondsRawStr : "";
  const minutesStr = withMinutes || isEmpty ? minutesRawStr : "";
  return `${hoursStr}${minutesStr}${secondsStr}`;
};

export const secondsToDays = (value: number, fallback: string = "") => {
  const days = Math.floor(value / (3600 * 24));
  const hours = Math.floor((value % (3600 * 24)) / 3600);
  const daysStr = (days && `${days}${days === 1 ? " day" : " days"} `) || "";
  const hoursStr = (hours && `${hours}${hours === 1 ? " h" : " h"} `) || "";
  return `${daysStr}${hoursStr}` || fallback;
};

export const toDate = (value: string) => {
  return parseISOToUTC(value);
};

export const getTimeZone = () => {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
};

export const parseISOToUTC = (isoDate: string) => {
  return utcToZonedTime(parseISO(isoDate), "UTC");
};

import {FC, useEffect, useState} from 'react'
import {FormControl, FormErrorMessage, FormLabel, Text} from '@chakra-ui/react'
import {useTranslation} from 'react-i18next'
import {Button, IconButton, Input} from 'components'
import {sendPasswordResetEmail} from 'firebase/auth'
import {auth} from 'services/firebase/firebase'
import {useForm} from 'react-hook-form'
import {emailRegex} from '@kaef/common/utils/helpers'

interface IProps {
  navToSignIn: () => void
}

interface IForgotPasswordForm {
  email: string
}

const ForgotPasswordScene: FC<IProps> = ({navToSignIn}) => {
  const {t} = useTranslation()
  const [emailError, setEmailError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState(false)
  const [isSent, setIsSent] = useState(false)

  const {watch, formState, register, handleSubmit} = useForm<IForgotPasswordForm>({
    mode: 'onBlur'
  })

  const onResetPassword = async (data: IForgotPasswordForm) => {
    setIsLoading(true)
    setEmailError(null)
    const {email} = data
    try {
      await sendPasswordResetEmail(auth, email)
      setIsSent(true)
    } catch (error) {
      if (error) {
        setEmailError(t('authorization.errors.incorrectPassword') as string)
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    if (emailError) {
      setEmailError(null)
    }
  }, [watch('email')])

  const isEmailError = !!formState.errors?.email || emailError

  return (
    <>
      <IconButton
        top={'-20px'}
        left={'-10px'}
        icon={'arrowBack'}
        variant={'ghost'}
        onClick={navToSignIn}
        aria-label={'navToSignIn'}
      />
      <Text mb={'8px'} textStyle={'lg'} color={'white.1'}>
        {t('authorization.forgotPassword.title')}
      </Text>
      <Text mb={'32px'} textStyle={'md'} color={'white.3'}>
        {t('authorization.forgotPassword.subtitle')}
      </Text>

      {!isSent && (
        <>
          <FormControl mb={'32px'} isInvalid={!!isEmailError}>
            <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
              {t('authorization.forgotPassword.email')}
            </FormLabel>
            <Input
              isInvalid={!!isEmailError}
              {...register('email', {required: t('authorization.errors.emailRequired') as string, pattern: emailRegex})}
              type={'email'}
              placeholder={String(t('authorization.forgotPassword.emailPlaceholder'))}
              size="md"
            />
            {isEmailError && <FormErrorMessage>{emailError}</FormErrorMessage>}
          </FormControl>
          <Button isLoading={isLoading} onClick={handleSubmit(onResetPassword)} variant={'accent'}>
            {t('authorization.forgotPassword.send')}
          </Button>
        </>
      )}
      {isSent && (
        <Text mb={'32px'} textStyle={'md'} color={'white.1'}>
          {t('authorization.forgotPassword.sent')}
        </Text>
      )}
    </>
  )
}

export default ForgotPasswordScene

import {FC, useEffect, useState} from 'react'
import {useLocation, useNavigate, useParams, useSearchParams} from 'react-router-dom'
import {Text, Flex} from '@chakra-ui/react'
import {paths} from 'shared/constants/paths'
import {useTranslation} from 'react-i18next'
import {Button, Modal, PluginIcon} from 'components'

const getPluginName = (plugin: string | null) => {
  if (plugin === 'plugin-jetbrains') {
    return 'JetBrains'
  }
  if (plugin === 'plugin-vscode') {
    return 'VS Code'
  }
  return ''
}

const LinkPlugin: FC = () => {
  const {t} = useTranslation()
  const {id} = useParams()
  const [searchParams, _] = useSearchParams()
  const navigate = useNavigate()
  const location = useLocation()
  const [plugin, setPlugin] = useState<string | null>(null)

  useEffect(() => {
    setPlugin(searchParams.get('utm_source'))
  }, [])

  const onCancel = () => {
    navigate(paths.home.path)
  }

  const onConnect = () => {
    navigate(`${paths.linkPluginConfirm.path}/${id}${location.search}`)
  }

  const renderIcon = () => {
    if (plugin === 'plugin-jetbrains') {
      return <PluginIcon w={'60px'} h={'60px'} mr={'24px'} type={'jetbrains'} />
    }
    if (plugin === 'plugin-vscode') {
      return <PluginIcon w={'60px'} h={'60px'} mr={'24px'} type={'vscode'} />
    }
    return null
  }

  return (
    <Modal size={'md'} onClose={onCancel} closeOnOverlayClick={false}>
      <Flex flexDirection={'column'} mt={'12px'}>
        <Flex alignItems={'center'}>
          {renderIcon()}
          <Text>{t('linkPlugin.confirmTitle', {plugin: getPluginName(plugin)})}</Text>
        </Flex>
        <Flex mt={'32px'} gap={'12px'}>
          <Button variant={'secondary'} onClick={onCancel}>
            {t('linkPlugin.cancel')}
          </Button>
          <Button variant={'accent'} onClick={onConnect}>
            {t('linkPlugin.connect')}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  )
}

export default LinkPlugin

import {IUser, IUserUpdate} from '@kaef/common/types'
import { AxiosInstance } from "axios";
import { user as demoUser } from "./user.mock";

export const getUser = async (
  api: AxiosInstance,
  isDemo?: boolean
): Promise<IUser> => {
  if (isDemo) {
    return demoUser;
  }
  const response = await api.post("/user", {
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });
  return response.data;
};

export const getUserInfo = async (
  api: AxiosInstance
): Promise<IUser> => {
  const response = await api.get("/user/info");
  return response.data;
};

export const updateUser = async (
  user: IUserUpdate,
  api: AxiosInstance,
): Promise<IUser> => {
  const response = await api.post("/user/info", user);
  return response.data;
};

export const linkPlugin = async (
  pluginId: string,
  api: AxiosInstance
): Promise<string> => {
  const response = await api.post("/user/plugin", { pluginId });
  return response.data;
};

export const getWeekPlan = async (
  api: AxiosInstance
): Promise<number[]> => {
  const response = await api.get("/user/days/work");
  return response.data.days;
};

export const saveWeekPlan = async (data: number[], api: AxiosInstance): Promise<void> => {
  await api.post("/user/days/work", {
      days: data
  });
};

export const sendWakatimeUrl = async (url: string, api: AxiosInstance): Promise<void> => {
  await api.post("/user/import/wakatime", {url});
}

import {FC} from 'react'
import {Button} from 'components'
import {useTranslation} from 'react-i18next'
import {Flex} from '@chakra-ui/react'
import {useOnboardingStore} from 'stores'
import {logOnboardingSetGoal, logOnboardingSkipGoal} from 'services/analytics/analytics'
import {StartModal} from 'components/Modals/Goals/StartModal'

interface IProps {
  onNextStep: () => void
}

export const ChooseStart: FC<IProps> = ({onNextStep}) => {
  const {t} = useTranslation()
  const [startValue, setStart] = useOnboardingStore((state) => [state.start, state.setStart])

  const onNext = () => {
    logOnboardingSetGoal('START')
    onNextStep()
  }

  const onSkip = () => {
    logOnboardingSkipGoal('START')
    onNextStep()
  }

  return (
    <Flex flexDirection={'column'} w={'100%'}>
      <StartModal setStart={setStart} startValue={startValue} />
      <Flex w={'100%'} flexDirection={'column'}>
        <Button mb={'16px'} onClick={onNext} variant={'accent'}>
          {t('onboarding.start.next')}
        </Button>
        <Button onClick={onSkip} variant={'secondary'}>
          {t('onboarding.start.skip')}
        </Button>
      </Flex>
    </Flex>
  )
}

import React, {FC} from 'react'
import {EGoalResult, ERange, IOverviewGoal, TDayType} from '@kaef/common/types'
import {Box, Flex, Text} from '@chakra-ui/react'
import {Icon, MicroPie, ProgressBox, ProgressLine} from 'components'
import {GoalCard} from '../GoalCard/GoalCard'
import { SuccessIcon } from '../SuccessIcon/SuccessIcon'
import {FailIcon} from '../FailIcon/FailIcon'
import {useTranslation} from 'react-i18next'
import {getGoalStatus, progressLineColorMap, secondsToString, textColorMap} from '@kaef/common/utils/helpers'

interface IProps {
  range: ERange
  goal: IOverviewGoal
  onEdit: () => void
  onClickDay: (day: string, result: EGoalResult, dayType: TDayType | null) => void
}

export const DurationGoalCard: FC<IProps> = ({range, goal, onEdit, onClickDay}) => {
  const {t} = useTranslation()
  const {total, average, maxStreak, success, failed} = goal!.stats
  const status = getGoalStatus(range, goal)
  const totalGoalDays = total

  const renderLeftPart = () => {
    switch (range) {
      case ERange.DAY:
        const durationPercent = (Number(goal?.history[0]?.value) / goal?.value) * 100
        return (
          <Flex flexDirection={'column'} alignItems={'flex-start'}>
            <Box position={'relative'}>
              <Icon height={20} width={20} type={'time1'} color={textColorMap[status]} />
              {status === 'SUCCESS' && <SuccessIcon />}
              {status === 'FAIL' && <FailIcon />}
              {status === 'IN_PROGRESS' && (
                <Flex
                  position={'absolute'}
                  top={'10px'}
                  left={'10px'}
                  bgColor={'black.2'}
                  w={'18px'}
                  h={'18px'}
                  borderRadius={'18px'}
                  justifyContent={'center'}
                  alignItems={'center'}>
                  <MicroPie withBlur={false} value={durationPercent} color={'white.3'} />
                </Flex>
              )}
            </Box>
            <Text mt={'6px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.title')}</Text>
            <Text color={'white.1'} textStyle={'md'}>{secondsToString(goal?.history[0]?.value as number, {withWhitespace: true})}</Text>
          </Flex>
        )
      case ERange.WEEK:
      case ERange.MONTH:
      case ERange.YEAR:
        return (
          <Flex flexDirection={'column'} alignItems={'flex-start'}>
            <Box position={'relative'}>
              <Icon height={20} width={20} type={'time1'} color={textColorMap[status]} />
              {status === 'SUCCESS' && <SuccessIcon />}
              {status === 'FAIL' && <FailIcon />}
            </Box>
            <Text mt={'6px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.averageTotal')}</Text>
            <Text color={'white.1'} textStyle={'md'}>{average === 0 ? '--:--' : secondsToString(average, {withWhitespace: true})}</Text>
          </Flex>
        )
    }
  }

  const renderRightPart = () => {
    switch (range) {
      case ERange.DAY:
        const durationPercent = (Number(goal?.history[0]?.value) / goal?.value) * 100

        return (
          <Flex flexDirection={'column'} w={'100%'}>
            {status !== 'NO_GOAL' && <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.duration.plannedWorkTime', {value: durationPercent.toFixed(0)})}</Text>}
            <ProgressLine isEmpty={status === 'NO_GOAL'}
                          trackColor={'white.3-10'}
                          fillBar={{color: progressLineColorMap.fillColor[status], start: 0, end: durationPercent}}
                          longMark={{color: progressLineColorMap.longMarkColor[status], position: durationPercent}} />
            {status === 'NO_GOAL' && <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.noGoal')}</Text>}
            {status !== 'NO_GOAL' && <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.workAtLeast', {value: secondsToString(goal?.value, {withWhitespace: true})})}</Text>}
          </Flex>
        )
      case ERange.WEEK:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <ProgressBox mode={'week'} data={goal?.history} onClick={onClickDay} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.workAtLeast', {value: secondsToString(goal?.value, {withWhitespace: true})})}</Text>
          </Flex>
        )
      case ERange.MONTH:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <ProgressBox mode={'month'} data={goal?.history} onClick={onClickDay} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.workAtLeast', {value: secondsToString(goal?.value, {withWhitespace: true})})}</Text>
          </Flex>
        )
      case ERange.YEAR:
        return (
          <Flex flexDirection={'column'} w={'100%'}>
            <Text color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.daysCompleted', {value: success, total: totalGoalDays})}</Text>
            <Text mb={'8px'} color={textColorMap[status]} textStyle={'sm'}>{t('sidebar.todayGoals.longestStreak', {value: maxStreak})}</Text>
            <ProgressLine trackColor={'white.3-10'}
                          fillBar={{color: 'state.positive-50', start: 0, end: success/(success + failed) * 100}}
                          secondFillBar={{color: 'state.negative-50', start: success/(success + failed) * 100, end: 100}} />
            <Text mt={'12px'} color={'white.3'} textStyle={'sm'}>{t('sidebar.todayGoals.duration.workAtLeast', {value: secondsToString(goal?.value, {withWhitespace: true})})}</Text>
          </Flex>
        )
    }
  }


  return (
    <GoalCard status={status}
              isWide={range === ERange.MONTH}
              LeftElement={() => renderLeftPart()}
              RightElement={() => renderRightPart()}
              onEdit={onEdit} />
  )
}
import React, {FC, useState} from 'react'
import {Flex, Text, Highlight, Image, FormControl, FormLabel, useToast} from '@chakra-ui/react'
import waka1 from 'assets/instructions/wakatime/waka1.png'
import waka2 from 'assets/instructions/wakatime/waka2.png'
import waka3 from 'assets/instructions/wakatime/waka3.png'
import waka4 from 'assets/instructions/wakatime/waka4.png'

import {useTranslation} from 'react-i18next'
import {Button, Input} from 'components/UI'
import {api} from 'services/api'
import {useNavigate} from 'react-router-dom'
import {paths} from 'shared/constants/paths'
import {logImportWakatime} from 'services/analytics/analytics'

const highlightStyle = {px: '2', py: '1', borderRadius: '6px', bg: 'accent.1-30', color: 'white.2'}

interface IProps {
  source: string
}

export const WakatimeImport: FC<IProps> = ({source}) => {
  const {t} = useTranslation()
  const toast = useToast()
  const navigate = useNavigate()

  const [exportUrl, setExportUrl] = useState('')

  const useSendWakatimeUrlMutation = api.useSendWakatimeUrlMutation()

  const onImport = () => {
    logImportWakatime(source)
    useSendWakatimeUrlMutation.mutate(exportUrl, {
      onSuccess: () => {
        toast({
          title: t('wakatimeImport.toastTitle'),
          description: t('wakatimeImport.toastDescription'),
          status: 'success',
          duration: 5000,
          isClosable: true
        })
        navigate(paths.dashboard.path)
      },
      onError: () => {
        toast({
          title: t('wakatimeImport.toastErrorTitle'),
          description: t('wakatimeImport.toastErrorDescription'),
          status: 'error',
          duration: 5000,
          isClosable: true
        })
      }
    })
  }

  return (
    <Flex minHeight={'180px'}>
      <Flex flexDirection={'column'}>
        <Text mb={'20px'} textStyle={'lg'} color={'white.1'}>
          {t('wakatimeImport.title')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          {t('wakatimeImport.subtitle')}
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query={['settings']} styles={highlightStyle}>
            1. Open Wakatime settings
          </Highlight>
        </Text>
        <Flex w={'100%'} justify={'center'} mb={'32px'} mt={'8px'}>
          <Image maxW={['300px', '300px', '300px']} borderRadius={'8px'} src={waka1} />
        </Flex>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Export my code stats..." styles={highlightStyle}>
            2. Click on Export my code stats... button
          </Highlight>
        </Text>
        <Image w={'100%'} src={waka2} mb={'32px'} mt={'8px'} borderRadius={'8px'} />
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Heartbeats" styles={highlightStyle}>
            3. Choose Heartbeats option in dialog modal
          </Highlight>
        </Text>
        <Image w={'100%'} src={waka3} borderRadius={'8px'} mb={'32px'} mt={'8px'} />
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Follow link" styles={highlightStyle}>
            4. Wait for Wakatime to generate a link to the data file
          </Highlight>
        </Text>
        <Image w={'100%'} src={waka4} borderRadius={'8px'} mb={'32px'} mt={'8px'} />
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          <Highlight query="Import" styles={highlightStyle}>
            5. Copy link to exported data, insert it in input bellow and press Import
          </Highlight>
        </Text>
        <Text mb={'6px'} textStyle={'md'} color={'white.3'}>
          You can import wakatime's data as many times as you want, we will show only last imported data
        </Text>

        <FormControl mb="12px">
          <FormLabel mb={'8px'} textStyle={'lg'} color={'white.1'}>
            Wakatime export URL
          </FormLabel>
          <Flex>
            <Input value={exportUrl} onChange={(e) => setExportUrl(e.target.value)} type={'text'} />
            <Button ml={'8px'} isDisabled={!exportUrl} variant={'accent'} w={'25%'} onClick={onImport}>
              {t('wakatimeImport.import')}
            </Button>
          </Flex>
        </FormControl>

        <Flex justifyContent={'center'}></Flex>
      </Flex>
    </Flex>
  )
}

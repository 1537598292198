import { create } from "zustand";

interface IAuthState {
  isAuth: boolean
  setIsAuth: (isAuth: boolean) => void
  afterAuthRedirect: string | null
  setAfterAuthRedirect: (path: string | null) => void
  isForceSignOut: boolean
  setIsForceSignOut: (value: boolean) => void
}

export const useAuthStore = create<IAuthState>()((set) => ({
  isAuth: false,
  setIsAuth: (isAuth) => set({isAuth}),
  afterAuthRedirect: null,
  setAfterAuthRedirect: afterAuthRedirect => set({ afterAuthRedirect }),
  isForceSignOut: false,
  setIsForceSignOut: isForceSignOut => set({isForceSignOut}),
}));

import {extractInitials} from '@kaef/common/utils/helpers'
import {Icon} from '../Icons/Icon'
import {Center} from '@chakra-ui/react'
import React, {FC, useMemo} from 'react'

interface IProps {
  name?: string
  size?: 'sm' | 'md' | 'lg'
}

export const Avatar: FC<IProps> = ({name, size = 'md'}) => {
  const {width, height, fontSize} = useMemo(() => {
    switch (size) {
      case 'sm':
        return {
          width: '24px',
          height: '24px',
          fontSize: '12px'
        }
      case 'md':
        return {
          width: '32px',
          height: '32px',
          fontSize: '15px'
        }
      case 'lg':
        return {
          width: '48px',
          height: '48px',
          fontSize: '20px'
        }
    }
  }, [size])

  return (
    <Center
      width={width}
      height={height}
      background={'black.4'}
      borderRadius={'50%'}
      fontSize={fontSize}
      flexShrink={0}
      fontWeight={'700'}>
      {name && extractInitials(name)}
      {!name && <Icon type={'profile'} />}
    </Center>
  )
}
